import { t } from "@lingui/macro";
import { RegrelloCommandItem, RegrelloIcon } from "@regrello/ui-core";

interface RegrelloSelectV2ErrorOptionProps {
  /**
   * Custom message to display.
   */
  message?: string;
}

/**
 * This component can be used in `extraOptions` of `RegrelloFormFieldSelect` to render an `error` state.
 */
export const RegrelloSelectV2ErrorOption = ({ message }: RegrelloSelectV2ErrorOptionProps) => {
  return (
    <RegrelloCommandItem
      disabled={true}
      forceMount={true}
      text={
        <div className="flex items-center gap-2 text-danger-textMuted">
          <RegrelloIcon iconName="alert" />
          <strong>{message != null ? message : t`Failed to load options`}</strong>
        </div>
      }
      value="__SELECT_ERROR_OPTION__"
    />
  );
};
