import { t } from "@lingui/macro";
import { EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { ConditionConnective, type FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloButton } from "@regrello/ui-core";
import { isValid } from "date-fns";
import { useCallback } from "react";
import type { UseFormReturn } from "react-hook-form";

import { ConnectiveComponent } from "./RegrelloConnectiveForm";
import {
  type RegrelloScheduleTimeFormSectionBase,
  type ScheduleTimeKeys,
  ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";
import { START_CONDITION_BETWEEN_LABEL_WIDTH } from "./scheduleTimeConstants";
import { ValidationRules } from "../../../../../constants/globalConstants";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import type { ConditionOperatorConfig } from "../../../../molecules/customFields/plugins/types/ConditionOperator";
import type { CustomFieldPlugin } from "../../../../molecules/customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import {
  RegrelloControlledFormFieldCustomFieldInstanceSelect,
  RegrelloControlledFormFieldSelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";

/**
 * RegrelloSingleConditionFormProps is largely a subset of the props from RegrelloConfigureConditionsRecursiveProps,
 * which are more thoroughly documented.
 * Essentially, RegrelloSingleConditionForm renders a single condition.
 */
type RegrelloSingleConditionFormProps = {
  allowedFieldPlugins: Array<CustomFieldPlugin<unknown>>;
  connective: ConditionConnective;
  connectivePath: `${ScheduleTimeKeys.CONDITIONS}.connective`;
  dependingOnStageIds: number[];
  filterCondition?: (option: FieldInstanceFields) => boolean;
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;
  index: number;
  isDisabled: boolean;
  /**
   * prefix must point to a condition row within some condition group.
   * For example, `${ScheduleTimeKeys.CONDITIONS}.conditions[1]`.
   */
  prefix: string;
  removeCondition: (idx: number) => void;
  startConditionOption?: ScheduleTimeValues.START_CONDITION_STAGE | ScheduleTimeValues.START_CONDITION_WORKFLOW;
  // biome-ignore lint/suspicious/noExplicitAny: any subset of the condition fields may be (un)set.
  updateCondition: (idx: number, value: any) => void;
  workflowContext:
    | {
        type: "workflowTemplate";
        workflowTemplateId: number;
        workflowTemplateIsCreateViaEmailEnabled: boolean;
      }
    | {
        type: "workflow";
        workflowId: number;
      };
};

/**
 * RegrelloSingleConditionForm renders a configurable row of a condition group.
 * It maintains its state in the provided form so callers still have access to it.
 */
export function RegrelloSingleConditionForm({
  allowedFieldPlugins,
  dependingOnStageIds,
  prefix,
  form,
  index,
  isDisabled,
  connectivePath,
  connective,
  startConditionOption,
  workflowContext,
  filterCondition,
  updateCondition,
  removeCondition,
}: RegrelloSingleConditionFormProps) {
  const fieldInstancePath = `${prefix}.fieldInstance` as `conditions.conditions.${number}.fieldInstance`;
  const fieldInstance = form.watch(fieldInstancePath) as FieldInstanceFields | null;
  const fieldInstancePropertyIdPath =
    `${prefix}.fieldInstancePropertyId` as `conditions.conditions.${number}.fieldInstancePropertyId`;
  const fieldInstancePropertyId = form.watch(fieldInstancePropertyIdPath) as number;
  const operatorPath = `${prefix}.operator` as `conditions.conditions.${number}.operator`;
  const operator = form.watch(operatorPath) as ConditionOperatorConfig | null;
  const value1Path = `${prefix}.value1` as `${ScheduleTimeKeys.CONDITIONS}.conditions.${number}.value1`;
  const value2Path = `${prefix}.value2` as `${ScheduleTimeKeys.CONDITIONS}.conditions.${number}.value2`;
  const value1 = form.watch(value1Path) as unknown;
  const value2 = form.watch(value2Path) as unknown;

  const fieldInstanceProperties =
    fieldInstance &&
    CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getFieldProperties?.(fieldInstance.field);

  const handleStartConditionFieldInstanceChange = useCallback(
    (newFieldInstance: FieldInstanceFields | null) => {
      // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
      // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
      // the fieldArray fields and set it as `never`.
      updateCondition(index, { fieldInstance: newFieldInstance, operator: null, value1: null, value2: null });
    },
    [updateCondition, index],
  );
  const handleStartConditionFieldPropertyChange = useCallback(
    (newFieldInstance: FieldInstanceFields | null, newPropertyId: number) => {
      // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
      // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
      // the fieldArray fields and set it as `never`.
      updateCondition(index, { fieldInstance: newFieldInstance, fieldInstancePropertyId: newPropertyId });
    },
    [updateCondition, index],
  );

  const handleStartConditionOperatorChange = useCallback(
    (
      currentFieldInstance: FieldInstanceFields | null,
      newOperator: ConditionOperatorConfig | null,
      oldValue1: unknown,
    ) => {
      // (dyury): If the user switched from an operator with operands to an operator without operands  (e.g. IS_GREATER_THAN -> IS_EMPTY)
      // we need to clear validation rules
      form.unregister(value1Path);
      // (zstanik): If the user switched from a BETWEEN operator, need to unregister the 2nd form
      // field to clear any stale validation rules.
      form.unregister(value2Path);

      const newValue1 =
        currentFieldInstance != null && newOperator != null
          ? CustomFieldPluginRegistrar.getPluginForFieldInstance(currentFieldInstance).getEmptyValueForFrontend({
              operator: newOperator.operator,
            })
          : null;

      // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
      // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
      // the fieldArray fields and set it as `never`, making it unusable.
      updateCondition(index, {
        fieldInstance: currentFieldInstance,
        fieldInstancePropertyId,
        operator: newOperator,
        value1: areOperatorValuesCompatible(oldValue1, newValue1) ? oldValue1 : newValue1,
        value2: null,
      });
    },
    [form, updateCondition, value1Path, value2Path, index, fieldInstancePropertyId],
  );

  return (
    <div className="flex gap-2 p-1.5 items-center">
      {index === 0 && <div className="w-20 px-2 shrink-0 pt-3.5">{t`If`}</div>}
      {index === 1 && (
        <ConnectiveComponent
          className="w-20 shrink-0"
          connectivePath={connectivePath}
          form={form}
          isDisabled={isDisabled}
        />
      )}
      {index > 1 && (
        <div className="w-20 px-2 shrink-0 pt-3.5">{connective === ConditionConnective.AND ? t`and` : t`or`}</div>
      )}
      <div className="flex flex-wrap gap-2 grow">
        <RegrelloControlledFormFieldCustomFieldInstanceSelect
          allowedFieldPlugins={allowedFieldPlugins}
          className="w-40"
          controllerProps={{
            control: form.control,
            name: fieldInstancePath,
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.START_DEPENDENCY_START_CONDITION_FIELD_SELECT}
          disabled={
            isDisabled ||
            ((dependingOnStageIds === undefined || dependingOnStageIds.length === 0) &&
              startConditionOption === ScheduleTimeValues.START_CONDITION_STAGE)
          }
          filterCondition={filterCondition}
          isAllowEmptyOptionalFieldInstances={true}
          isInactiveFieldInstancesHidden={true}
          isRetainOptionalFieldInstanceInputType={true}
          isSourceHelperTextVisible={true}
          onValueChange={(_, newValue) => handleStartConditionFieldInstanceChange(newValue)}
          optionsConfig={{
            type: "asyncLoaded",
            dependingOnActionItemTemplateId: undefined,
            workflowContext:
              workflowContext.type === "workflow"
                ? {
                    type: "workflow",
                    workflowId: workflowContext.workflowId,
                    workflowStageId: undefined,
                    dependingOnWorkflowStageIds: dependingOnStageIds ?? EMPTY_ARRAY,
                  }
                : {
                    type: "workflowTemplate",
                    workflowTemplateId: workflowContext.workflowTemplateId,
                    workflowStageTemplateId: undefined,
                    workflowTemplateIsCreateViaEmailEnabled: workflowContext.workflowTemplateIsCreateViaEmailEnabled,
                    dependingOnWorkflowStageTemplateIds: dependingOnStageIds ?? EMPTY_ARRAY,
                  },
          }}
          placeholder={t`Select field`}
        />
        {fieldInstance != null && fieldInstanceProperties != null && fieldInstanceProperties.length > 0 ? (
          <RegrelloControlledFormFieldSelect
            className="w-1/3"
            controllerProps={{
              control: form.control,
              name: fieldInstancePropertyIdPath,
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.START_DEPENDENCY_FIELD_PROPERTY}
            getOptionLabel={(option) =>
              fieldInstanceProperties.find((prop) => prop.id === option)?.name || String(option)
            }
            isFilterable={false}
            onValueChange={(_, newValue) => {
              if (newValue) {
                handleStartConditionFieldPropertyChange(fieldInstance, newValue);
              }
            }}
            options={fieldInstanceProperties != null ? fieldInstanceProperties.map((prop) => prop.id) : EMPTY_ARRAY}
            placeholder={t`Select property`}
          />
        ) : null}
        <RegrelloControlledFormFieldSelect
          className="w-40"
          controllerProps={{
            control: form.control,
            name: operatorPath,
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.START_DEPENDENCY_OPERATOR_SELECT}
          disabled={
            isDisabled || fieldInstance == null || (fieldInstanceProperties != null && fieldInstancePropertyId == null)
          }
          getOptionLabel={(option) => option.displayValue}
          isFilterable={false}
          onValueChange={(_, newValue) => handleStartConditionOperatorChange(fieldInstance, newValue, value1)}
          options={
            fieldInstance != null
              ? CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getConditionOperators()
              : EMPTY_ARRAY
          }
          placeholder={t`Select condition`}
        />
        {operator != null &&
          fieldInstance != null &&
          operator.numFields > 0 &&
          CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(fieldInstance.field, {
            className: operator.numFields > 1 ? "grow mr-0" : "grow overflow-hidden",
            controllerProps: {
              control: form.control,
              name: value1Path,
              rules: {
                ...ValidationRules.REQUIRED,
                ...(operator.numFields > 1
                  ? isValid(value2)
                    ? ValidationRules.BEFORE_DATE(value2 as Date)
                    : isValidNonEmptyNumber(value2)
                      ? ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number(value2))
                      : {}
                  : {}),
              },
            },
            disabled: isDisabled,
            operator: operator.operator,
            fieldInstance,
          })}
        {operator != null && fieldInstance != null && operator.numFields > 1 && (
          <RegrelloFormFieldLayout
            className="grow m-0 p-0"
            isLabelUppercase={true}
            label={t`And`}
            labelAlignment="center"
            labelWidth={START_CONDITION_BETWEEN_LABEL_WIDTH}
          >
            {CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(fieldInstance.field, {
              controllerProps: {
                control: form.control,
                name: value2Path,
                rules: {
                  ...ValidationRules.REQUIRED,
                  ...(isValid(value1)
                    ? ValidationRules.AFTER_DATE(value1 as Date)
                    : isValidNonEmptyNumber(value1)
                      ? ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number(value1))
                      : {}),
                },
              },
              disabled: isDisabled,
              operator: operator.operator,
              fieldInstance,
            })}
          </RegrelloFormFieldLayout>
        )}
      </div>
      <RegrelloButton
        className="ml-auto"
        disabled={isDisabled}
        iconOnly={true}
        onClick={() => removeCondition(index)}
        startIcon="delete"
        variant="ghost"
      />
    </div>
  );
}

// TODO Conditional Branching: Potentially tie this into the custom field plugin framework. This is
// a quick patch, but would require adding a new function to the framework or refactoring
// `getEmptyValueForFrontend`.
function areOperatorValuesCompatible(oldValue: unknown, newValue: unknown): boolean {
  return (
    (typeof oldValue === typeof newValue || newValue == null) &&
    oldValue != null &&
    Array.isArray(oldValue) === Array.isArray(newValue)
  );
}

function isValidNonEmptyNumber(value: unknown): boolean {
  return value != null && value !== "" && !Number.isNaN(Number(value));
}
