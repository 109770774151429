import { t } from "@lingui/macro";
import { clsx } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { ConditionConnective, type FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloTooltip } from "@regrello/ui-core";
import React, { useCallback } from "react";
import { type UseFormReturn, useWatch } from "react-hook-form";

import { ConnectiveComponent } from "./RegrelloConnectiveForm";
import {
  type RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
  type ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";
import { RegrelloSingleConditionForm } from "./RegrelloSingleConditionForm";
import {
  type Condition,
  type ConditionGroup,
  makeEmptyConditionGroup,
  MAX_CONDITION_GROUP_NESTING_LEVEL,
} from "./utils";
import { isOnSplitChild, isOnSplitterTask } from "../../../../../utils/customFields/splitFieldUtils";
import type { CustomFieldPlugin } from "../../../../molecules/customFields/plugins/types/CustomFieldPlugin";

export interface RegrelloConfigureConditionsRecursiveProps {
  /**
   * The field plugins allowed in the custom field instance select; i.e., the only field instances
   * that can be selected are those that can be processed by one of the field plugins in this array.
   */
  allowedFieldPlugins: Array<CustomFieldPlugin<unknown>>;

  className?: string;

  dependingOnStageIds: number[];

  /**
   * The form for configuring the schedule time of the stage or action item. Should have `mode`
   * set to 'all' for form validation to work properly.
   */
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;

  /**
   * Whether the entire schedule time section is disabled.
   */
  isDisabled: boolean;

  /**
   * isRecursiveGroupsEnabled allows nesting groups within groups. Otherwise, only conditions/predicates can be configured.
   */
  isRecursiveGroupsEnabled: boolean;

  onRemove?: () => void;

  /** prefix is potentially recursive, so it cannot be captured in types well.
   * This is essentially ScheduleTimeKeys.CONDITIONS recursively followed by "groups" and indices.
   * This prop should only be set by the recursive component when it makes recursive calls.
   */
  prefix?: string;
  // Whether to show an explanatory header above the conditional group.
  // onRemove must be specified if showHeader is specified. onRemoved
  // is a request to remove the whole condition group.
  showHeader: boolean;

  /**
   * The start condition schedule time option selected by the user. Only 2 options should render
   * this component, hence the stricter union type. If not defined, it's assumed this component is
   * being rendered as a standalone form section.
   */
  startConditionOption?: ScheduleTimeValues.START_CONDITION_STAGE | ScheduleTimeValues.START_CONDITION_WORKFLOW;
  /**
   * Current workflow or workflow template context for the stage. Used to determine which field
   * instances to show users when selecting fields for start conditions.
   */
  workflowContext:
    | {
        type: "workflowTemplate";
        workflowTemplateId: number;
        workflowTemplateIsCreateViaEmailEnabled: boolean;
      }
    | {
        type: "workflow";
        workflowId: number;
      };
}

export const RegrelloConfigureConditionsRecursive = React.memo<RegrelloConfigureConditionsRecursiveProps>(
  function RegrelloConfigureConditionsFormSectionFn({
    allowedFieldPlugins,
    isRecursiveGroupsEnabled,
    className,
    prefix,
    form,
    isDisabled,
    dependingOnStageIds,
    startConditionOption,
    workflowContext,
    showHeader,
    onRemove,
  }: RegrelloConfigureConditionsRecursiveProps) {
    const fullPrefix = prefix || ScheduleTimeKeys.CONDITIONS;
    const conditionsPath = `${fullPrefix}.conditions` as `${ScheduleTimeKeys.CONDITIONS}.conditions`;

    // It is tempting to useFieldArray. However, useFieldArray does not play nicely with setValue, which parent components
    // use freely to invalidate the state of the entire condition group. If we useFieldArray, then updates to the whole array would not be observed.
    // "Important: use replace from useFieldArray instead, update entire field array with setValue will be removed in the next major version."
    // Source: https://react-hook-form.com/docs/useform/setvalue#main
    const conditionRows: Condition[] =
      useWatch({
        control: form.control,
        name: conditionsPath,
      }) ?? [];

    const removeCondition = useCallback(
      (idx: number) => {
        const next: Condition[] = conditionRows.filter((_, index) => index !== idx);
        form.setValue(conditionsPath, next as never);
      },
      [form, conditionRows, conditionsPath],
    );

    const updateCondition = useCallback(
      // biome-ignore lint/suspicious/noExplicitAny: any subset of the condition fields may be (un)set.
      (idx: number, value: any) => {
        const newVal = { ...value, displayOrder: conditionRows[idx].displayOrder };
        const next: Condition[] = conditionRows.map((v, index) => (idx === index ? newVal : v));
        form.setValue(conditionsPath, next as never);
      },
      [form, conditionRows, conditionsPath],
    );

    const groupsPath = `${fullPrefix}.conditionGroups` as `${ScheduleTimeKeys.CONDITIONS}.conditionGroups`;
    const conditionGroups: ConditionGroup[] = useWatch({ control: form.control, name: groupsPath });
    const nextDisplayOrder = conditionRows.length + conditionGroups.length;

    const appendCondition = useCallback(() => {
      const next: Condition[] = [
        ...conditionRows,
        {
          fieldInstance: null,
          operator: null,
          value1: null,
          value2: null,
          displayOrder: nextDisplayOrder,
        },
      ];
      form.setValue(conditionsPath, next as never);
    }, [form, conditionRows, conditionsPath, nextDisplayOrder]);

    const appendGroup = useCallback(() => {
      const next: ConditionGroup[] = [...conditionGroups, makeEmptyConditionGroup(nextDisplayOrder)];
      form.setValue(groupsPath, next as never);
    }, [form, conditionGroups, groupsPath, nextDisplayOrder]);

    const removeGroup = useCallback(
      (toDelete: number) => {
        const next: ConditionGroup[] = conditionGroups.filter((_, index) => index !== toDelete);
        form.setValue(groupsPath, next as never);
      },
      [form, conditionGroups, groupsPath],
    );

    const nestingLevel = fullPrefix.split("conditionGroups").length;
    const conditionsIndexPath = useCallback((index: number) => `${fullPrefix}.conditions.${index}`, [fullPrefix]);
    const connectivePath = `${fullPrefix}.connective` as `${ScheduleTimeKeys.CONDITIONS}.connective`;
    const connective = useWatch({ control: form.control, name: connectivePath });

    // (wsheeha): Split fields can't be used for start conditions.
    const filterCondition = (fieldInstance: FieldInstanceFields) =>
      !isOnSplitterTask(fieldInstance) && !isOnSplitChild(fieldInstance);

    return (
      <div
        className={clsx(
          "flex flex-col flex-wrap",
          {
            "bg-backgroundSoft": nestingLevel === 2,
            "bg-neutral-soft": nestingLevel >= 3,
          },
          className,
        )}
      >
        {showHeader ? (
          <div className="flex justify-between items-center p-1.5">
            <span className="px-2">
              {connective === ConditionConnective.AND
                ? t`All of the following are true`
                : t`Any of the following is true`}
            </span>
            <RegrelloButton iconOnly={true} onClick={onRemove} startIcon="delete" variant="ghost" />
          </div>
        ) : null}
        {conditionRows.map((row, index) => {
          return (
            <RegrelloSingleConditionForm
              key={row.displayOrder}
              allowedFieldPlugins={allowedFieldPlugins}
              connective={connective}
              connectivePath={connectivePath}
              dependingOnStageIds={dependingOnStageIds}
              filterCondition={filterCondition}
              form={form}
              index={index}
              isDisabled={isDisabled}
              prefix={conditionsIndexPath(index)}
              removeCondition={removeCondition}
              startConditionOption={startConditionOption}
              updateCondition={updateCondition}
              workflowContext={workflowContext}
            />
          );
        })}
        {isRecursiveGroupsEnabled &&
          conditionGroups?.map((group, index) => {
            const absIdx = index + conditionRows.length;
            return (
              <div key={group.displayOrder} className="flex gap-2 p-1.5">
                {absIdx === 0 && <div className="w-20 px-2 shrink-0 pt-3.5">{t`If`}</div>}
                {absIdx === 1 && (
                  <ConnectiveComponent
                    className="w-20 shrink-0"
                    connectivePath={connectivePath}
                    form={form}
                    isDisabled={isDisabled}
                  />
                )}
                {absIdx > 1 && (
                  <div className="w-20 px-2 shrink-0 pt-3.5">
                    {connective === ConditionConnective.AND ? t`and` : t`or`}
                  </div>
                )}
                <RegrelloConfigureConditionsRecursive
                  allowedFieldPlugins={allowedFieldPlugins}
                  className="flex border rounded flex-1"
                  dependingOnStageIds={dependingOnStageIds}
                  form={form}
                  isDisabled={false}
                  isRecursiveGroupsEnabled={isRecursiveGroupsEnabled}
                  onRemove={() => removeGroup(index)}
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  prefix={`${fullPrefix}.conditionGroups.${index}`}
                  showHeader={true}
                  startConditionOption={startConditionOption}
                  workflowContext={workflowContext}
                />
              </div>
            );
          })}
        <div className="flex gap-2 p-1.5">
          <div className="w-20" />
          <RegrelloButton
            // (zstanik): Add some arbitrary margin to make the add button line up with the field
            // instance selects.
            className="ml-24.5"
            dataTestId={DataTestIds.START_DEPENDENCY_ADD_START_CONDITION_BUTTON}
            disabled={isDisabled}
            intent="primary"
            onClick={appendCondition}
            startIcon="add"
            variant="ghost"
          >
            {t`Add condition`}
          </RegrelloButton>
          {isRecursiveGroupsEnabled && (
            <RegrelloTooltip
              content={
                nestingLevel === MAX_CONDITION_GROUP_NESTING_LEVEL
                  ? t`Condition groups can only be nested 3 levels deep`
                  : undefined
              }
            >
              <div>
                <RegrelloButton
                  dataTestId={DataTestIds.START_DEPENDENCY_ADD_START_CONDITION_GROUP_BUTTON}
                  disabled={isDisabled || nestingLevel === MAX_CONDITION_GROUP_NESTING_LEVEL}
                  intent="primary"
                  onClick={appendGroup}
                  startIcon="add"
                  variant="ghost"
                >
                  {t`Add condition group`}
                </RegrelloButton>
              </div>
            </RegrelloTooltip>
          )}
        </div>
      </div>
    );
  },
);
