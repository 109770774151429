import { i18n } from "@lingui/core";
import { detect, fromNavigator, fromStorage, fromUrl } from "@lingui/detect-locale";
import { localStorageSetTyped, RegrelloLocalStorageKey } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { type LanguageCode, SUPPORTED_LOCALES } from "@regrello/i18n-llm-translator";

import { type AvailableMessages, availableMessages } from "./locales/config";
import { FeatureFlagService } from "./services/FeatureFlagService";

// can be a function with custom logic or just a string, `detect` method will handle it
const DEFAULT_LANGUAGE = "en";

// Force en-GB for tests
const TEST_LOCALE = "en-GB";

function fromEnvironment() {
  // Detect as default when i18n is disabled
  return FeatureFlagService.isEnabled(FeatureFlagKey.I18N_PHASE_ONE_2024_10) ? undefined : DEFAULT_LANGUAGE;
}

function fromTest() {
  // Force i18n locale in test cases

  const locale = fromNavigator();
  if (locale === "test") {
    return TEST_LOCALE;
  }

  return undefined;
}

export async function initTranslation(languageCode?: LanguageCode, forceLangFromParameter?: boolean) {
  const supportedLocales = new Set<LanguageCode | typeof TEST_LOCALE>(SUPPORTED_LOCALES);

  // Support for test locale
  supportedLocales.add(TEST_LOCALE);

  const detectedLocale = forceLangFromParameter
    ? (languageCode ?? DEFAULT_LANGUAGE)
    : (detect(
        fromTest(),
        fromEnvironment(),
        // eslint-disable-next-line lingui/no-unlocalized-strings
        fromUrl("lang"),
        languageCode,
        fromStorage(RegrelloLocalStorageKey.LOCALE),
      ) ?? DEFAULT_LANGUAGE);

  // biome-ignore lint/suspicious/noConsole: i18n debug (temporary)
  console.log(
    // eslint-disable-next-line lingui/no-unlocalized-strings
    `[i18n] Detected: test=${fromTest()} env=${fromEnvironment()} url=${fromUrl("lang")} param=${languageCode} storage=${fromStorage(RegrelloLocalStorageKey.LOCALE)} browser=${fromNavigator()}`,
  );

  // Strip an country postfix
  const language = detectedLocale?.replace(/-[A-Z]+/, "") as LanguageCode;

  // Make sure we only deal with officially supported locales
  const effectiveLocale = supportedLocales.has(detectedLocale as LanguageCode)
    ? detectedLocale
    : supportedLocales.has(language)
      ? language
      : DEFAULT_LANGUAGE;

  const messagesFile = availableMessages.includes(detectedLocale as AvailableMessages)
    ? detectedLocale
    : availableMessages.includes(language as AvailableMessages)
      ? language
      : DEFAULT_LANGUAGE;

  // Dynamically load messages from separate chunk
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const { messages } = await import(`./locales/${messagesFile}/messages.po`);

  i18n.load(effectiveLocale, messages);
  i18n.activate(effectiveLocale);

  // biome-ignore lint/suspicious/noConsole: i18n debug (temporary)
  console.log(`[i18n] (${effectiveLocale}) Messages ${messagesFile}=${Object.keys(messages).length} activated`);

  if (languageCode != null) {
    localStorageSetTyped(RegrelloLocalStorageKey.LOCALE, detectedLocale);
  }

  // Make sure to let the browser know what language we are using
  document.documentElement.lang = detectedLocale;
}
