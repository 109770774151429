import { t, Trans } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING, sortIgnoreCaseWithExactMatchFirst } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  CustomFieldDefaultColumnOption,
  FieldType,
  type LatestSpectrumFieldVersionsV2QueryVariables,
  PropertyDataType,
  type RoleFields,
  type SpectrumFieldVersionFields,
  useLatestSpectrumFieldVersionsV2QueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldSelectOption } from "./_internal/selectOptions/RegrelloFormFieldSelectOption";
import { RegrelloFormFieldSelectOptionRegrelloObject } from "./_internal/selectOptions/RegrelloFormFieldSelectOptionRegrelloObject";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloSelectV2LoadingOption } from "./_internal/selectOptions/RegrelloSelectV2LoadingOption";
import {
  type RegrelloFormFieldSelectPropsV2,
  RegrelloFormFieldSelectV2,
  type RegrelloSelectChangeReason,
} from "./RegrelloFormFieldSelectV2";
import { DEBOUNCE_TIMEOUT, WORKFLOW_OWNER_FIELD_NAME } from "../../../constants/globalConstants";
import { PAGINATION_LIMIT } from "../../../constants/numbers";
import { FeatureFlagService } from "../../../services/FeatureFlagService";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { AsyncLoaded } from "../../../utils/typescript/AsyncLoaded";
import { RegrelloAddRoleDialog } from "../../views/modals/formDialogs/roles/RegrelloAddRoleDialog";
import { ConfigureSpectrumFieldDialog } from "../../views/modals/formDialogs/spectrumFields/ConfigureSpectrumFieldDialog";
import { SpectrumFieldPluginRegistrar } from "../spectrumFields/registry/spectrumFieldPluginRegistrar";
import type { SpectrumFieldPluginDecorator } from "../spectrumFields/types/SpectrumFieldPluginDecorator";

export interface RegrelloFormFieldSpectrumFieldSelectProps
  extends RegrelloFormFieldBaseProps<SpectrumFieldVersionFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<SpectrumFieldVersionFields>,
      "inputRef" | "isClearButtonEnabled" | "onChange" | "onClearClick" | "onClose" | "placeholder" | "size" | "value"
    > {
  /**
   * Whether to allow creating new tags and tag types.
   * @default false
   */
  allowCreateFields?: boolean;

  /**
   * Whether to allow creating new roles.
   * @default false
   */
  allowCreateRoles?: boolean;

  /**
   * Whether to allow selecting the workflow owner system field
   * @default false
   */
  allowSelectWorkflowOwner?: boolean;

  /**
   * An allow list for filtering which field plugins this field should allow the user to select.
   */
  allowedFieldPlugins?: Array<SpectrumFieldPluginDecorator<unknown>>;

  /**
   * A list of options that should not be displayed in the suggestions menu. Useful for preventing
   * already-selected fields from being selected again, for example.
   */
  omittedOptions?: SpectrumFieldVersionFields[];

  /** Ref to pass to the button/trigger element. */
  selectRef?: React.Ref<HTMLButtonElement>;
}

/**
 * This component renders a select input field where the user can select from all fields in
 * the system. It is a wrapper around RegrelloFormFieldSelect. It loads the data from the
 * graphql Spectrum Fields Query.
 */
export const RegrelloFormFieldSpectrumFieldSelect = React.memo<RegrelloFormFieldSpectrumFieldSelectProps>(
  function RegrelloFormFieldSpectrumFieldSelectFn({
    allowCreateFields,
    allowCreateRoles,
    allowedFieldPlugins,
    allowSelectWorkflowOwner,
    className,
    omittedOptions,
    onChange, // (clewis): Pull this out because we need to override it.
    onClose,
    selectRef,
    size,
    ...multiselectProps
  }) {
    const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);

    // (clewis): As a UX nicety, we want to pre-populate the typed value in the add dialog. This is
    // tricky because the inputValue is cleared as soon as we select an option, so we have to track
    // the dialog's defaultValue separately.
    const [defaultValueForCreateDialog, setDefaultValueForCreateDialog] = useState<string>("");
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
    const [isCreateRoleDialogOpen, setIsCreateRoleDialogOpen] = useState<boolean>(false);
    const [loadedOptionsV2, setLoadedOptions] = useState<SpectrumFieldVersionFields[]>(EMPTY_ARRAY);

    const { handleError } = useErrorHandler();

    // (zstanik): debounce search changes to another, throttled state variable that can be used for
    // the API calls.
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [throttledSearchValue, setThrottledSearchValue] = useState<string | undefined>(undefined);

    const debouncedSetThrottledSearchValue = useCallback(() => {
      setThrottledSearchValue(searchValue);
    }, [searchValue]);
    useDebounce(debouncedSetThrottledSearchValue, DEBOUNCE_TIMEOUT, [searchValue]);

    const fieldsQueryVariables = useMemo<LatestSpectrumFieldVersionsV2QueryVariables>(
      () => ({
        search: throttledSearchValue,
        sortBy: CustomFieldDefaultColumnOption.FIELD,
        limit: PAGINATION_LIMIT,
        offset: 0,
        params: {
          excludeControllerFields: true,
        },
      }),
      [throttledSearchValue],
    );

    const [getFieldsAsync, fieldsQueryResult] = useLatestSpectrumFieldVersionsV2QueryLazyQuery({
      variables: fieldsQueryVariables,
      // (dosipiuk): needed for `fetchMore` to trigger `loading` state
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onError: (error) => {
        handleError(error, {
          toastMessage: t`Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem`,
        });
      },
    });
    const asyncLoadedFieldsQueryResult = useMemo(
      () => AsyncLoaded.fromGraphQlQueryResult(fieldsQueryResult),
      [fieldsQueryResult],
    );

    // (hchen): Build a set for faster lookup, given that we'll need to filter the options
    // every time the suggestions menu opens.
    const omittedOptionIds = useMemo(
      () =>
        new Set(
          (omittedOptions ?? EMPTY_ARRAY)
            .filter((option) => option.spectrumField.id !== multiselectProps.value?.spectrumField.id)
            .map((option) => option.spectrumField.id),
        ),
      [multiselectProps.value?.spectrumField.id, omittedOptions],
    );

    // Update and sort the locally stored fields when the query finishes loading.
    useEffect(() => {
      if (AsyncLoaded.isLoading(asyncLoadedFieldsQueryResult)) {
        return;
      }

      if (AsyncLoaded.isError(asyncLoadedFieldsQueryResult) || AsyncLoaded.isNotLoaded(asyncLoadedFieldsQueryResult)) {
        setLoadedOptions([]);
        return;
      }

      // (clewis): Need to spread before sorting, because the loaded array is readonly. Also need to
      // include the "Add" option in order for it to emit successfully via onChange.
      setLoadedOptions(sortOptions(asyncLoadedFieldsQueryResult.value.latestSpectrumFieldVersionsV2.fields));
    }, [asyncLoadedFieldsQueryResult]);

    const filterOption = useCallback(
      (field: SpectrumFieldVersionFields) => {
        if (
          allowedFieldPlugins != null &&
          allowedFieldPlugins.length > 0 &&
          !allowedFieldPlugins.some((plugin) => plugin.canProcessSpectrumField(field))
        ) {
          return false;
        }

        if (field.field?.fieldType === FieldType.SYSTEM) {
          if (
            !allowSelectWorkflowOwner ||
            // (akager) Hack: We rely on the field name to determine if it's the workflow owner field.
            field.name !== WORKFLOW_OWNER_FIELD_NAME
          ) {
            return false;
          }
        }

        return !omittedOptionIds.has(field.spectrumField.id);
      },
      [allowSelectWorkflowOwner, allowedFieldPlugins, omittedOptionIds],
    );

    const handleAutocompleteOpen = useCallback(() => {
      // Reload the fields when the autocomplete opens.
      void getFieldsAsync();
    }, [getFieldsAsync]);

    const handleChange = useCallback(
      (nextValue: SpectrumFieldVersionFields | null, reason: RegrelloSelectChangeReason) => {
        if (nextValue == null) {
          onChange(null, reason);
          return;
        }

        onChange(nextValue, reason);
      },
      [onChange],
    );

    const handleInputValueChange = useCallback((value: string) => {
      setSearchValue(value);
    }, []);

    const handleScrollToBottom = useCallback(
      async ({ currentTarget }: { currentTarget: HTMLElement }) => {
        if (
          // When we reach bottom of container
          currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight &&
          // And we are not loading
          AsyncLoaded.isLoaded(asyncLoadedFieldsQueryResult) &&
          // And there are still fields to be fetched
          asyncLoadedFieldsQueryResult.value.latestSpectrumFieldVersionsV2.fields.length !==
            asyncLoadedFieldsQueryResult.value.latestSpectrumFieldVersionsV2.totalCount
        ) {
          await fieldsQueryResult.fetchMore({
            variables: {
              offset: asyncLoadedFieldsQueryResult.value.latestSpectrumFieldVersionsV2.fields.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev;
              }
              return {
                latestSpectrumFieldVersionsV2: {
                  totalCount: fetchMoreResult.latestSpectrumFieldVersionsV2.totalCount,
                  fields: [
                    ...prev.latestSpectrumFieldVersionsV2.fields,
                    ...fetchMoreResult.latestSpectrumFieldVersionsV2.fields,
                  ],
                },
              };
            },
          });
        }
      },
      [asyncLoadedFieldsQueryResult, fieldsQueryResult],
    );

    const handleCreateDialogClose = useCallback(() => setIsCreateDialogOpen(false), []);
    const handleCreateRoleDialogClose = useCallback(() => setIsCreateRoleDialogOpen(false), []);

    const handleFieldCreated = useCallback(
      (newField: SpectrumFieldVersionFields) => {
        setLoadedOptions(sortOptions([...loadedOptionsV2, newField]));
        onChange(newField, "create-option");
      },
      [loadedOptionsV2, onChange],
    );

    const handleRoleCreated = useCallback(
      (
        newRole: {
          spectrumFieldVersion?: SpectrumFieldVersionFields | null;
        } & RoleFields,
      ) => {
        const spectrumFieldVersion = newRole.spectrumFieldVersion;
        if (spectrumFieldVersion != null) {
          setLoadedOptions(sortOptions([...loadedOptionsV2, spectrumFieldVersion]));
          onChange(spectrumFieldVersion, "create-option");
        }
      },
      [loadedOptionsV2, onChange],
    );

    const renderOption = useCallback((option: SpectrumFieldVersionFields | null) => {
      if (option == null) {
        return;
      }

      if (option.propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID) {
        if (option.field?.regrelloObject == null) {
          return null;
        }

        return <RegrelloFormFieldSelectOptionRegrelloObject regrelloObject={option.field.regrelloObject} />;
      }

      const fieldPlugin = SpectrumFieldPluginRegistrar.getPluginForSpectrumField(option);

      return (
        <RegrelloFormFieldSelectOption
          mainSnippets={[{ text: option.name, highlight: false }]}
          startAdornment={
            <div className="mr-1">
              <RegrelloIcon
                iconName={fieldPlugin.getIconName(option.field?.fieldType, option.field ?? undefined)}
                intent="neutral"
              />
            </div>
          }
        />
      );
    }, []);

    const isTooltipEnabled = useCallback((option: SpectrumFieldVersionFields | null) => {
      // Disable tooltips for `RegrelloObject` since it has its own tooltip.
      if (option?.propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID) {
        return false;
      }

      return true;
    }, []);

    const groupByRoleOrField = useCallback((option: SpectrumFieldVersionFields) => {
      const isRoleField = option.field?.fieldRestriction?.filterByRole != null;
      if (isRoleField) {
        return t`Roles`;
      }
      return t`Fields`;
    }, []);

    const AddFieldOptionToRegrello = ({ name }: { name: ReactNode }) => (
      <span>
        <Trans>
          Add field <span>&apos;</span>
          {name}
          <span>&apos;</span> to Regrello
        </Trans>
      </span>
    );

    const extraAddFieldEndOptions = [
      <RegrelloSelectV2AddOption
        key="option-add"
        allowCreateOptions={allowCreateFields}
        iconName="add"
        messageWithInput={AddFieldOptionToRegrello}
        onSelect={(inputValue) => {
          setDefaultValueForCreateDialog(inputValue);
          setIsCreateDialogOpen(true);
        }}
      />,
      !AsyncLoaded.isReady(asyncLoadedFieldsQueryResult) ? (
        <RegrelloSelectV2LoadingOption key="option-loading" />
      ) : null,
    ];

    const AddRoleOptionToRegrello = ({ name }: { name: ReactNode }) => (
      <span>
        <Trans>
          Add role <span>&apos;</span>
          {name}
          <span>&apos;</span> to Regrello
        </Trans>
      </span>
    );

    const extraEndOptionsByGroupKey = {
      Roles: [
        <RegrelloSelectV2AddOption
          key="option-add-role"
          allowCreateDisabledText={t`Please contact an admin if you would like to create a new role`}
          allowCreateOptions={allowCreateRoles}
          iconName="add"
          message={t`Add role`}
          messageWithInput={AddRoleOptionToRegrello}
          onSelect={(inputValue) => {
            setDefaultValueForCreateDialog(inputValue);
            setIsCreateRoleDialogOpen(true);
          }}
          value="__CREATE_NEW_ROLE__"
        />,
      ],
      Fields: extraAddFieldEndOptions,
    };

    return (
      <>
        <RegrelloFormFieldSelectV2
          className={className}
          extraEndOptions={isPermissionsV2Enabled ? undefined : extraAddFieldEndOptions}
          extraEndOptionsByGroupKey={isPermissionsV2Enabled ? extraEndOptionsByGroupKey : undefined}
          filterOption={filterOption}
          getOptionLabel={getFieldLabel}
          getOptionValue={getFieldValue}
          groupBy={isPermissionsV2Enabled ? groupByRoleOrField : undefined}
          // (clewis): The query won't start refetching until after a short debounce delay. During
          // that delay, go ahead and show a loading state to prevent the internal filtering logic
          // from erroneously showing the "+ Add" option by itself.
          isLoading={AsyncLoaded.isInitialLoad(asyncLoadedFieldsQueryResult)}
          isServerFiltered={true}
          isTooltipEnabled={isTooltipEnabled}
          onChange={handleChange}
          onClose={onClose}
          onInputValueChange={handleInputValueChange}
          onOpen={handleAutocompleteOpen}
          onScroll={handleScrollToBottom}
          options={loadedOptionsV2}
          renderOption={renderOption}
          renderSelectedValue={renderOption}
          selectRef={selectRef}
          size={size}
          {...multiselectProps}
        />

        {isCreateDialogOpen ? (
          <ConfigureSpectrumFieldDialog
            allowedFieldPlugins={allowedFieldPlugins}
            defaultValues={{
              name: defaultValueForCreateDialog,
              allowedValues: [],
              helpText: "",
              isValueConstraintsEnabled: false,
              pluginUri: "",
              valueConstraints: [],
            }}
            isOpen={true}
            onAfterCreation={handleFieldCreated}
            onClose={handleCreateDialogClose}
          />
        ) : null}
        {isCreateRoleDialogOpen ? (
          <RegrelloAddRoleDialog
            defaultValues={{
              name: defaultValueForCreateDialog,
              allowInvitingPeopleToTheWorkspace: false,
            }}
            isOpen={true}
            onClose={handleCreateRoleDialogClose}
            onRoleAdded={handleRoleCreated}
          />
        ) : null}
      </>
    );
  },
);

function getFieldLabel(field: SpectrumFieldVersionFields): string {
  return field.name;
}

function getFieldValue(field: { id: number }) {
  return field.id.toString();
}

function sortOptions(options: SpectrumFieldVersionFields[]): SpectrumFieldVersionFields[] {
  return sortIgnoreCaseWithExactMatchFirst([...options], (option) => option.name, EMPTY_STRING);
}
