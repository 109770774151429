import { clsx, type WithDataTestId } from "@regrello/core-utils";
import React from "react";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";

export interface RegrelloLinkV2Props extends RouterLinkProps, WithDataTestId {
  /** When `true`, will render the children without the link, and wrapped in a span. */
  disabled?: boolean;

  /** When `true`, will omit className assignment. */
  unstyled?: boolean;
}

/** A link that works with react-router-dom instead of navigating with function calls. */
export const RegrelloLinkV2 = React.memo(
  React.forwardRef<HTMLAnchorElement, RegrelloLinkV2Props>(function RegrelloLinkV2Fn(
    { children, dataTestId, disabled = false, unstyled = false, ...restProps },
    ref,
  ) {
    const className = unstyled
      ? ""
      : clsx("font-medium text-sm", {
          "text-primary-textMuted hover:underline": !disabled,
        });

    if (disabled) {
      return <span className={className}>{children}</span>;
    }

    return (
      <RouterLink ref={ref} data-testid={dataTestId} {...restProps} className={clsx(className, restProps.className)}>
        {children}
      </RouterLink>
    );
  }),
);
