/* eslint-disable no-underscore-dangle */

import { EMPTY_ARRAY, isDefined } from "@regrello/core-utils";
import { type ViewColumnFields, WorkflowDefaultColumnOption } from "@regrello/graphql-api";

export function getHomeStartingDefaultColumns(columnsData?: ViewColumnFields[] | undefined) {
  const enabledDefaultColumnOptions = columnsData?.map((column) => column.defaultColumnOption).filter(isDefined);
  return enabledDefaultColumnOptions ?? EMPTY_ARRAY;
}

export function getHomeStartingFieldColumns(columnsData?: ViewColumnFields[] | undefined): ViewColumnFields[] {
  const enabledFieldColumnOptions = columnsData?.filter((column) => column.fieldId != null);
  return enabledFieldColumnOptions ?? EMPTY_ARRAY;
}

export function getColumnFieldId(id: string) {
  const idAsNumber = Number.parseInt(id, 10);
  return !Number.isNaN(idAsNumber) ? idAsNumber : undefined;
}

export function getColumnOption(id: string) {
  return Object.values(WorkflowDefaultColumnOption).find((option) => option === id);
}

/**
 * Util for joining the field ID and property ID into a string for the table column.
 */
export function getColumnIdFromViewColumn(viewColumn: ViewColumnFields): `${number}` | `${number}-${number}` {
  const fieldId = viewColumn.field?.id ?? -1;
  const regrelloObjectPropertyId = viewColumn.regrelloObjectProperty?.id ?? -1;

  if (regrelloObjectPropertyId !== -1) {
    return `${fieldId}-${regrelloObjectPropertyId}`;
  }

  return `${fieldId}`;
}
