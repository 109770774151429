import { t } from "@lingui/macro";
import { RegrelloButton } from "@regrello/ui-core";
import React, { type KeyboardEvent, useCallback, useRef } from "react";

export interface RegrelloFormFieldSelfContainedFormPopoverProps {
  error: string | undefined;
  fieldElement: React.ReactElement;
  onClose: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

/**
 * A popover that displays the provided form field element (passed via `children`) in its own form.
 * Also provides keyboard shortcuts for and manages focus after submit/cancel. Intended for internal
 * use strictly by `RegrelloFormFieldLayout`, not for general consumption.
 */
export const RegrelloFormFieldSelfContainedFormPopover = React.memo<RegrelloFormFieldSelfContainedFormPopoverProps>(
  function RegrelloFormFieldSelfContainedFormPopoverFn({ error, fieldElement, onClose, onSubmit }) {
    const submitButtonRef = useRef<HTMLButtonElement | null>(null);

    const submit = useCallback(() => {
      if (error != null) {
        onClose();
      } else {
        submitButtonRef.current?.click();
      }
    }, [error, onClose]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === "Enter" && event.metaKey) {
          submit();
        } else if (event.key === "Escape") {
          onClose();
        }
      },
      [submit, onClose],
    );

    const handleFormSubmit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        // (clewis): Prevent the native-form submission from refreshing the page.
        event.preventDefault();
        onSubmit(event);
        onClose();
      },
      [onClose, onSubmit],
    );

    return (
      <form onKeyDown={handleKeyDown} onSubmit={handleFormSubmit}>
        {fieldElement}

        <div className="flex items-center mt-1">
          <div className="flex-1" />
          <div className="flex-none">
            <RegrelloButton className="ml-1" onClick={onClose} variant="outline">
              {t`Cancel`}
            </RegrelloButton>

            <RegrelloButton
              ref={submitButtonRef}
              className="ml-1"
              disabled={error != null}
              intent="primary"
              type="submit"
            >
              {t`Save`}
            </RegrelloButton>
          </div>
        </div>
      </form>
    );
  },
);
