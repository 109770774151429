import type { PureQueryOptions } from "@apollo/client";
import { t } from "@lingui/macro";
import { EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type TagFields,
  TagsQueryDocument,
  type TagTableTagFields,
  type TagTypeFields,
  useCreateTagMutation,
  useUpdateTagMutation,
} from "@regrello/graphql-api";
import React, { useCallback, useMemo, useState } from "react";

import { ValidationRules } from "../../../../../constants/globalConstants";
import type { SubmitHandler } from "../../../../../types/form";
import { consoleWarnInDevelopmentModeOnly } from "../../../../../utils/environmentUtils";
import { getGraphQlErrorCode, RegrelloGraphQlErrorCode } from "../../../../../utils/errorUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../../../utils/sentryScopeUtils";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormDialog } from "../../../../atoms/dialog/RegrelloFormDialog";
import { RegrelloControlledFormFieldTagTypeSelect } from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";

const LABEL_WIDTH = 120; // (clewis): Wide enough to avoid line wrapping in the labels.

interface ConfigureTagFormFields {
  name: string;
  tagType: TagTypeFields | null;
}

export enum ConfigureTagDialogContext {
  ADD = "add",
  EDIT = "edit",
}

export interface ConfigureTagDialogProps {
  context:
    | {
        type: ConfigureTagDialogContext.ADD;
        /** An initial value to show in the "tag name" field. Intended to save the user some time.*/
        defaultName?: string;
        /** Callback invoked after a new tag has been created. */
        onTagAdded?: (newTag: TagTableTagFields) => void;
        /** An array of queries to refetch after a tag has been created. */
        refetchQueries?: PureQueryOptions[];
      }
    | {
        type: ConfigureTagDialogContext.EDIT;
        tag: TagFields | undefined;
        /** Callback invoked after a new tag has been updated. */
        onTagUpdated?: (newTag: TagFields) => void;
        /** An array of queries to refetch after a tag has been updated. */
        refetchQueries?: PureQueryOptions[];
      };

  /** Whether the dialog should render as open. */
  isOpen: boolean;

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;
}

/**
 * A dialog in which the user can add both new tags and new tag types. Encapsulates all necessary
 * communication with the backend and emits the new tag/tag type via a prop.
 */
export const ConfigureTagDialog = React.memo<ConfigureTagDialogProps>(function CreateTagDialogFn({
  context,
  isOpen,
  onClose,
}) {
  const { currentUser } = useUser();
  const { handleError } = useErrorHandler();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleClose = useCallback(() => {
    setErrorMessage(undefined);
    onClose();
  }, [onClose]);

  const [createTagAsync] = useCreateTagMutation({
    onError: (error) => {
      const errorCode = getGraphQlErrorCode(error);
      switch (errorCode) {
        case RegrelloGraphQlErrorCode.OBJECT_NAME_MUST_BE_UNIQUE:
          setErrorMessage(t`A tag with that name already exists.`);
          break;
        default:
          handleError(error, {
            toastMessage: t`Failed to create tag. Please try again, or contact a Regrello admin if you continue to see this error.`,
          });
          break;
      }
    },
    refetchQueries: context.refetchQueries ?? [{ query: TagsQueryDocument }],
  });
  const [updateTagAsync] = useUpdateTagMutation({
    onError: (error) => {
      const errorCode = getGraphQlErrorCode(error);
      switch (errorCode) {
        case RegrelloGraphQlErrorCode.OBJECT_NAME_MUST_BE_UNIQUE:
          setErrorMessage(t`A tag with that name already exists.`);
          break;
        default:
          handleError(error, {
            toastMessage: t`Failed to update tag. Please try again, or contact a Regrello admin if you continue to see this error.`,
          });
          break;
      }
    },
    refetchQueries: context.refetchQueries ?? [{ query: TagsQueryDocument }],
  });

  const handleSaveEdit = useCallback<SubmitHandler<ConfigureTagFormFields>>(
    async (data) => {
      if (context.type !== ConfigureTagDialogContext.EDIT || context.tag == null) {
        consoleWarnInDevelopmentModeOnly("`handleSaveEdit` is executed when context is 'add'. This is unexpected.");
        return false;
      }

      const result = await updateTagAsync({
        variables: {
          input: {
            name: data.name ?? context.tag.name,
            tagId: context.tag.id,
            tagTypeId: data.tagType?.id ?? context.tag.tagType.id,
          },
        },
      });

      if (result.errors != null) {
        return false;
      }

      if (result.data?.updateTag?.tag == null) {
        console.warn("Updated a tag successfully, but no tag data was returned in the response.");
        return false;
      }

      context.onTagUpdated?.(result.data.updateTag.tag);
      return true;
    },
    [context, updateTagAsync],
  );

  const handleAddTag = useCallback<SubmitHandler<ConfigureTagFormFields>>(
    async (data) => {
      if (context.type !== ConfigureTagDialogContext.ADD) {
        consoleWarnInDevelopmentModeOnly("`handleAddTag` is executed when context is 'edit'. This is unexpected.");
        return false;
      }
      if (data.tagType == null) {
        consoleWarnInDevelopmentModeOnly(
          "The ConfigureTagDialog form was submitted with a missing tagType value. " +
            "Please ensure that we're disabling the submit button until the form is valid.",
        );
        return false;
      }

      const result = await createTagAsync({
        variables: {
          input: {
            createdByUserId: currentUser.id,
            name: data.name,
            tagTypeId: data.tagType.id,
          },
        },
        refetchQueries: context.refetchQueries,
      });

      if (result.errors != null) {
        return false;
      }

      if (result.data?.createTag?.tag == null) {
        console.warn("Created a tag successfully, but no tag data was returned in the response.");
        return false;
      }

      context.onTagAdded?.(result.data.createTag.tag);
      return true;
    },
    [context, createTagAsync, currentUser.id],
  );

  const defaultValues: ConfigureTagFormFields = useMemo(() => {
    if (context.type === ConfigureTagDialogContext.ADD) {
      return { name: context.defaultName ?? EMPTY_STRING, tagType: null };
    }
    return {
      name: context.tag?.name ?? EMPTY_STRING,
      tagType: context.tag?.tagType ?? null,
    };
  }, [context]);

  const onSubmit = useMemo(
    () => (context.type === ConfigureTagDialogContext.ADD ? handleAddTag : handleSaveEdit),
    [context.type, handleAddTag, handleSaveEdit],
  );

  return (
    <RegrelloFormDialog
      dataTestId={DataTestIds.ADD_TAG_DIALOG}
      defaultValues={defaultValues}
      error={errorMessage}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={onSubmit}
      scopeNameForSentry={RegrelloFormDialogName.ADD_TAG}
      submitButtonText={context.type === ConfigureTagDialogContext.ADD ? t`Add` : t`Save`}
      title={context.type === ConfigureTagDialogContext.ADD ? t`Add tag` : t`Edit tag`}
    >
      {(form) => (
        <>
          <RegrelloControlledFormFieldText
            autoFocus={true}
            controllerProps={{ control: form.control, name: "name", rules: ValidationRules.REQUIRED }}
            dataTestId={DataTestIds.ADD_TAG_DIALOG_FIELD_NAME}
            isRequiredAsteriskShown={true}
            label={t`Name`}
            labelWidth={LABEL_WIDTH}
          />
          <RegrelloControlledFormFieldTagTypeSelect
            allowCreateTagTypes={true}
            controllerProps={{ control: form.control, name: "tagType", rules: ValidationRules.REQUIRED }}
            dataTestId={DataTestIds.ADD_TAG_DIALOG_FIELD_TAG_TYPE}
            helperText={t`This helps you organize all your tags into groups.`}
            isRequiredAsteriskShown={true}
            label={t`Tag category`}
            labelWidth={LABEL_WIDTH}
          />
        </>
      )}
    </RegrelloFormDialog>
  );
});
