import { EMPTY_ARRAY, isDefined } from "@regrello/core-utils";
import {
  ConditionConnective,
  ConditionOperator,
  type ViewColumnFields,
  type ViewColumnInput,
  type ViewFilterModifier,
  WorkflowDefaultColumnOption,
} from "@regrello/graphql-api";

import { UNDEFINED_COLUMN_ID } from "../../../../../constants/globalConstants";
import { RegrelloFormFieldFilterType } from "../../../../../types/FilterTypeUnion";
import {
  getFilterMultiValue,
  getFilterSingleValueAsString,
  getStageStatus,
} from "../../../../../utils/tableFilterUtils";
import { getColumnIdFromViewColumn } from "../../../../molecules/tableColumnsButton/_internal/tableColumnsUtils";
import type { RegrelloFilterGroup } from "../../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Types";
import type { HomeFilters } from "../HomePage";

export function getColumnInputFields(columns: ViewColumnFields[]): ViewColumnInput[] {
  return columns.map((column, index) => {
    const { filters, id, field, regrelloObjectProperty, ...fields } = column;

    // Intentionally discard 'field' and 'id' from the input fields.
    permitUnusedVariable(field);

    return {
      ...fields,
      // (krashanoff): New columns have a `displayOrder` of zero. If this does not match the element's index,
      // then fallback.
      displayOrder: column.displayOrder === 0 && index !== 0 ? index : column.displayOrder,
      viewColumnId: id !== UNDEFINED_COLUMN_ID ? id : undefined,
      regrelloObjectPropertyId: regrelloObjectProperty?.id,
      filters: filters.length > 0 ? filters : undefined,
      filterConnective:
        fields.defaultColumnOption === WorkflowDefaultColumnOption.STAGE_STATUS
          ? ConditionConnective.OR
          : ConditionConnective.AND,
    };
  });
}

export function getColumnName(column: ViewColumnFields) {
  const columnName = getColumnIdFromViewColumn(column);
  return column.defaultColumnOption != null ? column.defaultColumnOption : columnName;
}

export function getColumnInputFieldsWithFilters(
  columns: ViewColumnFields[],
  homeTableFilters: RegrelloFilterGroup<HomeFilters>,
): ViewColumnInput[] {
  return columns.map((column) => {
    const { field, id, regrelloObjectProperty, ...columnFields } = column;

    // Intentionally discard 'field' and 'id' from the input fields.
    permitUnusedVariable(field);
    permitUnusedVariable(id);
    const columnName = getColumnName(column);

    return {
      ...columnFields,
      regrelloObjectPropertyId: regrelloObjectProperty?.id,
      filterConnective:
        columnName === WorkflowDefaultColumnOption.STAGE_STATUS ? ConditionConnective.OR : ConditionConnective.AND,
      filters: getFilterInputs(homeTableFilters, columnName),
    };
  });
}

export function getColumnFieldsWithFilters(
  columns: ViewColumnFields[],
  homeTableFilters: RegrelloFilterGroup<HomeFilters>,
): ViewColumnFields[] {
  return columns.map((column) => {
    const { ...columnFields } = column;
    const columnName = getColumnName(column);

    return {
      ...columnFields,
      filterConnective:
        columnName === WorkflowDefaultColumnOption.STAGE_STATUS ? ConditionConnective.OR : ConditionConnective.AND,
      filters: getFilterInputs(homeTableFilters, columnName),
    };
  });
}

/**
 * Invoke this function with a variable that would otherwise be unused in the parent scope, to
 * avoid lint warnings and the need for lint overrides.
 */
function permitUnusedVariable(_value: unknown) {
  return;
}

function getFilterInputFields(filter: RegrelloFilterGroup<HomeFilters>["filters"][0]): {
  filterValue: string | undefined;
  filterValues: string[] | undefined;
  operator: ConditionOperator;
  modifier: ViewFilterModifier | undefined;
} {
  return {
    filterValue: getFilterSingleValueAsString(filter),
    filterValues: getFilterMultiValue(filter),
    operator: filter.filterDefinition.filter.operator,
    modifier: filter.filterDefinition.filter.modifier,
  };
}

function isFilterValueEmpty(filterValue: string | undefined, filterValues: string[] | undefined) {
  return (filterValue == null || filterValue.length === 0) && (filterValues == null || filterValues.length === 0);
}

function getFilterInputs(homeTableFilters: RegrelloFilterGroup<HomeFilters>, columnName: string) {
  const filters = homeTableFilters.filters.filter((filter) => filter.columnId === columnName);

  const filterInputs = (filters ?? EMPTY_ARRAY)
    .flatMap((filter) => {
      const { filterValue, filterValues, operator, modifier } = getFilterInputFields(filter);

      if (isFilterValueEmpty(filterValue, filterValues)) {
        return undefined;
      }

      // (wbuchanan): For the "Stage status" filter, we need to pass a list of filters 'OR'd
      // together to the backend rather than a single multivalue filter.
      if (filter.columnId === WorkflowDefaultColumnOption.STAGE_STATUS) {
        return filterValues?.map((value) => {
          return {
            operator: ConditionOperator.EQUALS,
            value: getStageStatus(value)?.toString(),
            values: undefined,
            modifier,
          };
        });
      }

      // (wbuchanan): Convert percent complete values to a decimal (i.e. 12 -> 0.12) to align with backend filtering.
      if (filter.columnId === WorkflowDefaultColumnOption.PERCENT_COMPLETE) {
        return {
          operator,
          // (zstanik): We don't support any decimal places for percent complete, so round the
          // calculated percentage to the nearest hundredth before passing to the BE.
          value: filterValue != null ? getPercentCompleteFilterInputFromValue(filterValue) : undefined,
          values: undefined,
          modifier,
        };
      }

      if (filter.filterDefinition.type === RegrelloFormFieldFilterType.DATE) {
        if (filter.filterDefinition.filter.operator === ConditionOperator.EQUALS) {
          // (elle): For equal date only and ignore time, get all workflows with timestamp between min and max
          // timestamps of the given day to get around the issue of comparing dates between time zones.
          if (filterValue == null) {
            return undefined;
          }
          const startDate = new Date(filterValue);
          const endDate = new Date(filterValue);

          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);

          return {
            operator: ConditionOperator.BETWEEN,
            value: undefined,
            values: [startDate.toISOString(), endDate.toISOString()],
            modifier,
          };
        }
        if (filter.filterDefinition.filter.operator === ConditionOperator.LESS_THAN) {
          // (elle): For before date, we need to get all workflows with timestamps less than the min
          // time of the selected value.
          if (filterValue == null) {
            return undefined;
          }

          const minDate = new Date(filterValue);
          minDate.setHours(0, 0, 0, 0);
          return {
            operator: ConditionOperator.LESS_THAN,
            value: minDate.toISOString(),
            values: undefined,
            modifier,
          };
        }
      }

      return [
        {
          operator,
          value: filterValue,
          values: filterValues,
          modifier,
        },
      ];
    })
    .filter(isDefined);

  return filterInputs;
}

export function dropWidthFromViewColumnFields(columnsState: ViewColumnFields[] | undefined) {
  return columnsState?.map((column) => {
    const { columnWidth, ...columnFields } = column;
    permitUnusedVariable(columnWidth);
    return columnFields;
  });
}

export function convertPercentCompleteToInt(percentComplete: number | string): number {
  const percentCompleteNumberFromInput =
    typeof percentComplete === "string" ? Number.parseFloat(percentComplete) : percentComplete;
  return Math.round(percentCompleteNumberFromInput * 100);
}

export function getPercentCompleteFilterInputFromValue(percentComplete: string): string {
  return (Number.parseFloat(percentComplete) / 100).toFixed(2);
}
