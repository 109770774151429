/* eslint-disable lingui/no-unlocalized-strings */

/**
 * A heuristic, best-effort function that returns true if the provided key name would produce
 * exactly one character if it were to occur in an `input` or `textarea`.
 *
 * Useful for `keydown`/`keyup` handlers, where the key press won't have reflected in the input yet.
 */
export function getWouldKeyboardEventTypeOneCharacter(event: { key: string }): boolean {
  if (event.key == null) {
    return false;
  }
  if (event.key.length !== 1) {
    return false;
  }
  return !KEYS_THAT_DONT_TYPE_A_CHARACTER.has(event.key);
}

// (clewis): From https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
const KEYS_THAT_DONT_TYPE_A_CHARACTER = new Set([
  "Alt",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "AudioVolumeDown",
  "AudioVolumeMute",
  "AudioVolumeUp",
  "Backspace",
  "CapsLock",
  "ContextMenu",
  "Control",
  "Control",
  "Delete",
  "End",
  "Enter",
  "Escape",
  "F1",
  "F10",
  "F11",
  "F12",
  "F2",
  "F3",
  "F4",
  "F5",
  "F6",
  "F7",
  "F8",
  "F9",
  "Home",
  "Insert",
  "LaunchApplication1",
  "LaunchApplication2",
  "LaunchMediaPlayer",
  "Meta",
  "NumLock",
  "PageDown",
  "PageUp",
  "Pause",
  "PrintScreen",
  "ScrollLock",
  "Shift",
  "Shift",
  "Tab",
]);
