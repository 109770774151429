import { clsx, useSimpleHover } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloButton,
  RegrelloIcon,
  type RegrelloIconName,
  RegrelloShape,
  RegrelloTooltip,
  RegrelloTooltippedText,
} from "@regrello/ui-core";
import React from "react";
import type { ConnectDragSource, ConnectDropTarget } from "react-dnd";

export interface RegrelloContentChipProps {
  /** Custom classes to provide to child elements. */
  classNames?: {
    root?: string;
    iconName?: string;
    deleteButton?: string;
  };

  /** Props for adding drag 'n' drop support. */
  dndProps?: {
    /** Ref to be passed to the chip's drop area. */
    dropRef: ConnectDropTarget;

    /** Ref to be passed to the chip's draggable area. */
    dragRef: ConnectDragSource;

    /** If true, a blue drop indicator will render on the chip's right side. */
    isDraggedOverFromBefore: boolean;

    /** If true, a blue drop indicator will render on the chip's left side. */
    isDraggedOverFromAfter: boolean;
  };

  /** Optional icon to render at the start of the chip. */
  icon?: RegrelloIconName;

  /**
   * If defined, the chip will be rendered with a faint gold background and this tooltip text to
   * indicate it's a "special" chip in an info state.
   */
  infoStateTooltipText?: string;

  /**
   * Whether the chip is currently disabled.
   * @default false
   */
  isDisabled?: boolean;

  /**
   * Whether the chip is being used as a drag preview.
   * @default false
   */
  isDragPreview?: boolean;

  /**
   * Whether the chip is being used as a preview. Note the chip won't be interactive, like when it's
   * disabled, but will have different styles applied.
   * @default false
   */
  isPreview?: boolean;

  /**
   * Whether the chip is currently selected.
   * @default false
   */
  isSelected?: boolean;

  /** The display name to render. */
  name: string;

  /** Callback invoked when the chip is deleted. If not provided, the delete icon will not be
   * rendered. */
  onDelete?: () => void;
}

/**
 * A visual chip that represents a 'thing' in Regrello (e.g., a field, a tag). Updated per new
 * designs and functionality as of Jun 2023. Warning: this component hasn't been thoroughly tested
 * for general use and will likely be replaced by the new Rex design system components.
 *
 * @deprecated as of 26 Mar 2023; use <RegrelloChip> instead.
 */
export const RegrelloContentChip = React.memo<RegrelloContentChipProps>(function RegrelloContentChipFn({
  classNames,
  dndProps,
  icon,
  infoStateTooltipText,
  isDisabled = false,
  isDragPreview = false,
  isPreview = false,
  isSelected = false,
  name,
  onDelete,
}: RegrelloContentChipProps) {
  const { isHovered, onMouseEnter, onMouseLeave } = useSimpleHover();

  return (
    <div
      ref={(elem) => {
        dndProps?.dropRef(elem);
        dndProps?.dragRef(elem);
      }}
      className={clsx(
        "relative inline-flex items-center gap-0.5 h-6 max-w-full py-1 pl-1 bg-backgroundSoft rounded border",
        {
          "bg-primary-soft": isSelected && !isDisabled,
          "hover:cursor-grab active:cursor-grabbing": dndProps != null && !isDisabled && !isPreview,
          "after:absolute after:inset-0 after:content-[''] after:shadow-[inset_-2px_0_0_#2183DE]":
            dndProps?.isDraggedOverFromBefore && !isDisabled,
          "before:absolute before:inset-0 before:content-[''] before:shadow-[inset_2px_0_0_#2183DE]":
            dndProps?.isDraggedOverFromAfter && !isDisabled,
          "bg-neutral-soft opacity-75 border-neutral-borderInteractive shadow-[0_0_8px_3px_#DBDEE2]": isDragPreview,
          "hover:bg-neutral-softHovered hover:border-neutral-borderInteractiveHovered active:bg-neutral-softPressed":
            !isDisabled && !isPreview,
          "pr-1.5": !onDelete,
          "pl-1.5": !icon,
          "bg-warning-soft": infoStateTooltipText != null,
        },
        classNames?.root,
      )}
      // (zstanik): Necessary additional attributes when adding drag 'n' drop support in certain
      // contexts.
      data-drag-handle={dndProps != null}
      data-testid={DataTestIds.CONTENT_CHIP}
      draggable={dndProps != null}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <RegrelloTooltip content={infoStateTooltipText} variant="popover">
        <div
          className={clsx(
            "flex items-center gap-0.5 min-w-0 grow basis-0",
            {
              "opacity-50": isDisabled,
            },
            classNames?.iconName,
          )}
        >
          {icon != null && <RegrelloIcon className="text-neutral-icon" iconName={icon} size="small" />}
          <RegrelloTooltippedText additionalClassNames="mt-px" lines={1}>
            {name}
          </RegrelloTooltippedText>
        </div>
      </RegrelloTooltip>
      {onDelete != null && (
        <RegrelloButton
          className={clsx(
            "text-neutral-iconMuted",
            {
              [clsx(
                "text-neutral-icon",
                "hover:text-textMuted",
                "hover:bg-neutral-transparent",
                "active:bg-neutral-transparent",
              )]: isHovered && !isDisabled,
            },
            classNames?.deleteButton,
          )}
          disabled={isDisabled}
          iconOnly={true}
          onClick={onDelete}
          shape={RegrelloShape.CIRCLE}
          size="x-small"
          startIcon="close"
          variant="ghost"
        />
      )}
    </div>
  );
});
