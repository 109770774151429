import { i18n } from "@lingui/core";

import { I18N_CONFIG } from "../consts";

// TypeScript helpers to return typed values from static analysis of i18n.config.json

/** Get all keys of a map */
type AllKeys<T> = T extends unknown ? keyof T : never;

/** All possible types of a given key */
type PickType<T, K extends AllKeys<T>> = T extends { [k in K]?: unknown } ? T[K] : undefined;

/** Proxy to recognise K */
type PickTypeOf<T, K extends string | number | symbol> = K extends AllKeys<T> ? PickType<T, K> : never;

/** Merge all keys and possible value types to one type */
type Merge<T extends object> = {
  [k in AllKeys<T>]?: PickTypeOf<T, k>;
};

/** Get all values of a map */
type Values<T> = T[keyof T];

// Specific to i18n.config.json

/** Whole i18n.config.json as type */
type I18NConfig = typeof I18N_CONFIG;

/** Locale config part of i18n.config.json */
type LocaleConfigAttributes = I18NConfig["localeConfig"];

/** Locales available in locale config */
type LocaleConfigLocales = keyof LocaleConfigAttributes;

/** All available attributes in locale config section */
type I18NConfigAttributes = AllKeys<LocaleConfigAttributes[LocaleConfigLocales]>;

/** Type of all entries in locale config section with optional attributes */
type LocaleConfigMap = Merge<Values<LocaleConfigAttributes>>;

/**
 * Retrieves the internationalization (i18n) configuration for a given attribute.
 *
 * @template Attr - The type of the attribute key.
 * @param attribute - The attribute key for which the i18n configuration is to be retrieved.
 * @returns The i18n configuration for the specified attribute, or undefined if not found.
 */
export function getI18NConfig<Attr extends I18NConfigAttributes>(attribute: Attr): LocaleConfigMap[Attr] | undefined {
  const localeConfig = I18N_CONFIG.localeConfig;

  if (!localeConfig) {
    return undefined;
  }

  const currentLocaleConfig = localeConfig[i18n.locale as LocaleConfigLocales];

  if (!currentLocaleConfig) {
    return undefined;
  }

  const typedCurrentLocaleConfig: LocaleConfigMap = currentLocaleConfig;

  return typedCurrentLocaleConfig[attribute];
}
