/**
 * Injects a separator between each element in `elements`. Useful for creating comma-separated
 * lists. Pass a callback for `separator` if the separators are `React.ReactElement`s and you need to
 * provide a unique React `key` per separator.
 */
export function intersperse<T>(
  elements: T[],
  separator:
    | { type: "value"; value: T }
    | { type: "callback"; callback: (leftElement: T, leftIndex: number, rightElement: T, rightIndex: number) => T },
): T[] {
  return elements.reduce((arr: T[], element, i) => {
    arr.push(element);

    // Insert a separator after all but the final element.
    if (i < elements.length - 1) {
      arr.push(separator.type === "value" ? separator.value : separator.callback(element, i, elements[i + 1], i + 1));
    }

    return arr;
  }, []);
}
