import { t } from "@lingui/macro";
import { type AnyObject, EMPTY_ARRAY, EMPTY_STRING, endOfDay, getKeys } from "@regrello/core-utils";
import type {
  SpectrumFieldConstraintFields,
  SpectrumFieldVersionFields,
  SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import { startOfDay } from "date-fns";

import {
  AllAllowedFileTypes,
  type FrontendAllowedFileTypes,
  ValidationRules,
} from "../../../../constants/globalConstants";

export enum FrontendValueConstraintRuleName {
  MAX_CHARACTER = "max_characters",
  MIN_CHARACTER = "min_characters",

  MAX_INT = "max_int",
  MIN_INT = "min_int",

  MAX_FLOAT = "max_float",
  MIN_FLOAT = "min_float",

  MAX_PARTY = "max_parties",

  BEFORE_OR_AFTER_TODAY_INCLUSIVE = "before_or_after_today_inclusive",

  EMAIL = "email",

  DOCUMENT_TYPE = "document_type",
  DOCUMENT_DISALLOW_LINK = "document_disallow_link",
}

export const ON_OR_AFTER_TODAY_ARG = "1";
export const ON_OR_BEFORE_TODAY_ARG = "-1";
/** Max party is -1, BE will ignore this constraint. */
export const ALLOW_MULTIPLE_PARTY_ARG = "-1";
/** Max party is 1. */
export const DISALLOW_MULTIPLE_PARTY_ARG = "1";

export function getValidationRuleFromFieldConstraint(fieldConstraint: SpectrumFieldConstraintFields) {
  const constraintArgs = fieldConstraint.constraintArgs;
  switch (fieldConstraint.spectrumValueConstraint.valueConstraintRule) {
    case FrontendValueConstraintRuleName.MIN_CHARACTER:
      return ValidationRules.MIN_LENGTH(Number.parseInt(constraintArgs[0], 10));
    case FrontendValueConstraintRuleName.MAX_CHARACTER:
      return ValidationRules.MAX_LENGTH(Number.parseInt(constraintArgs[0], 10));
    case FrontendValueConstraintRuleName.MIN_INT:
      return ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.parseInt(constraintArgs[0], 10));
    case FrontendValueConstraintRuleName.MIN_FLOAT:
      return ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.parseFloat(constraintArgs[0]));
    case FrontendValueConstraintRuleName.MAX_INT:
      return ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.parseInt(constraintArgs[0], 10));
    case FrontendValueConstraintRuleName.MAX_FLOAT:
      return ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.parseFloat(constraintArgs[0]));
    case FrontendValueConstraintRuleName.EMAIL:
      return ValidationRules.VALID_EMAIL;
    case FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE: {
      if (constraintArgs.length === 0) {
        return undefined;
      }
      if (Number.parseInt(constraintArgs[0], 10) > 0) {
        return ValidationRules.AFTER_DATE(startOfDay(new Date()));
      }
      return ValidationRules.BEFORE_DATE(endOfDay(new Date()));
    }
    case FrontendValueConstraintRuleName.DOCUMENT_TYPE:
      return ValidationRules.VALID_DOCUMENT_FORMAT(
        constraintArgs.filter((arg) =>
          AllAllowedFileTypes.includes(arg as FrontendAllowedFileTypes),
        ) as FrontendAllowedFileTypes[],
      );
    default:
      return undefined;
  }
}

export function getValueConstraintDisplayName(valueConstraint: SpectrumValueConstraintFields) {
  switch (valueConstraint.valueConstraintRule) {
    case FrontendValueConstraintRuleName.MIN_CHARACTER:
      return t`Min length (characters)`;
    case FrontendValueConstraintRuleName.MAX_CHARACTER:
      return t`Max length (characters)`;
    case FrontendValueConstraintRuleName.MIN_INT:
    case FrontendValueConstraintRuleName.MIN_FLOAT:
      return t`Minimum value`;
    case FrontendValueConstraintRuleName.MAX_INT:
    case FrontendValueConstraintRuleName.MAX_FLOAT:
      return t`Maximum value`;
    case FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE:
      return t`Accepted dates`;
    case FrontendValueConstraintRuleName.DOCUMENT_TYPE:
      return t`File extensions`;
    case FrontendValueConstraintRuleName.DOCUMENT_DISALLOW_LINK:
      return t`Disallow external link`;
    case FrontendValueConstraintRuleName.MAX_PARTY:
      return t`Allow adding multiple people or teams`;
    default:
      return EMPTY_STRING;
  }
}

export function getIsValueConstraintEmpty(valueConstraint: SpectrumValueConstraintFields, args: string[]) {
  if (args.length === 0) {
    return true;
  }

  switch (valueConstraint.valueConstraintRule) {
    case FrontendValueConstraintRuleName.DOCUMENT_DISALLOW_LINK:
      return args[0] === "false";
    case FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE:
      return args[0] === "0";
    case FrontendValueConstraintRuleName.MAX_PARTY:
      return args[0] === "1";
    default:
      return args.every((arg) => arg.length === 0);
  }
}

export function getNumberOfArgsByConstraint(valueConstraint: SpectrumValueConstraintFields) {
  switch (valueConstraint.valueConstraintRule) {
    case FrontendValueConstraintRuleName.MIN_CHARACTER:
    case FrontendValueConstraintRuleName.MAX_CHARACTER:
    case FrontendValueConstraintRuleName.MIN_INT:
    case FrontendValueConstraintRuleName.MAX_INT:
    case FrontendValueConstraintRuleName.MIN_FLOAT:
    case FrontendValueConstraintRuleName.MAX_FLOAT:
    case FrontendValueConstraintRuleName.MAX_PARTY:
    case FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE:
    case FrontendValueConstraintRuleName.DOCUMENT_DISALLOW_LINK:
      return 1;
    case FrontendValueConstraintRuleName.EMAIL:
      return 0;
    default:
      return 0;
  }
}

export function getAllValidationRulesFromFieldConstraints(
  isRequired: boolean,
  fieldConstraints: SpectrumFieldConstraintFields[],
) {
  const result = fieldConstraints.reduce((validationRules, fieldConstraint) => {
    const validationRule = getValidationRuleFromFieldConstraint(fieldConstraint);
    if (validationRule == null) {
      return validationRules;
    }
    // (hchen): We have validated the rule is defined, it must have a key
    const ruleName = getKeys(validationRule)[0];
    validationRules[ruleName] = validationRule[ruleName];
    return validationRules;
  }, {} as AnyObject);
  if (isRequired) {
    return {
      ...ValidationRules.REQUIRED,
      ...result,
    };
  }
  return {
    ...ValidationRules.NOT_REQUIRED,
    ...result,
  };
}

export interface FrontendActiveRules {
  show: boolean;
  hide: boolean;
  required: boolean;
}

export function getActiveRuleAsBoolean(
  spectrumRuleMetadata: Array<{ type: string; value: string }> | undefined,
): FrontendActiveRules {
  const rules: FrontendActiveRules = {
    show: false,
    hide: false,
    required: false,
  };
  if (spectrumRuleMetadata == null) {
    return rules;
  }
  for (const { value } of spectrumRuleMetadata) {
    switch (value) {
      case "hide":
        rules.hide = true;
        break;
      case "show":
        rules.show = true;
        break;
      case "mandatory":
        rules.required = true;
        rules.show = true;
        break;
      default:
        break;
    }
  }
  return rules;
}

export function getAllowedFileTypes(spectrumFieldVersion: SpectrumFieldVersionFields) {
  for (const constraint of spectrumFieldVersion.fieldConstraints) {
    if (constraint.spectrumValueConstraint.valueConstraintRule === FrontendValueConstraintRuleName.DOCUMENT_TYPE) {
      return constraint.constraintArgs.map((fileExtension) => `.${fileExtension}` as `.${string}`);
    }
  }
  return EMPTY_ARRAY;
}

export function getIsLinkDisallowed(spectrumFieldVersion: SpectrumFieldVersionFields) {
  for (const constraint of spectrumFieldVersion.fieldConstraints) {
    if (
      constraint.spectrumValueConstraint.valueConstraintRule === FrontendValueConstraintRuleName.DOCUMENT_DISALLOW_LINK
    ) {
      return constraint.constraintArgs.length > 0 && constraint.constraintArgs[0] === "true";
    }
  }
  return false;
}

export function getIsMultiPartyAllowed(spectrumFieldVersion: SpectrumFieldVersionFields) {
  for (const constraint of spectrumFieldVersion.fieldConstraints) {
    if (constraint.spectrumValueConstraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_PARTY) {
      return constraint.constraintArgs.length > 0 && constraint.constraintArgs[0] === ALLOW_MULTIPLE_PARTY_ARG;
    }
  }
  return false;
}
