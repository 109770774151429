import { t } from "@lingui/macro";
import { RegrelloAvatar, RegrelloIcon, RegrelloTooltip, RegrelloTypography } from "@regrello/ui-core";
import React from "react";

import { RegrelloPartyAvatar } from "./RegrelloPartyAvatar";
import { RegrelloPartyName } from "./RegrelloPartyName";
import { type FlatParty, flatPartyListSortComparator } from "./utils";

export interface RegrelloPartyAvatarsProps {
  /**
   * Whether to visually highlight any deleted parties in the array of parties.
   * @default false
   */
  isDeletedPartiesHighlighted?: boolean;

  /**
   * Whether to show a warning icon with a tooltip if some parties in the array are deleted.
   * @default false
   */
  showInactivePartyWarningForList?: boolean;

  /** The list of parties to render. */
  parties: FlatParty[];

  /**
   * The size of the avatars.
   * @default "x-small"
   */
  size?: "x-small" | "small";
}

const MAX_VISIBLE_AVATARS = 5;

/**
 * Elegantly displays one or more party avatars. Shows a row of avatar icons if there is more than
 * one party in `parties`, and shows "+ N more" if there more than a few.
 */
export const RegrelloPartyAvatars = React.memo<RegrelloPartyAvatarsProps>(function RegrelloPartyAvatarsFn({
  isDeletedPartiesHighlighted,
  showInactivePartyWarningForList,
  parties,
  size = "x-small",
}: RegrelloPartyAvatarsProps) {
  // (wbuchanan): sorting prevents icons changing order upon polling
  const sortedParties = [...parties].sort(flatPartyListSortComparator);

  return (
    <div className="not-first:-ml-1 min-w-0">
      {sortedParties.length === 1 ? (
        <RegrelloPartyAvatar
          isHighlightedIfDeleted={isDeletedPartiesHighlighted}
          party={sortedParties[0]}
          size={size}
        />
      ) : (
        <RegrelloTypography className="flex cursor-default" component="div" noWrap={true}>
          {sortedParties.slice(0, MAX_VISIBLE_AVATARS).map((assignee, index) => {
            return (
              <RegrelloTooltip
                // (elle): In SCIM m2, we introduced a duo field instance for manager fields, which means each party
                // field has another field with the same id. Hence, we cannot use only field id as the key since they
                // are not unique. We need a combination of field type and name.
                key={`${assignee.id}-${assignee.fullName}-${assignee.type}`}
                content={<RegrelloPartyName party={assignee} />}
              >
                <div className="not-first:-ml-1 min-w-0">
                  <RegrelloPartyAvatar
                    hideText={true}
                    isStackedBordersEnabled={index > 0}
                    party={assignee}
                    size={size}
                  />
                </div>
              </RegrelloTooltip>
            );
          })}
          {sortedParties.length > MAX_VISIBLE_AVATARS && (
            <RegrelloTooltip
              content={
                <div className="flex flex-col gap-1">
                  {sortedParties.slice(MAX_VISIBLE_AVATARS).map((party) => {
                    return <RegrelloPartyAvatar key={party.id} party={party} size={size} />;
                  })}
                </div>
              }
            >
              <div className="not-first:-ml-1 min-w-0">
                <RegrelloAvatar
                  isHighlightedWhenInactive={isDeletedPartiesHighlighted}
                  shape="circle"
                  size={size}
                  visual={{
                    type: "initials",
                    text: `+${sortedParties.length - MAX_VISIBLE_AVATARS}`,
                  }}
                />
              </div>
            </RegrelloTooltip>
          )}
          {showInactivePartyWarningForList && sortedParties.some((party) => party.isInactive) && (
            <RegrelloTooltip content={t`Some people assigned to the task are inactive`} side="top">
              <span>
                <RegrelloIcon iconName="alert-outline" intent="neutral" size="x-small" />
              </span>
            </RegrelloTooltip>
          )}
        </RegrelloTypography>
      )}
    </div>
  );
});
