// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserLanguageVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  language: Types.UserLanguage;
}>;


export type UpdateUserLanguage = { updateUserLanguage?: { error?: string | null, user?: { id: number, language?: Types.UserLanguage | null } | null } | null };


export const UpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($id: ID!, $language: UserLanguage!) {
  updateUserLanguage(id: $id, language: $language) {
    error
    user {
      id
      language
    }
  }
}
    `;
export type UpdateUserLanguageMutationFn = Apollo.MutationFunction<UpdateUserLanguage, UpdateUserLanguageVariables>;

/**
 * __useUpdateUserLanguage__
 *
 * To run a mutation, you first call `useUpdateUserLanguage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguage, { data, loading, error }] = useUpdateUserLanguage({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateUserLanguage(baseOptions?: Apollo.MutationHookOptions<UpdateUserLanguage, UpdateUserLanguageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLanguage, UpdateUserLanguageVariables>(UpdateUserLanguageDocument, options);
      }
export type UpdateUserLanguageHookResult = ReturnType<typeof useUpdateUserLanguage>;
export type UpdateUserLanguageMutationResult = Apollo.MutationResult<UpdateUserLanguage>;
export type UpdateUserLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateUserLanguage, UpdateUserLanguageVariables>;