/* eslint-disable lingui/no-unlocalized-strings */

// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { type ClassValue, clsx as clsxOriginal } from "clsx"; // eslint-disable-line no-restricted-imports
import { extendTailwindMerge } from "tailwind-merge";

const customTwMerge = extendTailwindMerge({
  extend: {
    // (dosipiuk): If we override default preset values of tailwind, we need to change them here
    theme: {},
    // (dosipiuk): Custom class groups - classes that are mutually exclusive.
    // Only the last one in `className` from particular group will be applied, rest will be removed.
    classGroups: {
      rounded: ["rounded", "rounded-default", "rounded-focused", "rounded-circular", "rounded-none"],
    },
    // (dosipiuk): Class group conflicts that are not modifying single value. Ex. `px` and `pr`, `pl`
    conflictingClassGroups: {},
  },
});

export function clsx(...inputs: ClassValue[]) {
  return customTwMerge(clsxOriginal(inputs));
}

// Builds a class string compliant with Tailwind CSS that inserts the provided arbitrary variant in
// the documented format for each provided class name.
export function generateClassStringWithArbitraryVariant(arbitraryVariant: string, classNames: string[]): string {
  return classNames.reduce((classString, className) => {
    return `${classString} [${arbitraryVariant}]:${className}`;
  }, "");
}
