import { cva } from "class-variance-authority";

import type { RegrelloButtonVariant } from "./RegrelloButtonVariant";
import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import type { RegrelloShape } from "../../utils/enums/RegrelloShape";
import type { RegrelloSize } from "../../utils/enums/RegrelloSize";

export const buttonVariants = cva<{
  shape: Record<RegrelloShape, string>;
  intent: Record<Exclude<RegrelloIntent, "warning" | "success" | "secondary"> | "alternate", string>;
  variant: Record<RegrelloButtonVariant, string>;
  size: Record<RegrelloSize, string>;
  iconOnly: Record<RegrelloSize, string>;
}>(
  [
    "disabled:pointer-events-none",
    "disabled:opacity-30",
    "focus-visible:outline-2",
    "focus-visible:outline-primary-solid",
    "focus-visible:outline-offset-3",
    "font-semibold",
    "inline-flex",
    "items-center",
    "justify-center",
    "text-sm",
    "relative", // To ensure we contain the 'loading' spinner.
  ],
  {
    variants: {
      /**
       * The shape to use for the button's corners.
       * @default RegrelloShape.RECTANGLE
       */
      shape: {
        rectangle: "rounded",
        circle: "rounded-circular",
      },
      /**
       * The semantic intent the button should convey.
       * @default RegrelloIntent.PRIMARY
       */
      intent: {
        neutral: "",
        primary: "",
        danger: "",
        alternate: "",
      },
      /**
       * The visual weight with which the button should appear.
       * @default RegrelloButtonVariant.SOLID
       */
      variant: {
        solid: "",
        outline: "",
        ghost: "",
      },
      /**
       * The size of the button.
       * @default RegrelloSize.MEDIUM
       */
      size: {
        "x-small": "px-1.5 py-1 text-xs",
        small: "px-2 py-1",
        medium: "px-2.5 py-1.5",
        large: "px-3 py-2",
        "x-large": "px-3.5 py-2 text-base",
      },
      iconOnly: {
        "x-small": "w-6 h-6",
        small: "w-7 h-7",
        medium: "w-8 h-8",
        large: "w-9 h-9",
        "x-large": "w-10 h-10",
      },
    },
    compoundVariants: [
      // Solid
      {
        intent: "primary",
        variant: "solid",
        class: ["bg-primary-solid", "hover:bg-primary-solidHovered", "active:rg-solidPressed", "text-textContrast"],
      },
      {
        intent: "danger",
        variant: "solid",
        class: ["bg-danger-solid", "hover:bg-danger-solidHovered", "active:rg-solidPressed", "text-textContrast"],
      },
      {
        intent: "neutral",
        variant: "solid",
        class: ["bg-neutral-solid", "hover:bg-neutral-solidHovered", "active:rg-solidPressed", "text-textContrast"],
      },
      /** @deprecated as of 2024.05.28. Originally introduced for the sidebar "+ Create" button but not used anymore. */
      {
        intent: "alternate",
        variant: "solid",
        class: ["bg-textDefault", "hover:bg-textMuted", "active:rg-solidPressed", "text-textContrast"],
      },

      // Outline
      {
        intent: "primary",
        variant: "outline",
        class: [
          "bg-primary-transparent",
          "hover:bg-primary-transparentHovered",
          "active:bg-primary-transparentPressed",
          "box-inset",
          "shadow-primary-borderInteractiveStrong",
          "text-primary-textMuted",
        ],
      },
      {
        intent: "danger",
        variant: "outline",
        class: [
          "bg-danger-transparent",
          "hover:bg-danger-transparentHovered",
          "active:bg-danger-transparentPressed",
          "box-inset",
          "shadow-danger-borderInteractiveStrong",
          "text-danger-textMuted",
        ],
      },
      {
        intent: "neutral",
        variant: "outline",
        class: [
          "bg-neutral-transparent",
          "hover:bg-neutral-transparentHovered",
          "active:bg-neutral-transparentPressed",
          "box-inset",
          "shadow-neutral-borderInteractiveStrong",
          "text-textMuted",
        ],
      },
      {
        intent: "alternate",
        variant: "outline",
        class: [
          "bg-neutral-transparent",
          "hover:bg-neutral-transparentHovered",
          "active:bg-neutral-transparentPressed",
          "box-inset",
          "shadow-textDefault",
          "text-textDefault",
        ],
      },

      // Ghost
      {
        intent: "primary",
        variant: "ghost",
        class: [
          "bg-primary-transparent",
          "hover:bg-primary-transparentHovered",
          "active:bg-primary-transparentPressed",
          "text-primary-textMuted",
        ],
      },
      {
        intent: "danger",
        variant: "ghost",
        class: [
          "bg-danger-transparent",
          "hover:bg-danger-transparentHovered",
          "active:bg-danger-transparentPressed",
          "text-danger-textMuted",
        ],
      },
      {
        intent: "neutral",
        variant: "ghost",
        class: [
          "bg-neutral-transparent",
          "hover:bg-neutral-transparentHovered",
          "active:bg-neutral-transparentPressed",
          "text-textMuted",
        ],
      },
      {
        intent: "alternate",
        variant: "ghost",
        class: [
          "bg-neutral-transparent",
          "hover:bg-neutral-transparentHovered",
          "active:bg-neutral-transparentPressed",
          "text-textDefault",
        ],
      },
    ],
    defaultVariants: {
      intent: "neutral",
      shape: "rectangle",
      variant: "solid",
    },
  },
);
