import type { FieldFields } from "@regrello/graphql-api";
import { RegrelloChip, type RegrelloChipProps } from "@regrello/ui-core";
import React from "react";

import type { PartyTypeUnion } from "../../../utils/parties/PartyTypeUnion";
import { getPartyFullName } from "../../../utils/parties/partyUtils";
import { CustomFieldPluginRegistrar } from "../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";

export type RegrelloFieldChipProps = Omit<RegrelloChipProps, "icon" | "name"> & {
  field: FieldFields;
  fieldPropertyId?: number | null;

  /**
   * Whether the icon should not be shown.
   * @default false
   */
  hideIcon?: boolean;

  party?: PartyTypeUnion;
};

/**
 * An visual object that represents a field (a.k.a. custom field).
 *
 * @deprecated as of 26 Mar 2023; use <RegrelloChip> instead.
 */
export const RegrelloFieldChip = React.memo<RegrelloFieldChipProps>(function RegrelloFieldChipFn({
  hideIcon = false,
  size,
  ...props
}) {
  const plugin = CustomFieldPluginRegistrar.getPluginForField(props.field);
  let name = props.field.name;

  if (props.field.regrelloObject != null) {
    const propertName = props.field.regrelloObject.properties.find((property) => property.id === props.fieldPropertyId);

    if (propertName != null) {
      name = `${propertName.displayName} ( ${props.field.name} )`;
    }
  }

  if (props.party != null) {
    name = getPartyFullName(props.party);
  }

  return (
    <RegrelloChip
      icon={
        hideIcon
          ? undefined
          : {
              type: "iconName",
              iconName: plugin.getIconName(props.field.fieldType, props.field),
            }
      }
      size={size}
      {...props}
    >
      {name}
    </RegrelloChip>
  );
});
