import { ComparatorResult } from "./comparators/ComparatorResult";

export function sortIgnoreCase<T>(items: T[], getString: (item: T) => string) {
  return items.sort((itemA, itemB) => comparatorIgnoreCase(itemA, itemB, getString));
}

/**
 * Sorts strings matching searchString first, with the remaining results sorted alphabetically.
 */
export function sortIgnoreCaseWithExactMatchFirst<T>(items: T[], getString: (item: T) => string, searchString: string) {
  return items.sort((itemA, itemB) => {
    const strA = getString(itemA).toLocaleLowerCase();
    const strB = getString(itemB).toLocaleLowerCase();

    if (strA === searchString.toLocaleLowerCase() || strB === searchString.toLocaleLowerCase()) {
      if (strA === strB) {
        return ComparatorResult.EQUAL;
      }
      return strA === searchString.toLocaleLowerCase() ? ComparatorResult.BEFORE : ComparatorResult.AFTER;
    }

    return comparatorIgnoreCase(itemA, itemB, getString);
  });
}

export function comparatorIgnoreCase<T>(itemA: T, itemB: T, getString: (item: T) => string) {
  const strA = getString(itemA).toLocaleLowerCase();
  const strB = getString(itemB).toLocaleLowerCase();

  if (strA < strB) {
    return ComparatorResult.BEFORE;
  }
  if (strB < strA) {
    return ComparatorResult.AFTER;
  }
  return ComparatorResult.EQUAL;
}

/**
 * Sorts an array of objects containing a `displayOrder` key. If the `displayOrder` is equal between
 * two different elements, the fallback key is used to sort the elements.
 *
 * (anthony): The reason for providing a fallback is that JavaScript can be inconsistent when the
 * comparator is inconclusive, resulting in order-swapping in the UI on re-renders.
 */
export function sortFieldInstancesByDisplayOrderFallbackToFirstValueId<
  T extends { displayOrder?: number | null; values: Array<{ id: number }> },
>(itemA: T, itemB: T) {
  const displayOrderA = itemA.displayOrder ?? 0;
  const displayOrderB = itemB.displayOrder ?? 0;
  const isUsingFallback = displayOrderA === displayOrderB;

  if (isUsingFallback) {
    const fallbackIdA = itemA.values[0]?.id ?? -1;
    const fallbackIdB = itemB.values[0]?.id ?? -1;
    return fallbackIdA < fallbackIdB
      ? ComparatorResult.BEFORE
      : fallbackIdA > fallbackIdB
        ? ComparatorResult.AFTER
        : ComparatorResult.EQUAL;
  }

  return displayOrderA < displayOrderB
    ? ComparatorResult.BEFORE
    : displayOrderA > displayOrderB
      ? ComparatorResult.AFTER
      : ComparatorResult.EQUAL;
}
