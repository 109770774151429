type Order = "asc" | "desc";

type Key<T> = keyof T | ((item: T) => string | number | Date | boolean);

/**
 * Sorts an array of objects by one or more iteratees. You can provide "asc", "desc", or a custom
 * function to sort by.
 *
 * @see `arrayOrderBy.test.ts` for examples.
 */
export function arrayOrderBy<T>(collection: T[], iteratees: Array<Key<T>> = [], orders: Order[] = []): T[] {
  return [...collection].sort((a, b) => {
    for (let i = 0; i < iteratees.length; i += 1) {
      const iteratee = iteratees[i];
      // eslint-disable-next-line lingui/no-unlocalized-strings
      const order = orders[i] || "asc";

      const aValue = typeof iteratee === "function" ? iteratee(a) : a[iteratee as keyof T];
      const bValue = typeof iteratee === "function" ? iteratee(b) : b[iteratee as keyof T];

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
    }
    return 0;
  });
}
