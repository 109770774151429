import { useDateSegment } from "@react-aria/datepicker";
import type { DateFieldState, DateSegment } from "@react-stately/datepicker";
import { clsx, mergeRefs } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { useRef } from "react";

import type { RegrelloSize } from "../../utils/enums/RegrelloSize";

export interface RegrelloDateSegmentProps {
  /**
   * The semantic intent to apply to this date segment. Should match that of the parent date-picker
   * input.
   */
  intent?: "neutral" | "warning" | "danger";

  onBlur?: () => void;

  segment: DateSegment;

  /** The size of the parent input. */
  size: RegrelloSize;

  state: DateFieldState;
}

export const RegrelloDateSegment = React.forwardRef(function RegrelloDateSegmentFn(
  { intent, onBlur, segment, size, state }: RegrelloDateSegmentProps,
  ref,
) {
  const refInternal = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, refInternal);

  return (
    <div
      {...segmentProps}
      ref={mergeRefs(refInternal, ref)}
      className={clsx(
        "px-0.5",
        {
          "py-0.5": size === "x-large" || size === "large" || size === "medium",
        },
        "tabular-nums",
        "text-center",
        "outline-none",
        "rounded",
        "text-textDefault",
        "focus:text-textContrast",
        "focus:bg-primary-solid",
        {
          "focus:bg-danger-solid": intent === "danger",
          "focus:bg-warning-solid": intent === "warning",
        },
        "aria-[invalid]:text-danger-textMuted",
        {
          "text-textPlaceholder": segment.isPlaceholder,
        },
      )}
      data-testid={segment.isEditable ? DataTestIds.FORM_FIELD_DATE_DATE_SEGMENT : undefined}
      onBlur={onBlur}
      style={{
        ...segmentProps.style,
        minWidth: segment.maxValue != null ? String(segment.maxValue).length + 1 + "ch" : undefined,
      }}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className="block w-full text-center focus:text-textContrast"
        style={{
          visibility: segment.isPlaceholder ? undefined : "hidden",
          height: segment.isPlaceholder ? undefined : 0,
          pointerEvents: "none",
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
});
