/* eslint-disable lingui/no-unlocalized-strings */

const INPUT_ELEMENT_TYPES = [
  "date",
  "datetime",
  "datetime-local",
  "email",
  "month",
  "number",
  "password",
  "search",
  "tel",
  "text",
  "time",
  "url",
  "week",
];

/**
 * Checks whether the provided DOM element is a text input.
 */
export function isTextInput(element: Element | null): boolean {
  if (element == null) {
    return false;
  }

  switch (element.tagName) {
    case "TEXTAREA":
      return true;
    case "INPUT":
      return element instanceof HTMLInputElement && INPUT_ELEMENT_TYPES.includes(element.type);

    default:
      return false;
  }
}
