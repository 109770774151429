import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { getI18NConfig, type LanguageCode } from "@regrello/i18n-llm-translator";
import {
  formatDistance as formatDistanceFns,
  formatDistanceToNow as formatDistanceToNowFns,
  formatDistanceToNowStrict as formatDistanceToNowStrictFns,
  isThisYear,
  isValid,
  type Locale,
  parse,
} from "date-fns";
import { de, enUS, es, fr, zhCN } from "date-fns/locale";

import { endOfDay, isDateWithinNextHours, isEndOfDay, parseDate } from "./dateUtils";

// (clewis): Supply-chain managers are *very* passionate about the day-month-year order of this
// formatted date. Please ask others before changing this.
// Ref: https://docs.oracle.com/javase/6/docs/api/java/text/SimpleDateFormat.html
// (surya): [IMPORTANT] If you make changes here, be sure to also change
// dateFormatUtils.ts in the email-renderer repo.
export const DATE_STRING = "dd MMM yyyy"; // "02 Jan 2006"

// eslint-disable-next-line lingui/no-unlocalized-strings
const DATE_CONFIG: Intl.DateTimeFormatOptions = { dateStyle: "medium" };
// eslint-disable-next-line lingui/no-unlocalized-strings
const TIME_CONFIG: Intl.DateTimeFormatOptions = { timeStyle: "short" };

const LOCALE_MAP: { [k in LanguageCode]: Locale } = {
  de: de,
  en: enUS,
  zh: zhCN,
  fr: fr,
  es: es,
};

/**
 * Formats a given date string or Date object into a localized date string.
 * Includes year (y), abbreviated-month (MMM), day (d).
 *
 * @param date - The date to format, either as a string or a Date object.
 * @returns The formatted date string.
 */
export function formatDateString(date: string | Date): string {
  return i18n.date(parseDate(date), DATE_CONFIG);
}

/**
 * Formats a date or date string into a localized date-time string.
 * Includes year (y), abbreviated-month (MMM), day (d).
 * Includes hour and minute
 *
 * @param date - The date or date string to format.
 * @returns The formatted date-time string.
 */
export function formatDateTimeString(date: string | Date): string {
  // Formatted separately to avoid separators between the date and time string
  return `${formatDateString(date)} ${formatTimeString(date)}`;
}

/**
 * Formats a given date string or Date object into a localized time string.
 * Includes hour and minute
 *
 * @param date - The date to format, either as a string or a Date object.
 * @returns The formatted time string.
 */
export function formatTimeString(date: string | Date): string {
  const config: Intl.DateTimeFormatOptions = { ...TIME_CONFIG };

  // eslint-disable-next-line lingui/no-unlocalized-strings
  const hour12 = getI18NConfig("hour12");
  if (hour12 === true) {
    config.hour12 = true;
  }

  return i18n.date(parseDate(date), config);
}

/**
 * Parses a date string using a fallback mechanism.
 *
 * This function attempts to parse the given date string using the format "HH:mm:ssxxx".
 * If the parsed date is invalid, it falls back to parsing the date string using the format "HH:mm:ssx".
 *
 * @param date - The date string to be parsed.
 * @returns The parsed Date object. If both parsing attempts fail, the returned Date object will be invalid.
 */
export function parseWithFallback(date: string): Date {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const dateParsed = parse(date, "HH:mm:ssxxx", new Date());
  if (isValid(dateParsed)) {
    return dateParsed;
  }
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return parse(date, "HH:mm:ssx", new Date());
}

export function formatIsoStringInLocalTimezone(date: string | Date): string {
  const parsedDate = parseDate(date);
  return endOfDay(parsedDate).toISOString();
}

/**
 * Converts a date into a compact readable context-aware datetime based on these rules:
 * - Do not show year if it is this year.
 * - Do not show time if the time is end of day for the user.
 * - Do not show leading zeros in date or time.
 * E.g. "3:45 PM on 2 Jan", "2 Jan", "2 Jan 2045"
 */
export function formatCompactDateString(date: string | Date): string {
  const parsedDate = parseDate(date);

  const isDueThisYear = isThisYear(parsedDate);
  const isTimeWorthShowing = !isEndOfDay(parsedDate);

  const dateFormatConfig: Intl.DateTimeFormatOptions = {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    day: "numeric",
    // eslint-disable-next-line lingui/no-unlocalized-strings
    month: "short",
  };
  if (!isDueThisYear) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    dateFormatConfig.year = "numeric";
  }
  const datePart = i18n.date(parsedDate, dateFormatConfig);

  if (isTimeWorthShowing) {
    const timePart = formatTimeString(parsedDate);

    return t`${timePart} on ${datePart}`;
  }

  return datePart;
}

/**
 * Show full date and optionally show time if explicitly set with "isTimeShown",
 * or if the time is all of:
 * - not EOD time
 * - at most 24 hours away
 * E.g. either "3:45 PM on 2 Jan 2006" or "2 Jan 2006"
 */
export function formatDateAndConditionallyShowTime(
  date: string | Date | null | undefined,
  isTimeShown?: boolean,
): string {
  if (date == null) {
    return "";
  }

  const parsedDate = parseDate(date);

  const isDateWithinNext24Hours = isDateWithinNextHours(parsedDate, 24);
  const isTimeWorthShowing = isDateWithinNext24Hours && !isEndOfDay(parsedDate);

  const datePart = formatDateString(parsedDate);

  if (isTimeShown || isTimeWorthShowing) {
    const timePart = formatTimeString(parsedDate);

    return t`${timePart} on ${datePart}`;
  }

  return datePart;
}

export const formatDistanceToNow: typeof formatDistanceToNowFns = (date, options) => {
  const locale: LanguageCode = i18n.locale as LanguageCode;

  return formatDistanceToNowFns(date, {
    locale: LOCALE_MAP[locale],
    ...options,
  });
};

export const formatDistanceToNowStrict: typeof formatDistanceToNowStrictFns = (date, options) => {
  const locale: LanguageCode = i18n.locale as LanguageCode;

  return formatDistanceToNowStrictFns(date, {
    locale: LOCALE_MAP[locale],
    ...options,
  });
};

export const formatDistance: typeof formatDistanceFns = (date, baseDate, options) => {
  const locale: LanguageCode = i18n.locale as LanguageCode;

  return formatDistanceFns(date, baseDate, {
    locale: LOCALE_MAP[locale],
    ...options,
  });
};
