import type { PureQueryOptions } from "@apollo/client";
import {
  ActionItemDetailsQueryDocument,
  type ActionItemDetailsQueryVariables,
  ActionItemDetailsWithFormInstancesQueryDocument,
  type ActionItemDetailsWithFormInstancesQueryVariables,
  ApprovalAttemptsForActionItemQueryDocument,
  type ApprovalAttemptsForActionItemQueryVariables,
  FieldsQueryV2Document,
  type FieldsQueryV2Variables,
  FormVersionPreviewQueryDocument,
  type FormVersionPreviewQueryVariables,
  LatestFormVersionQueryDocument,
  type LatestFormVersionQueryVariables,
  ValidateWorkflowQueryDocument,
  type ValidateWorkflowQueryVariables,
  VariantBlueprintsQueryDocument,
  type VariantBlueprintsQueryVariables,
  WorkflowQueryDocument,
  type WorkflowQueryVariables,
  WorkflowTemplatesListQueryDocument,
  type WorkflowTemplatesListQueryVariables,
  WorkflowTemplatesQueryDocument,
  type WorkflowTemplatesQueryVariables,
} from "@regrello/graphql-api";

export function getWorkflowQueryWithVariable(variables: WorkflowQueryVariables): PureQueryOptions {
  return {
    query: WorkflowQueryDocument,
    variables,
  };
}

export function getWorkflowTemplateQueryWithVariables(variables: WorkflowTemplatesQueryVariables): PureQueryOptions {
  return {
    query: WorkflowTemplatesQueryDocument,
    variables,
  };
}

export function getWorkflowTemplatesListQueryWithVariables(
  variables: WorkflowTemplatesListQueryVariables,
): PureQueryOptions {
  return {
    query: WorkflowTemplatesListQueryDocument,
    variables,
  };
}

export function getVariantBlueprintsQueryWithVariables(variables: VariantBlueprintsQueryVariables): PureQueryOptions {
  return {
    query: VariantBlueprintsQueryDocument,
    variables,
  };
}

export function getFieldsV2QueryWithVariables(variables: FieldsQueryV2Variables): PureQueryOptions {
  return {
    query: FieldsQueryV2Document,
    variables,
  };
}

export function getValidateWorkflowQueryWithVariables(variables: ValidateWorkflowQueryVariables): PureQueryOptions {
  return {
    query: ValidateWorkflowQueryDocument,
    variables,
  };
}

export function getActionItemDetailQueryWithVariables(variables: ActionItemDetailsQueryVariables): PureQueryOptions {
  return {
    query: ActionItemDetailsQueryDocument,
    variables,
  };
}

export function getApprovalAttemptsForActionItemWithVariables(
  variables: ApprovalAttemptsForActionItemQueryVariables,
): PureQueryOptions {
  return {
    query: ApprovalAttemptsForActionItemQueryDocument,
    variables,
  };
}

export function getLatestFormVersionQueryWithVariables(variables: LatestFormVersionQueryVariables): PureQueryOptions {
  return {
    query: LatestFormVersionQueryDocument,
    variables,
  };
}

export function getActionItemWithFormInstanceQueryWithVariables(
  variables: ActionItemDetailsWithFormInstancesQueryVariables,
): PureQueryOptions {
  return {
    query: ActionItemDetailsWithFormInstancesQueryDocument,
    variables,
  };
}

export function getFormVersionPreviewQueryWithVariables(variables: FormVersionPreviewQueryVariables): PureQueryOptions {
  return {
    query: FormVersionPreviewQueryDocument,
    variables,
  };
}
