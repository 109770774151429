import { RoutePaths, RoutePathsWithId, RouteQueryStringKeys } from "./consts";

function getWorkspacePrefix(): string {
  const match = window.location.pathname.match(/\/workspace\/[0-9a-zA-Z-_]+/);
  return match ? match[0] : "";
}

export function createWorkflowRoute(workflowId: number): string {
  return `${getWorkspacePrefix()}${RoutePathsWithId.WORKFLOW}/${workflowId}`;
}

export function getRouteToActionItem(taskUuid: string) {
  return `?${RouteQueryStringKeys.TASK_ID}=${taskUuid}`;
}

export function getRouteToWorkflowActionItem(workflowId: number, taskUuid: string) {
  return `${createWorkflowRoute(workflowId)}${getRouteToActionItem(taskUuid)}`;
}

export function getRouteToWorkflowActionItemComment(workflowId: number, taskUuid: string, commentId: number) {
  return `${createWorkflowRoute(workflowId)}${getRouteToActionItemComment(taskUuid, commentId)}`;
}

export function getRouteToActionItemComment(taskUuid: string, commentId: number) {
  return `?${RouteQueryStringKeys.TASK_ID}=${taskUuid}&${RouteQueryStringKeys.COMMENT_ID}=${commentId}`;
}

export function getRouteToFields() {
  return getWorkspaceRouteTo(RoutePaths.CUSTOM_FIELDS);
}

export function getRouteToFormsPublished() {
  return getWorkspaceRouteTo(RoutePaths.SPECTRUM_FORMS);
}

export function getRouteToWorkflow(workflowId: number) {
  return getRouteWithId(RoutePathsWithId.WORKFLOW, workflowId);
}

export function getRouteToWorkflowTemplate(workflowTemplateId: number) {
  return getRouteWithId(RoutePathsWithId.BLUEPRINTS, workflowTemplateId);
}

export function getRouteToFormBuilder(formUUID: string, isPublic?: boolean) {
  const baseRoute = `${getWorkspacePrefix()}${RoutePaths.SPECTRUM_FORM_BUILDER}?${
    RouteQueryStringKeys.UUID
  }=${formUUID}`;
  return isPublic ? baseRoute + "&is-public=1" : baseRoute;
}

export function getWorkspaceRouteTo(routePath: string) {
  return `${getWorkspacePrefix()}${routePath}`;
}

function getRouteWithId(route: RoutePathsWithId, id: number) {
  return `${getWorkspacePrefix()}${route}/${id}`;
}

export const maybeRemoveFirstSlash = (path: string) => {
  return path.startsWith("/") ? path.slice(1) : path;
};
