// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from './FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from './AcyclicFieldInstanceFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from './FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from './FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { gql } from '@apollo/client';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
export type FieldInstanceValueFieldsFieldInstanceMultiValueDocument = (
  { id: number }
  & FieldInstanceMultiValueDocumentFields
);

export type FieldInstanceValueFieldsFieldInstanceMultiValueFloat = (
  { id: number }
  & FieldInstanceMultiValueFloatFields
);

export type FieldInstanceValueFieldsFieldInstanceMultiValueInt = (
  { id: number }
  & FieldInstanceMultiValueIntFields
);

export type FieldInstanceValueFieldsFieldInstanceMultiValueParty = (
  { id: number }
  & FieldInstanceMultiValuePartyFields
);

export type FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance = (
  { id: number }
  & FieldInstanceMultiValueRegrelloObjectInstanceFields
);

export type FieldInstanceValueFieldsFieldInstanceMultiValueString = (
  { id: number }
  & FieldInstanceMultiValueStringFields
);

export type FieldInstanceValueFieldsFieldInstanceMultiValueTime = (
  { id: number }
  & FieldInstanceMultiValueTimeFields
);

export type FieldInstanceValueFieldsFieldInstanceValueBoolean = (
  { id: number }
  & FieldInstanceValueBooleanFields
);

export type FieldInstanceValueFieldsFieldInstanceValueFloat = (
  { id: number }
  & FieldInstanceValueFloatFields
);

export type FieldInstanceValueFieldsFieldInstanceValueInt = (
  { id: number }
  & FieldInstanceValueIntFields
);

export type FieldInstanceValueFieldsFieldInstanceValueParty = (
  { id: number }
  & FieldInstanceValuePartyFields
);

export type FieldInstanceValueFieldsFieldInstanceValueString = (
  { id: number }
  & FieldInstanceValueStringFields
);

export type FieldInstanceValueFieldsFieldInstanceValueTime = (
  { id: number }
  & FieldInstanceValueTimeFields
);

export type FieldInstanceValueFields = FieldInstanceValueFieldsFieldInstanceMultiValueDocument | FieldInstanceValueFieldsFieldInstanceMultiValueFloat | FieldInstanceValueFieldsFieldInstanceMultiValueInt | FieldInstanceValueFieldsFieldInstanceMultiValueParty | FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance | FieldInstanceValueFieldsFieldInstanceMultiValueString | FieldInstanceValueFieldsFieldInstanceMultiValueTime | FieldInstanceValueFieldsFieldInstanceValueBoolean | FieldInstanceValueFieldsFieldInstanceValueFloat | FieldInstanceValueFieldsFieldInstanceValueInt | FieldInstanceValueFieldsFieldInstanceValueParty | FieldInstanceValueFieldsFieldInstanceValueString | FieldInstanceValueFieldsFieldInstanceValueTime;

export const FieldInstanceValueFields = gql`
    fragment FieldInstanceValueFields on FieldInstanceValue {
  id
  ... on FieldInstanceValueString {
    ...FieldInstanceValueStringFields
  }
  ... on FieldInstanceMultiValueString {
    ...FieldInstanceMultiValueStringFields
  }
  ... on FieldInstanceValueInt {
    ...FieldInstanceValueIntFields
  }
  ... on FieldInstanceValueFloat {
    ...FieldInstanceValueFloatFields
  }
  ... on FieldInstanceValueBoolean {
    ...FieldInstanceValueBooleanFields
  }
  ... on FieldInstanceValueTime {
    ...FieldInstanceValueTimeFields
  }
  ... on FieldInstanceValueParty {
    ...FieldInstanceValuePartyFields
  }
  ... on FieldInstanceMultiValueParty {
    ...FieldInstanceMultiValuePartyFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...FieldInstanceMultiValueDocumentFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...FieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  ... on FieldInstanceMultiValueInt {
    ...FieldInstanceMultiValueIntFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...FieldInstanceMultiValueFloatFields
  }
  ... on FieldInstanceMultiValueTime {
    ...FieldInstanceMultiValueTimeFields
  }
}
    `;