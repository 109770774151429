// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormFieldConstraintFields } from './FormFieldConstraintFields.generated';
import { FormFieldConstraintRuleFields } from './FormFieldConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from './FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from './FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { gql } from '@apollo/client';
export type FormFieldFields = { id: number, uuid: string, createdAt: string, displayOrder: number, displayColumn: number, enableFieldPassing: boolean, isMultiValued: boolean, formSection: { id: number, uuid: string }, formFieldConstraints: Array<FormFieldConstraintFields>, spectrumField: { id: number }, spectrumFieldVersion: SpectrumFieldVersionFields };

export const FormFieldFields = gql`
    fragment FormFieldFields on FormField {
  id
  uuid
  createdAt
  formSection {
    id
    uuid
  }
  formFieldConstraints {
    ...FormFieldConstraintFields
  }
  spectrumField {
    id
  }
  spectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
  displayOrder
  displayColumn
  enableFieldPassing
  isMultiValued
}
    `;