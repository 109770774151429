import type { ApolloError, LazyQueryResult, OperationVariables, QueryResult } from "@apollo/client";
import { useMemo } from "react";

import { AsyncLoaded } from "../typescript/AsyncLoaded";

/** Wraps the provided Apollo GraphQL query result in a memoized `AsyncLoaded` value. */
export function useMemoizedAsyncLoadedValue<
  TData,
  TTransformedData = TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  queryResult: QueryResult<TData, TVariables> | LazyQueryResult<TData, TVariables>,
  transformData?: (data: TData) => TTransformedData,
): AsyncLoaded<TTransformedData, ApolloError> {
  const asyncLoadedQueryResult = useMemo(
    () => AsyncLoaded.fromGraphQlQueryResult(queryResult, transformData),
    [queryResult, transformData],
  );
  return asyncLoadedQueryResult;
}
