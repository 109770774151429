// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type WorkflowTemplatePermissionsFields = { canAddAutomatedTask: boolean, canAddLinkedWorkflowTask: boolean, canRead: boolean, canEdit: boolean, CanEditWorkflowOwner: boolean, canExport: boolean, CanOverrideDefaultWorkflowOwner: boolean, canCopy: boolean, canPublish: boolean, canDelete: boolean, canViewTabAbout: boolean, canViewTabAccess: boolean, canViewTabData: boolean, canViewTabDocuments: boolean, canCreateWorkflowsFrom: boolean, canDownload: boolean, canLockWorkflow: boolean, canUpdateToNewVersion: boolean, canCreateVariantsOf: boolean };

export const WorkflowTemplatePermissionsFields = gql`
    fragment WorkflowTemplatePermissionsFields on UserPermissionsToWorkflowTemplate {
  canAddAutomatedTask
  canAddLinkedWorkflowTask
  canRead
  canEdit
  CanEditWorkflowOwner
  canExport
  CanOverrideDefaultWorkflowOwner
  canCopy
  canPublish
  canDelete
  canViewTabAbout
  canViewTabAccess
  canViewTabData
  canViewTabDocuments
  canCreateWorkflowsFrom
  canDownload
  canLockWorkflow
  canUpdateToNewVersion
  canCreateVariantsOf
}
    `;