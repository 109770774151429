import type { DocumentFields } from "@regrello/graphql-api";

import type { DeleteableDocumentFields } from "../ui/views/modals/actionItemDetailDialog/_internal/documentsSection/DocumentRow";

export function getDeletableDocumentFields(
  document: DocumentFields,
  isDeletable: boolean,
  reasonWhyNotDeletable?: string,
): DeleteableDocumentFields {
  return {
    document,
    isDeletable,
    reasonWhyNotDeletable,
  };
}

/**
 * Returns the file extension of a file name, lower-cased. If the file name does not have a file
 * extension, returns `undefined`.
 */
export function getFileExtension(
  fileName: string,
  options: {
    /**
     * Whether to include the period in the file extension string output.
     *
     * @default false
     */
    includePeriod: boolean;
  } = { includePeriod: false },
): string | undefined {
  const fileNameSplitByPeriod = fileName?.split(".") ?? [];
  if (fileNameSplitByPeriod.length < 2) {
    return undefined;
  }

  const fileExtension = fileNameSplitByPeriod[fileNameSplitByPeriod.length - 1].toLocaleLowerCase();

  return options.includePeriod ? `.${fileExtension}` : fileExtension;
}

export function getDocumentFields(name: string, uuid: string): DocumentFields {
  return {
    id: 0,
    name: name,
    currentVersion: {
      id: 0,
      uid: uuid,
      createdAt: "",
      createdBy: {
        __typename: "Party",
        id: 0,
        user: undefined,
        team: undefined,
      },
    },
    documentType: {
      id: 0,
      name: "",
      icon: getIconUrl("file.png"),
    },
    tags: [],
    permissions: {
      canDelete: false,
    },
  };
}

export function getIconUrl(iconFile: string) {
  // (swerner) This seems a little outdated approach. Can't we just have these images in the actual application and import them instead?
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return `https://www.regrello.com/images/files/${iconFile}`;
}
