import { t, Trans } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING, useConfirmationDialog, useOnOpen } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { type TagFields, useTagsQueryLazyQuery } from "@regrello/graphql-api";
import { RegrelloListPhrase, type RegrelloMultiSelectProps, RegrelloTooltip } from "@regrello/ui-core";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldMultiSelect, type RegrelloFormFieldMultiSelectProps } from "./RegrelloFormFieldMultiSelect";
import { tagSortComparator } from "../../../utils/comparators/tagSortComparator";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { AsyncLoaded } from "../../../utils/typescript/AsyncLoaded";
import {
  ConfigureTagDialog,
  ConfigureTagDialogContext,
  type ConfigureTagDialogProps,
} from "../../views/modals/formDialogs/tags/ConfigureTagDialog";

function createSpecialOptionWithId(id: number): TagFields {
  return {
    id,
    createdAt: EMPTY_STRING,
    name: EMPTY_STRING,
    tagType: {
      id: -1,
      backgroundColor: EMPTY_STRING,
      borderColor: EMPTY_STRING,
      name: EMPTY_STRING,
    },
  };
}

const ERROR_OPTION = createSpecialOptionWithId(-5);

/**
 * This component renders a multiselect input field where the user can select from all tags in the
 * system. It is a wrapper around RegrelloFormFieldMultiSelect. It loads the tag data from the
 * graphql query Tags.
 */
export interface RegrelloFormFieldTagSelectProps
  extends RegrelloFormFieldBaseProps<TagFields[]>,
    Pick<RegrelloFormFieldMultiSelectProps<TagFields>, "autoFocus" | "inputRef" | "onChange" | "size" | "value"> {
  /**
   * Whether to allow creating new tags and tag types.
   * @default false
   */
  allowCreateTags?: boolean;

  /**
   * Neither "+ Add Tag" nor "Add tag not allowed" will be shown if this flag is true."
   * @default false
   */
  disableExtraOption?: boolean;
}

export const RegrelloFormFieldTagSelect = React.memo(function RegrelloFormFieldTagSelectFn({
  allowCreateTags,
  className,
  disableExtraOption = false,
  inputRef,
  onChange, // (clewis): Pull this out because we need to override it.
  selfContainedForm, // (clewis): Pull this out because we need to override it.
  ...multiselectProps
}: RegrelloFormFieldTagSelectProps) {
  const [cachedIsPopoverOpen, setCachedIsPopoverOpen] = useState<boolean>(false);
  const [loadedTags, setLoadedTags] = useState<TagFields[]>(EMPTY_ARRAY);

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
    payload: defaultValueForCreateDialogRef,
  } = useConfirmationDialog<string>();

  const { handleError } = useErrorHandler();

  const [getTagsAsync, tagsQueryResult] = useTagsQueryLazyQuery({
    onError: (error) => handleError(error),
    fetchPolicy: "no-cache",
  });

  const asyncTagsQueryResult = useMemo<AsyncLoaded<TagFields[]>>(() => {
    return AsyncLoaded.fromGraphQlQueryResult(tagsQueryResult, (data) => data.tags);
  }, [tagsQueryResult]);

  useOnOpen(cachedIsPopoverOpen, getTagsAsync);

  // Update and sort the locally stored tags when the query finishes loading.
  useEffect(() => {
    if (!AsyncLoaded.isReady(asyncTagsQueryResult)) {
      return;
    }

    if (AsyncLoaded.isError(asyncTagsQueryResult)) {
      setLoadedTags([ERROR_OPTION]);
      return;
    }

    setLoadedTags(sortItems(asyncTagsQueryResult.value));
  }, [asyncTagsQueryResult]);

  const handleTagAdded = useCallback(
    (newTag: TagFields) => {
      setLoadedTags(sortItems([...loadedTags, newTag]));
      onChange([...(multiselectProps?.value ?? EMPTY_ARRAY), newTag]);
      closeCreateDialog();
    },
    [closeCreateDialog, loadedTags, multiselectProps?.value, onChange],
  );

  const memoizedSelfContainedForm: RegrelloFormFieldTagSelectProps["selfContainedForm"] = useMemo(() => {
    if (selfContainedForm == null) {
      return undefined;
    }
    return selfContainedForm;
  }, [selfContainedForm]);

  // (clewis): Need to extract the value, else ESLint won't set the useMemo dependencies correctly.
  const defaultValueForCreateDialog = defaultValueForCreateDialogRef.current;

  const configureTagDialogContext = useMemo<ConfigureTagDialogProps["context"]>(() => {
    return {
      type: ConfigureTagDialogContext.ADD,
      // (clewis): As a UX nicety, we want to pre-populate the typed value in the add dialog. This
      // is tricky because the inputValue is cleared as soon as we select an option, so we have to
      // track the dialog's defaultValue separately.
      defaultName: defaultValueForCreateDialog,
      onTagAdded: handleTagAdded,
    };
  }, [defaultValueForCreateDialog, handleTagAdded]);

  const memoizedCreateItemOptions: RegrelloMultiSelectProps<TagFields>["createItemOptions"] = useMemo(() => {
    if (disableExtraOption) {
      return undefined;
    }
    return {
      getCreateItemDisabled: () => {
        return !allowCreateTags;
      },
      onCreateItem: (query: string) => {
        openCreateDialog(query);
      },
      renderCreateItem: (query: string) => {
        if (allowCreateTags) {
          return {
            dataTestId: DataTestIds.FORM_FIELD_SELECT_OPTION,
            text: t`Add tag`,
          };
        }
        return {
          dataTestId: DataTestIds.FORM_FIELD_SELECT_ADD_NOT_ALLOWED_OPTION,
          intent: "neutral",
          text:
            query.length > 0 ? (
              <Trans>
                Please contact an admin if you would like to add <strong>{query}</strong>
              </Trans>
            ) : (
              t`Please contact an admin if you would like to add tags`
            ),
        };
      },
    };
  }, [allowCreateTags, disableExtraOption, openCreateDialog]);

  return (
    <>
      <RegrelloFormFieldMultiSelect
        className={className}
        createItemOptions={memoizedCreateItemOptions}
        getItemDisabled={getItemDisabled}
        getItemGroup={getTagTypeName}
        getItemLabelForSelfContainedForm={getItemLabel}
        getSelectedItemProps={getSelectedItemProps}
        inputRef={inputRef}
        isItemsEqual={isItemsEqual}
        itemPredicate={itemPredicate}
        items={loadedTags}
        loading={!AsyncLoaded.isReady(asyncTagsQueryResult)}
        onChange={onChange}
        onOpenChange={setCachedIsPopoverOpen}
        renderDisplayValueForSelfContainedForm={renderDisplayValue}
        renderItem={renderItem}
        selfContainedForm={memoizedSelfContainedForm}
        {...multiselectProps}
      />

      <ConfigureTagDialog context={configureTagDialogContext} isOpen={isCreateDialogOpen} onClose={closeCreateDialog} />
    </>
  );
});

function isItemsEqual(tagA: TagFields, tagB: TagFields): boolean {
  return tagA.id === tagB.id;
}

function itemPredicate(query: string, tag: TagFields): boolean {
  return tag.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

function getItemDisabled(tag: TagFields): boolean {
  return tag.id === ERROR_OPTION.id;
}

function getItemLabel(tag: TagFields): string {
  return tag.name;
}

function getSelectedItemProps(tag: TagFields) {
  return {
    children: getItemLabel(tag),
    dataTestId: DataTestIds.FORM_FIELD_MULTISELECT_DISPLAY_TAG,
  };
}

function getTagTypeName(tag: TagFields) {
  return tag.tagType.name;
}

function renderDisplayValue(tags: TagFields[]) {
  return (
    <RegrelloListPhrase
      items={tags}
      renderItem={(tag) => {
        return (
          <RegrelloTooltip key={tag.id} content={tag.tagType.name} side="top">
            <span>{tag.name}</span>
          </RegrelloTooltip>
        );
      }}
    />
  );
}

const renderItem: NonNullable<RegrelloMultiSelectProps<TagFields>["renderItem"]> = (item: TagFields) => {
  if (item.id === ERROR_OPTION.id) {
    return {
      key: item.id,
      disabled: true,
      icon: "alert-outline",
      text: t`Failed to load options`,
    };
  }

  return {
    key: item.id,
    dataTestId: DataTestIds.FORM_FIELD_SELECT_OPTION,
    icon: "tag",
    text: item.name,
  };
};

function sortItems(tags: readonly TagFields[]): TagFields[] {
  // (clewis): Need to spread before sorting, because the loaded array is readonly.
  return [...tags].sort(tagSortComparator);
}
