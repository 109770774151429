/* eslint-disable lingui/no-unlocalized-strings */

// (clewis): This is the email-validation regex per the W3C standard for input[type="email"].
// Source: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
//
// I did modify it by deleting one unnecessary escape character before character 22, '/', since

import { EMPTY_STRING } from "../constants/emptyString";

// TypeScript complained about it.
const W3C_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// A more restrictive email regex than the one defined above, only allows alphanumeric characters
// and periods in the email alias.
const RESTRICTIVE_EMAIL_REGEX =
  /^[a-zA-Z0-9.]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// Complement of the valid alias character class as defined above in the regex we use for
// determining if a blueprint contact email is valid.
const INVALID_BLUEPRINT_CONTACT_EMAIL_ALIAS_CHARACTERS = /[^a-zA-Z0-9.]/g;

/** Returns `true` if the provided string is a valid email address. Uses the W3C's  */
export function isValidEmail(value: string): boolean {
  return W3C_EMAIL_REGEX.test(value);
}

/**
 * Returns `true` if the provided string is a valid, more restrictive email address (i.e., the email
 * alias only contains alphanumeric characters and periods).
 */
export function isValidRestrictiveEmail(value: string): boolean {
  return RESTRICTIVE_EMAIL_REGEX.test(value);
}

/** Returns `true` if the provided string is a valid multi-email address string.  */
export function isValidMultiEmail(value: string): boolean {
  const emails = value.split(",");
  return emails.map((token) => token.trim()).every(isValidEmail);
}

/** Returns the portion of the email before the first '@' sign. */
export function getEmailAlias(value: string): string {
  const indexOfFirstAtSign = value.indexOf("@");
  return indexOfFirstAtSign < 0 ? EMPTY_STRING : value.substring(0, indexOfFirstAtSign);
}

/** Returns the portion of the email after the first '@' sign. */
export function getEmailDomain(value: string) {
  const indexOfFirstAtSign = value.indexOf("@");
  return indexOfFirstAtSign < 0 || indexOfFirstAtSign === value.length - 1
    ? EMPTY_STRING
    : value.substring(indexOfFirstAtSign + 1);
}

/**
 * Returns the same email address with the specified characters replaced (e.g., replacing "_" with
 * "." or vice versa).
 */
export function emailReplaceCharsInAlias(value: string, charToRemove: string, charToInsert: string): string {
  const alias = getEmailAlias(value);
  const domain = getEmailDomain(value);

  const newAlias = alias.replaceAll(charToRemove, charToInsert);
  return `${newAlias}@${domain}`;
}

/**
 * Returns the provided email alias stripped of any restrictive invalid characters (i.e., any
 * character that's not alphanumeric or a period).
 */
export function stripRestrictiveInvalidCharactersFromEmailAlias(alias: string): string {
  return alias.replaceAll(INVALID_BLUEPRINT_CONTACT_EMAIL_ALIAS_CHARACTERS, EMPTY_STRING);
}

/**
 * Check if two domains are "the same", respecting nationally-different TLDs such as
 * `regrello.com.cn` and `regrello.com.hk` and `regrello.com`.
 *
 * (krashanoff): This function makes assumptions that domain names respect TLDs,
 * and are not hostnames of machines on one's LAN.
 */
export function isSameGeneralizedDomain(domainA: string, domainB: string): boolean {
  if (domainA === domainB) {
    return true;
  }

  const partsA = domainA.split(".").reverse();
  const partsB = domainB.split(".").reverse();
  if (partsA.length === partsB.length && partsA.length <= 2) {
    return false;
  }

  const generalizedA = partsA
    .splice(isCountryTLD(partsA[0]) ? 1 : 0, 2)
    .reverse()
    .join(".");
  const generalizedB = partsB
    .splice(isCountryTLD(partsB[0]) ? 1 : 0, 2)
    .reverse()
    .join(".");
  return generalizedA === generalizedB;
}

export function isSameGeneralizedAddress(emailA: string, emailB: string): boolean {
  return isSameGeneralizedDomain(getEmailDomain(emailA), getEmailDomain(emailB));
}

export function normalizeEmail(email: string) {
  return email.trim().toLocaleLowerCase();
}

const COUNTRY_TLDS = [
  "abudhabi",
  "ac",
  "ad",
  "ae",
  "ae",
  "ae",
  "af",
  "ag",
  "ai",
  "al",
  "al",
  "am",
  "an",
  "an",
  "an",
  "an",
  "ao",
  "aq",
  "ar",
  "as",
  "at",
  "au",
  "aw",
  "ax",
  "az",
  "ba",
  "bb",
  "bd",
  "be",
  "bf",
  "bg",
  "bh",
  "bi",
  "bj",
  "bl",
  "bm",
  "bn",
  "bo",
  "bq",
  "bq",
  "bq",
  "br",
  "bs",
  "bt",
  "bv",
  "bw",
  "by",
  "bz",
  "ca",
  "cat",
  "cc",
  "cd",
  "cf",
  "cg",
  "ch",
  "ci",
  "ck",
  "cl",
  "cm",
  "cn",
  "co",
  "cr",
  "cu",
  "cv",
  "cw",
  "cx",
  "cy",
  "cz",
  "de",
  "dj",
  "dk",
  "dm",
  "do",
  "dz",
  "ec",
  "ee",
  "eg",
  "eh",
  "er",
  "es",
  "et",
  "eu",
  "eus",
  "fi",
  "fj",
  "fk",
  "fm",
  "fo",
  "fr",
  "fr",
  "fr",
  "ga",
  "gal",
  "gb",
  "gd",
  "ge",
  "gf",
  "gg",
  "gh",
  "gi",
  "gl",
  "gm",
  "gn",
  "gp",
  "gp",
  "gp",
  "gq",
  "gr",
  "gs",
  "gt",
  "gu",
  "gw",
  "gy",
  "hk",
  "hm",
  "hn",
  "hr",
  "ht",
  "hu",
  "id",
  "ie",
  "il",
  "im",
  "in",
  "io",
  "iq",
  "ir",
  "is",
  "it",
  "je",
  "jm",
  "jo",
  "jp",
  "ke",
  "kg",
  "kh",
  "ki",
  "km",
  "kn",
  "kp",
  "kp",
  "kr",
  "kr",
  "kw",
  "ky",
  "kz",
  "la",
  "lb",
  "lc",
  "li",
  "lk",
  "lr",
  "ls",
  "lt",
  "lu",
  "lv",
  "ly",
  "ma",
  "ma",
  "mc",
  "md",
  "me",
  "mf",
  "mg",
  "mh",
  "mk",
  "mk",
  "ml",
  "mm",
  "mm",
  "mn",
  "mo",
  "mp",
  "mq",
  "mr",
  "ms",
  "mt",
  "mu",
  "mv",
  "mw",
  "mx",
  "my",
  "mz",
  "na",
  "nc",
  "nc",
  "nc",
  "ne",
  "nf",
  "ng",
  "ni",
  "nl",
  "nl",
  "nl",
  "nl",
  "no",
  "np",
  "nr",
  "nu",
  "nz",
  "om",
  "pa",
  "pe",
  "pf",
  "pf",
  "pg",
  "ph",
  "pk",
  "pl",
  "pm",
  "pn",
  "pr",
  "ps",
  "ps",
  "ps",
  "pt",
  "pw",
  "py",
  "qa",
  "re",
  "ro",
  "rs",
  "ru",
  "rw",
  "sa",
  "sb",
  "sc",
  "sd",
  "se",
  "sg",
  "sh",
  "si",
  "sj",
  "sk",
  "sl",
  "sm",
  "sn",
  "so",
  "so",
  "sr",
  "ss",
  "st",
  "sv",
  "sx",
  "sy",
  "sz",
  "tc",
  "td",
  "tf",
  "tg",
  "th",
  "tj",
  "tk",
  "tl",
  "tm",
  "tn",
  "to",
  "tp",
  "tr",
  "tr",
  "tr",
  "tt",
  "tv",
  "tw",
  "tz",
  "ua",
  "ug",
  "uk",
  "uk",
  "uk",
  "uk",
  "uk",
  "uk",
  "uk",
  "us",
  "uy",
  "uz",
  "va",
  "vc",
  "ve",
  "vg",
  "vi",
  "vn",
  "vu",
  "wf",
  "ws",
  "ye",
  "yt",
  "za",
  "zm",
  "zw",
];

function isCountryTLD(domainComponent: string): boolean {
  return COUNTRY_TLDS.indexOf(domainComponent.toLowerCase()) !== -1;
}
