import { useEffect } from "react";
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { usePrevious } from "react-use";

export interface RegrelloControlledFormFieldBaseProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
}

export type RegrelloControlledFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TRegrelloFormFieldProps,
> = RegrelloControlledFormFieldBaseProps<TFieldValues, TName> & TRegrelloFormFieldProps;

export function getErrorProp(fieldState: ControllerFieldState) {
  // (clewis): If there is no fieldState.error, return an empty object so we don't clobber an
  // `error` that may have been passed from above.
  return fieldState.error != null ? { error: fieldState.error?.message } : {};
}

/**
 * Triggers the field's `onChange()` whenever the required state changes from required to not required.
 * This is done to ensure that any stale "required" errors are cleared when the field changes to no longer
 * be required.
 * Note that we do not trigger the `onChange()` when a non-required field is marked as required as this is
 * visually noisy and not desired.
 * @param field the affected field
 * @param isRequired the current required state of the field.
 */
export function useInvokeOnChangeOnRequiredStateChange<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(field: ControllerRenderProps<TFieldValues, TName>, isRequired: boolean) {
  const prevIsRequired = usePrevious(isRequired);
  const fieldValue = field.value;
  const fieldOnChangeFn = field.onChange;

  useEffect(() => {
    if (prevIsRequired && !isRequired) {
      // (max): The required state has updated to no longer make this a required field; we want to trigger a
      // re-evaluation of the validation rules and re-render the appropriate errors.
      fieldOnChangeFn(fieldValue);
    }
  }, [fieldOnChangeFn, fieldValue, isRequired, prevIsRequired]);
}
