import type { AcyclicFieldInstanceFields, FieldInstanceFields } from "@regrello/graphql-api";

import type { FieldInstanceBaseFields } from "../../types";
import { CustomFieldPluginRegistrar } from "../../ui/molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { getErrorMessageWithPayload } from "../getErrorMessageWithPayload";

/** Returns the de facto ID of the provided field instance. */
// (clewis): This is janky; see: https://regrello.slack.com/archives/C01M8SV60U9/p1659468563656409
export function getFieldInstanceId(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields | AcyclicFieldInstanceFields,
): number {
  if (fieldInstance.values.length === 0) {
    throw new Error(
      getErrorMessageWithPayload("Cannot get the field-instance ID of a field instance having no values", {
        fieldInstance,
      }),
    );
  }

  // @ts-expect-error (dosipiuk): HACK - since regrello objects have their own id's for each value,
  // we loose the instanceId. Until we get better way to solve that we add it on top of exposed
  // types.
  return fieldInstance.values[0].fieldInstanceId || fieldInstance.values[0].id;
}

export function getSelectedFieldInstanceIdOrSourceId(fieldInstance: FieldInstanceFields) {
  const fieldInstancePlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);
  return (
    fieldInstancePlugin.getSourceFieldInstanceId(fieldInstance) ??
    fieldInstancePlugin.getCrossWorkflowSourceFieldInstanceIdFromValue(fieldInstance) ??
    getFieldInstanceId(fieldInstance) ??
    -1
  );
}
