import { clsx } from "@regrello/core-utils";
import React from "react";

import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";

export interface RegrelloIconStylerProps extends Pick<React.HTMLProps<HTMLDivElement>, "className"> {
  /** The `RegrelloIcon` element to forcefully resize. Requires exactly one child. */
  children: React.ReactNode;

  /**
   * When `true`, displays as `inline-block` with `vertical-align: middle` so it can be used in text
   * elements.
   *
   * @default false
   */
  displayInline?: boolean;

  /**
   * The intent to apply to this icon. If this is provided, `color` will be ignored.
   *
   * @default undefined
   */
  intent?: RegrelloIntent;

  /**
   * The size to force for the child icon. A value of `null` does not
   * apply any size constraint.
   *
   */
  size: RegrelloSize | null;

  /**
   * Whether this icon should be visibly muted, normal, or dark. `dark` is only available for
   * `"neutral"` icons.
   *
   * - `"muted"` will produce a lighter shade of any intent.
   * - `"normal"` will produce the standard icon shade of any intent.
   * - `"text"` is only supported for `"neutral"` intent and will produce the same color used for
   *   `text-textDefault`.
   * - `"textContrast"` is only supported for `"neutral"` intent and will produce the same color
   *   used for `text-textContrast`.
   *
   * @default "normal"
   */
  variant?: "muted" | "normal" | "text" | "textContrast";
}

/**
 * A convenience component that forcefully overrides the styles of a child SVG icon. Intended
 * for compositional patterns within other atomic components (e.g., buttons, chips), not necessarily
 * for widespread use in molecules and views beyond.
 *
 * @example
 * // The following will forcefully set the child icon to be X_SMALL and inherit the parent's color:
 * <RegrelloIconStyler size={RegrelloSize.X_SMALL}>
 *   {iconJsxElementFromProps}
 * </RegrelloIconStyler>
 */
export const RegrelloIconStyler = React.memo<RegrelloIconStylerProps>(function RegrelloIconStylerFn({
  children,
  className,
  displayInline = false,
  intent,
  size,
  variant = "normal",
}) {
  return (
    <div
      className={clsx(
        "[&_svg]:block", // Instead of the default "inline-block"; the goal here is to eliminate superfluous line height.
        {
          "inline-block align-middle": displayInline,
          "[&_svg]:w-4 [&_svg]:h-4": size === RegrelloSize.X_SMALL,
          "[&_svg]:w-5 [&_svg]:h-5": size === RegrelloSize.SMALL,
          "[&_svg]:w-6 [&_svg]:h-6": size === RegrelloSize.MEDIUM,
          "[&_svg]:w-8 [&_svg]:h-8": size === RegrelloSize.LARGE,
          "[&_svg]:w-15 [&_svg]:h-15": size === RegrelloSize.X_LARGE,

          "text-danger-icon fill-danger-icon": intent === "danger" && variant === "normal",
          "text-neutral-icon fill-neutral-icon": intent === "neutral" && variant === "normal",
          "text-primary-icon fill-primary-icon": intent === "primary" && variant === "normal",
          "text-secondary-icon fill-secondary-icon": intent === "secondary" && variant === "normal",
          "text-success-icon fill-success-icon": intent === "success" && variant === "normal",
          "text-warning-icon fill-warning-icon": intent === "warning" && variant === "normal",

          "text-danger-iconMuted fill-danger-iconMuted": intent === "danger" && variant === "muted",
          "text-neutral-iconMuted fill-neutral-iconMuted": intent === "neutral" && variant === "muted",
          "text-primary-iconMuted fill-primary-iconMuted": intent === "primary" && variant === "muted",
          "text-secondary-iconMuted fill-secondary-iconMuted": intent === "secondary" && variant === "muted",
          "text-success-iconMuted fill-success-iconMuted": intent === "success" && variant === "muted",
          "text-warning-iconMuted fill-warning-iconMuted": intent === "warning" && variant === "muted",

          "text-textDefault fill-textDefault": intent === "neutral" && variant === "text",
          "text-textContrast fill-textContrast": intent === "neutral" && variant === "textContrast",
        },
        className,
      )}
    >
      {children}
    </div>
  );
});
