import { t } from "@lingui/macro";
import React from "react";
import type { UseFormReturn } from "react-hook-form";

import { ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloControlledFormFieldCheckbox } from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";

const LABEL_WIDTH = 130;

export namespace RegrelloRoleForm {
  export enum Keys {
    NAME = "name",
    ALLOW_INVITING_PEOPLE_TO_THE_WORKSPACE = "allowInvitingPeopleToTheWorkspace",
  }

  export interface Fields {
    [Keys.NAME]: string;
    /** Currently unused. */
    [Keys.ALLOW_INVITING_PEOPLE_TO_THE_WORKSPACE]: boolean;
  }

  export interface Props {
    form: UseFormReturn<Fields>;

    /**
     * Whether the allow inviting people to the workspace checkbox is disabled.
     *
     * @default false
     */
    isAllowInvitingPeopleToTheWorkspaceFieldDisabled?: boolean;

    /**
     * Whether the name field is disabled.
     *
     * @default false
     */
    isNameDisabled?: boolean;
  }

  /**
   * Renders a standard form for collecting information about a user. Can be used in add-user or
   * edit-user dialogs.
   */
  export const Component = React.memo(function RegrelloRoleFormFn({
    form,
    isAllowInvitingPeopleToTheWorkspaceFieldDisabled,
    isNameDisabled,
  }: Props) {
    return (
      <>
        <RegrelloControlledFormFieldText
          className="mb-4"
          controllerProps={{
            control: form.control,
            name: Keys.NAME,
            rules: {
              ...ValidationRules.REQUIRED,
            },
          }}
          disabled={isNameDisabled}
          isRequiredAsteriskShown={true}
          label={t`Name`}
          labelWidth={LABEL_WIDTH}
          placeholder={t`Role`}
        />

        {!isAllowInvitingPeopleToTheWorkspaceFieldDisabled && (
          <RegrelloControlledFormFieldCheckbox
            className="mb-4"
            controllerProps={{
              control: form.control,
              name: Keys.ALLOW_INVITING_PEOPLE_TO_THE_WORKSPACE,
            }}
            disabled={isAllowInvitingPeopleToTheWorkspaceFieldDisabled}
            isRequiredAsteriskShown={false}
            label={t`Allow inviting people to the workspace`}
            labelWidth={LABEL_WIDTH}
          />
        )}
      </>
    );
  });
}
