import type { ValuesUnion } from "@regrello/core-utils";

export const RegrelloIntent = {
  NEUTRAL: "neutral" as const,
  PRIMARY: "primary" as const,
  SECONDARY: "secondary" as const,
  SUCCESS: "success" as const,
  WARNING: "warning" as const,
  DANGER: "danger" as const,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RegrelloIntent = ValuesUnion<typeof RegrelloIntent>;
