import {
  RegrelloControlWithLabel,
  RegrelloRadioGroup,
  RegrelloRadioGroupItem,
  type RegrelloRadioGroupProps,
} from "@regrello/ui-core";
import React from "react";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";

export interface RegrelloFormFieldRadioGroupProps
  // (clewis): Radio groups don't work well with the 'selfContainedForm' option, because they don't
  // really consist of a key/value pair. Can maybe revisit in the future (e.g., if we want to show
  // "Label: True/False").
  extends Omit<RegrelloFormFieldBaseProps<boolean>, "selfContainedForm">,
    Pick<RegrelloRadioGroupProps, "onBlur" | "onChange" | "value"> {
  /* An optional title for the radio group that is renders above the options  */
  groupTitle?: string;

  /* Options that will be used as form values and labels */
  options: Array<{
    align?: "top" | "center";
    value: string;
    label: React.ReactNode;
    disabled?: boolean;
  }>;
}

export const RegrelloFormFieldRadioGroup = React.memo(
  React.forwardRef<HTMLDivElement, RegrelloFormFieldRadioGroupProps>(function RegrelloFormFieldRadioGroupFn(
    {
      className,
      dataTestId,
      error,
      helperText,
      isDefaultMarginsOmitted,
      isEmphasized: _isEmphasized, // (clewis): Extract so we don't get HTML invalid-attribute errors.
      isRequiredAsteriskShown,
      options,
      label,
      labelPlacement,
      labelWidth,
      value,
      ...radioGroupProps
    }: RegrelloFormFieldRadioGroupProps,
    ref,
  ) {
    return (
      <RegrelloFormFieldLayout
        className={className}
        dataTestId={dataTestId}
        error={error}
        helperText={helperText != null ? <div className="mt--2 ml-7">{helperText}</div> : undefined}
        isDefaultMarginsOmitted={isDefaultMarginsOmitted}
        isRequiredAsteriskShown={isRequiredAsteriskShown}
        label={label}
        labelPlacement={labelPlacement}
        labelWidth={labelWidth}
      >
        <RegrelloRadioGroup value={value} {...radioGroupProps} ref={ref}>
          {options.map((option) => (
            <RegrelloControlWithLabel
              key={option.value}
              align={option.align}
              control={<RegrelloRadioGroupItem disabled={option.disabled} value={option.value} />}
              disabled={option.disabled}
              label={option.label}
            />
          ))}
        </RegrelloRadioGroup>
      </RegrelloFormFieldLayout>
    );
  }),
);
