import { clsx } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloButton, RegrelloSkeleton, RegrelloTypography } from "@regrello/ui-core";
import React from "react";

export interface RegrelloFormSectionProps {
  /** The content to show in this form section. */
  children: React.ReactNode;

  /**
   * If provided, the section will be collapsible via click on the section title. If not defined,
   * the section will always be open and will not be collapsible.
   */
  collapsibleProps?: {
    /** Whether the section is currently open or closed. */
    isOpen: boolean;

    /** Callback invoked when the section wants to open or close. */
    onOpenChange: (nextIsOpen: boolean) => void;
  };

  /** The description explaining more about this form section. */
  description?: React.ReactNode;

  /**
   * Whether the whole form section is disabled. Primarily used to disable the show/hide
   * functionality but maintain its visibility.
   * @default false
   */
  disabled?: boolean;

  /**
   * Whether to render a red asterisk next to the form section title to indicate the entire section
   * is required.
   * @default false
   */
  isRequiredAsteriskShown?: boolean;

  /**
   * Whether this section is currently loading data. Setting this to `true` will hide content and
   * show an appropriate loading state.
   */
  loading?: boolean;

  /**
   * Whether to render the component without default spacing and border.
   */
  omitSectionStyling?: boolean;

  /** The title of this form section. */
  title: string;
}

/** A visually separated section in a form that has its own title and subtitle. */
export const RegrelloFormSection = React.memo<RegrelloFormSectionProps>(function RegrelloFormSectionFn({
  children,
  collapsibleProps,
  description,
  disabled = false,
  isRequiredAsteriskShown = false,
  loading: isLoading,
  omitSectionStyling = false,
  title,
}) {
  const titleAndDescription = (
    <>
      <RegrelloTypography className={clsx("text-left", { "text-textMuted": disabled })} variant="h6">
        <span>{title}</span>
        {isRequiredAsteriskShown && <span className="text-danger-icon">*</span>}
      </RegrelloTypography>
      {description != null && (
        <RegrelloTypography className="mt-0.5" muted={true} variant="body">
          {description}
        </RegrelloTypography>
      )}
    </>
  );

  const expandableTitleAndDescription = (
    <RegrelloButton
      className="flex justify-start"
      dataTestId={DataTestIds.FORM_SECTION_TOGGLE_VISIBILITY_BUTTON}
      disabled={disabled}
      fullWidth={true}
      onClick={() => collapsibleProps?.onOpenChange(!collapsibleProps.isOpen)}
      startIcon={collapsibleProps?.isOpen ? "expand-less" : "expand-more"}
      variant="ghost"
    >
      {titleAndDescription}
    </RegrelloButton>
  );

  if (isLoading) {
    // (clewis): Brittle! These heights have been manually tuned to avoid any vertical jitter whatsoever
    // when the data loads in. If we ever change our form field sizing or spacing, this will break.
    // That's low stakes though, so I'm not spending time to prevent that.
    return (
      <div
        className={clsx({
          "mt-4 pt-4 border-t": !omitSectionStyling,
        })}
      >
        <RegrelloSkeleton className="h-4.5 w-1/3" />
        {description != null && <RegrelloSkeleton className="mt-0.5 h-4.5 w-4/5" />}
        <RegrelloSkeleton className="mt-2 h-9 w-full" />
      </div>
    );
  }

  const canCollapse = collapsibleProps != null;

  return (
    <div
      className={clsx({
        "mt-4 pt-4 border-t": !omitSectionStyling,
      })}
    >
      {canCollapse ? expandableTitleAndDescription : titleAndDescription}
      {(!canCollapse || collapsibleProps.isOpen) && <div className="mt-2">{children}</div>}
    </div>
  );
});
