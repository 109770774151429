import i18nConfig from "../../../../i18n.config.json";

/**
 * Represents a mapping of supported locales based on the i18n configuration.
 *
 * This type is derived from the `supportedLocales` property of the `i18nConfig` object.
 * It is used to define the structure of the language map used in the application.
 */
type LanguageMap = typeof i18nConfig.supportedLocales;

/**
 * Represents the supported language codes for translations.
 *
 * This type is derived from the keys of the `LanguageMap` type.
 * It is used to define the list of supported language codes in the application.
 */
export type LanguageCode = keyof LanguageMap;

/**
 * Represents the supported language codes for translations.
 */
export const SUPPORTED_LOCALES = Object.keys(i18nConfig.supportedLocales) as LanguageCode[];

/**
 * A mapping of language codes to their corresponding language names.
 */
export const LANG_MAP: LanguageMap = i18nConfig.supportedLocales;

/**
 * A mapping of language codes to their corresponding language names that is translated and would be used AS-IS.
 */
export const TARGET_LANG_MAP: LanguageMap = i18nConfig.supportedLocalesTranslated;

/**
 * List of words that should not be translated.
 */
export const WORDS_NOT_TRANSLATED = i18nConfig.wordsNotTranslated;

export const I18N_CONFIG = i18nConfig;
