import { mergeRefs } from "@regrello/core-utils";
import type { SpectrumFieldVersionFields } from "@regrello/graphql-api";
import React from "react";
import { type FieldPath, type FieldValues, useController } from "react-hook-form";

import type { FormFieldPropsToOmit } from "./regrelloControlledFormFields";
import { getErrorProp, type RegrelloControlledFormFieldProps, useInvokeOnChangeOnRequiredStateChange } from "./utils";
import {
  RegrelloFormFieldSpectrumFieldSelect,
  type RegrelloFormFieldSpectrumFieldSelectProps,
} from "../RegrelloFormFieldSpectrumFieldSelect";

const RegrelloControlledFormFieldSpectrumFieldSelectInternal =
  function RegrelloControlledFormFieldSpectrumFieldSelectFn<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>,
  >({
    controllerProps,
    onValueChange,
    placeholder,
    ...customFieldProps
  }: RegrelloControlledFormFieldProps<
    TFieldValues,
    TName,
    Omit<RegrelloFormFieldSpectrumFieldSelectProps, FormFieldPropsToOmit> & {
      onValueChange?: (name: string, newValue: SpectrumFieldVersionFields | null) => void;
    }
  >) {
    const { field, fieldState } = useController(controllerProps);
    const { ref, ...fieldProps } = field;
    useInvokeOnChangeOnRequiredStateChange(field, customFieldProps.isRequiredAsteriskShown ?? false);

    return (
      <RegrelloFormFieldSpectrumFieldSelect
        {...fieldProps}
        {...customFieldProps}
        {...getErrorProp(fieldState)}
        inputRef={customFieldProps.inputRef != null ? mergeRefs(ref, customFieldProps.inputRef) : ref}
        onChange={(newValue) => {
          onValueChange?.(field.name, newValue);
          fieldProps.onChange(newValue);
        }}
        placeholder={placeholder}
      />
    );
  };

export const RegrelloControlledFormFieldSpectrumFieldSelect = React.memo(
  RegrelloControlledFormFieldSpectrumFieldSelectInternal,
) as typeof RegrelloControlledFormFieldSpectrumFieldSelectInternal;
