import { t } from "@lingui/macro";
import {
  type FieldInstanceFields,
  type PartyBaseFields,
  type PartyFields,
  UserAccessLevel,
  type UserFields,
} from "@regrello/graphql-api";

import { getErrorMessageWithPayload } from "./getErrorMessageWithPayload";
import { PartyBaseTypeUnion } from "./parties/PartyBaseTypeUnion";
import { PartyTypeUnion } from "./parties/PartyTypeUnion";
import {
  getPartyBaseTypeUnion,
  getPartyId,
  getPartyTypeUnion,
  isPartyBaseFields,
  USER_FIELD_INSTANCE_PARTY_ID,
} from "./parties/partyUtils";
import { getOptimisticAccessRoleFields, getOptimisticUserFields } from "../state/apolloOptimisticResponseAccessors";
import { UserFieldPlugin } from "../ui/molecules/customFields/plugins/UserFieldPlugin";

export function getPartyFieldsFromPartyBaseFields(party: PartyBaseFields): PartyFields {
  const { user, ...fields } = party;
  return {
    ...fields,
    user:
      user != null
        ? {
            ...getOptimisticUserFields(),
            ...party.user,
            accessRole: {
              ...getOptimisticAccessRoleFields(),
              userScope: user?.accessRole?.userScope,
            },
          }
        : null,
  };
}

export function getPartiallyEmptyPartyTypeUnionFromBaseFields(party: PartyFields | PartyBaseFields): PartyTypeUnion {
  if (isPartyBaseFields(party)) {
    const partiallyEmptyUser: UserFields = {
      ...getOptimisticUserFields(),
      ...party.user,
      accessRole: {
        ...getOptimisticAccessRoleFields(),
        userScope: party.user?.accessRole?.userScope,
      },
    };

    return PartyBaseTypeUnion.visit(getPartyBaseTypeUnion(party), {
      user: () => PartyTypeUnion.user(partiallyEmptyUser) as PartyTypeUnion,
      team: (obj) => PartyTypeUnion.team(obj),
      unknown: () => {
        throw new Error("Invalid party type");
      },
    });
  }
  return getPartyTypeUnion(party);
}

export function getNonFieldInstancePartyTypeUnionList(parties: PartyTypeUnion[]): PartyTypeUnion[] {
  return parties.filter((party) => getPartyId(party) !== USER_FIELD_INSTANCE_PARTY_ID);
}

export function getUserAccessLevelDisplayString(isAdmin: boolean, accessLevel: UserAccessLevel): string {
  return isAdmin
    ? t`Admin`
    : accessLevel === UserAccessLevel.INTERNAL
      ? t`Non-Admin (Internal)`
      : t`Non-Admin (External)`;
}

/**
 * Converts the field-controller instances into a list of their source field instances as parties.
 *
 * This should be used when you have an action item or action item template that has a field
 * instance controlling a native field and you want to display a party value in the field.
 */
export function getControllerFieldValuesAsParties(
  controllerFieldInstances: FieldInstanceFields[] | undefined,
): PartyTypeUnion[] | undefined {
  if (controllerFieldInstances == null || controllerFieldInstances.length === 0) {
    return undefined;
  }

  if (!controllerFieldInstances.every(UserFieldPlugin.canProcessFieldInstance)) {
    throw new Error(getErrorMessageWithPayload("Must provide user field instances", { controllerFieldInstances }));
  }

  const controllerFieldsAsParties: PartyTypeUnion[] = [];
  controllerFieldInstances.forEach((controllerFieldInstance) => {
    const controllerSourceFieldInstance = UserFieldPlugin.getSourceFieldInstance(controllerFieldInstance);
    if (controllerSourceFieldInstance != null) {
      const controllerSourceFieldInstanceAsParty = PartyTypeUnion.fieldInstance(controllerSourceFieldInstance);
      const controllerFieldInstanceValues = UserFieldPlugin.getValueForFrontend(controllerFieldInstance);
      if (controllerFieldInstanceValues.length === 0) {
        controllerSourceFieldInstance.controllerBehaviorModifier = controllerFieldInstance.controllerBehaviorModifier;
        controllerFieldsAsParties.push(controllerSourceFieldInstanceAsParty);
      }
    }
  });

  return controllerFieldsAsParties.length === 0 ? undefined : controllerFieldsAsParties;
}
