import { formatDateTimeString, type WithClassName } from "@regrello/core-utils";
import {
  type RegrelloDatePickerProps,
  RegrelloDateTimePicker,
  type RegrelloDateTimePickerProps,
  type RegrelloSize,
} from "@regrello/ui-core";
import React, { useMemo } from "react";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldEmptyValueNone } from "./_internal/RegrelloFormFieldEmptyValueNone";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { getSelfContainedFormInternal } from "./_internal/selfContainedForm/getSelfContainedFormInternal";

export interface RegrelloFormFieldDatePropsV2
  extends WithClassName,
    RegrelloFormFieldBaseProps<Date | null>,
    Pick<RegrelloDatePickerProps, "getIsDateDisabled" | "firstSegmentRef" | "inputRef">,
    Pick<RegrelloDateTimePickerProps, "isDefaultTimeStartOfDay"> {
  dataTestIdForDialog?: string;

  /**
   * Apply the disabled modifier to the matching days.
   */
  getIsDateDisabled?: (day: Date) => boolean;

  /**
   * Whether to hide the form field error icon and message. Added (potentially temporarily depending
   * on Design System updates) for Conditional Branching to support the new error state for stage
   * start date forms.
   */
  isErrorMessageHidden?: boolean;

  /**
   * Maximal selectable date
   */
  maxDate?: Date;

  /**
   * Minimal selectable date
   */
  minDate?: Date;

  /**
   * If provided, will show an 'x' button in the input that invokes this callback when clicked.
   * Expected usage thereafter is for the callback to clear the input's value.
   */
  onClearClick?: () => void;
  onChange: (date: Date | string | null, value?: string | null | undefined) => void;

  /**
   * Whether to show a time-picker with the date-picker.
   * @default false
   */
  showTimePicker?: boolean;

  /**
   * The size of the input field.
   * @default RegrelloSize.LARGE
   */
  size?: RegrelloSize;

  value?: Date | null;

  /**
   * Should chosen value be represented as string instead of `Date`
   */
  valueAsString?: boolean;
}

/**
 * Component that can be used in forms to select a date.
 * Supports both accessible segmented input with keyboard navigation and calendar popover.
 */
export const RegrelloFormFieldDate = React.memo<RegrelloFormFieldDatePropsV2>(function RegrelloFormFieldDateFn({
  className,
  dataTestId,
  dataTestIdForDialog,
  disabled,
  error,
  firstSegmentRef,
  getIsDateDisabled,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  inputRef,
  isDefaultMarginsOmitted,
  isDefaultTimeStartOfDay,
  isDeleted,
  isEmphasized,
  isErrorMessageHidden = false,
  isLabelVerticallyCentered,
  isRequiredAsteriskShown,
  label,
  labelPlacement,
  labelWidth,
  maxDate,
  minDate,
  name,
  onChange,
  onClearClick,
  selfContainedForm,
  showTimePicker = false,
  size,
  value,
  valueAsString = false,
  variant = "default",
  warning,
}) {
  const getSavedValueContent = (savedValue: Date | null) => {
    switch (true) {
      case savedValue === null:
        return <RegrelloFormFieldEmptyValueNone />;
      default:
        return formatDateTimeString(savedValue as Date);
    }
  };

  const intent: RegrelloDatePickerProps["intent"] = error != null ? "danger" : isEmphasized ? "warning" : undefined;

  const memoizedDatePickerProps = useMemo(() => {
    return {
      dataTestIdForDialog,
      disabled,
      firstSegmentRef,
      getIsDateDisabled,
      inputRef,
      intent,
      name,
      onClearClick,
      warning,
    };
  }, [
    dataTestIdForDialog,
    disabled,
    firstSegmentRef,
    getIsDateDisabled,
    inputRef,
    intent,
    name,
    onClearClick,
    warning,
  ]);

  const memoizedTimePickerProps = useMemo(() => {
    return { intent };
  }, [intent]);

  return (
    <RegrelloFormFieldLayout
      className={className}
      dataTestId={dataTestId}
      error={isErrorMessageHidden ? undefined : error}
      helperText={helperText}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDefaultMarginsOmitted={isDefaultMarginsOmitted}
      isDeleted={isDeleted}
      isLabelVerticallyCentered={isLabelVerticallyCentered}
      isRequiredAsteriskShown={isRequiredAsteriskShown}
      label={label}
      labelPlacement={labelPlacement}
      labelWidth={labelWidth}
      selfContainedForm={getSelfContainedFormInternal(selfContainedForm, (savedValue) =>
        getSavedValueContent(savedValue),
      )}
      variant={variant}
    >
      <RegrelloDateTimePicker
        datePickerProps={memoizedDatePickerProps}
        disabled={disabled}
        isDefaultTimeStartOfDay={isDefaultTimeStartOfDay}
        maxValue={maxDate}
        minValue={minDate}
        onChange={onChange}
        showTimePicker={showTimePicker}
        size={size}
        timePickerProps={memoizedTimePickerProps}
        value={value}
        valueAsString={valueAsString}
      />
    </RegrelloFormFieldLayout>
  );
});
