import { assertNever } from "@regrello/core-utils";
import type {
  FieldInstanceFields,
  FieldInstanceValueBaseFields,
  FieldInstanceValueFields,
  FieldInstanceValueWithCrossWorkflowFields,
} from "@regrello/graphql-api";

import type { FieldInstanceBaseFields } from "../../../../../types";

/** Distinguishes between FieldInstanceFields and FieldInstanceBaseFields. */
export function getIsFieldInstanceFields(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): fieldInstance is FieldInstanceFields {
  return (
    ("actionItem" in fieldInstance && "status" in (fieldInstance.actionItem || {})) ||
    "actionItemTemplate" in fieldInstance ||
    "workflowTemplate" in fieldInstance ||
    ("workflow" in fieldInstance && "scheduleStatus" in (fieldInstance.workflow || {}))
  );
}

/**
 * Distinguishes between FieldInstanceValueWithCrossWorkflowFields, FieldInstanceValueFields,
 * and FieldInstanceValueBaseFields.
 */
export function getIsFieldInstanceValueWithCrossWorkflowFields(
  value: FieldInstanceValueWithCrossWorkflowFields | FieldInstanceValueFields | FieldInstanceValueBaseFields,
): value is FieldInstanceValueWithCrossWorkflowFields {
  // eslint-disable-next-line no-underscore-dangle
  switch (value.__typename) {
    case "FieldInstanceMultiValueDocument":
      return "crossWorkflowSourceFieldInstanceMultiValueDocument" in value;
    case "FieldInstanceMultiValueFloat":
      return "crossWorkflowSourceFieldInstanceMultiValueFloat" in value;
    case "FieldInstanceMultiValueInt":
      return "crossWorkflowSourceFieldInstanceMultiValueInt" in value;
    case "FieldInstanceMultiValueParty":
      return "crossWorkflowSourceFieldInstanceMultiValueParty" in value;
    case "FieldInstanceMultiValueRegrelloObjectInstance":
      return "crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance" in value;
    case "FieldInstanceMultiValueString":
      return "crossWorkflowSourceFieldInstanceMultiValueString" in value;
    case "FieldInstanceMultiValueTime":
      return "crossWorkflowSourceFieldInstanceMultiValueTime" in value;
    case "FieldInstanceValueBoolean":
      return "crossWorkflowSourceFieldInstanceValueBoolean" in value;
    case "FieldInstanceValueFloat":
      return "crossWorkflowSourceFieldInstanceValueFloat" in value;
    case "FieldInstanceValueInt":
      return "crossWorkflowSourceFieldInstanceValueInt" in value;
    case "FieldInstanceValueParty":
      return "crossWorkflowSourceFieldInstanceValueParty" in value;
    case "FieldInstanceValueString":
      return "crossWorkflowSourceFieldInstanceValueString" in value;
    case "FieldInstanceValueTime":
      return "crossWorkflowSourceFieldInstanceValueTime" in value;
    default:
      assertNever(value);
  }
}

/** Distinguishes between FieldInstanceValueFields and FieldInstanceValueBaseFields. */
export function getIsFieldInstanceValueFields(
  value: FieldInstanceValueFields | FieldInstanceValueBaseFields,
): value is FieldInstanceValueFields {
  // eslint-disable-next-line no-underscore-dangle
  switch (value.__typename) {
    case "FieldInstanceMultiValueDocument":
      return "sourceFieldInstanceMultiValueDocument" in value;
    case "FieldInstanceMultiValueFloat":
      return "sourceFieldInstanceMultiValueFloat" in value;
    case "FieldInstanceMultiValueInt":
      return "sourceFieldInstanceMultiValueInt" in value;
    case "FieldInstanceMultiValueParty":
      return "sourceFieldInstanceMultiValueParty" in value;
    case "FieldInstanceMultiValueRegrelloObjectInstance":
      return "sourceFieldInstanceMultiValueRegrelloObjectInstance" in value;
    case "FieldInstanceMultiValueString":
      return "sourceFieldInstanceMultiValueString" in value;
    case "FieldInstanceMultiValueTime":
      return "sourceFieldInstanceMultiValueTime" in value;
    case "FieldInstanceValueBoolean":
      return "sourceFieldInstanceValueBoolean" in value;
    case "FieldInstanceValueFloat":
      return "sourceFieldInstanceValueFloat" in value;
    case "FieldInstanceValueInt":
      return "sourceFieldInstanceValueInt" in value;
    case "FieldInstanceValueParty":
      return "sourceFieldInstanceValueParty" in value;
    case "FieldInstanceValueString":
      return "sourceFieldInstanceValueString" in value;
    case "FieldInstanceValueTime":
      return "sourceFieldInstanceValueTime" in value;
    default:
      assertNever(value);
  }
}
