// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type OutOfOfficeEventFields = { id: number, startAt: string, endAt?: string | null, isActive: boolean, delegationSettings?: { shouldCcOnDelegatedTasks: boolean, nonApprovalTaskDelegates: Array<PartyBaseFields>, approvalTaskDelegates: Array<PartyBaseFields>, roleDelegations: Array<{ role: { id: number, name: string }, delegateParties: Array<PartyBaseFields> }> } | null };

export const OutOfOfficeEventFields = gql`
    fragment OutOfOfficeEventFields on OutOfOfficeEvent {
  id
  startAt
  endAt
  isActive
  delegationSettings {
    nonApprovalTaskDelegates {
      ...PartyBaseFields
    }
    approvalTaskDelegates {
      ...PartyBaseFields
    }
    shouldCcOnDelegatedTasks
    roleDelegations {
      role {
        id
        name
      }
      delegateParties {
        ...PartyBaseFields
      }
    }
  }
}
    `;