import { EMPTY_ARRAY } from "@regrello/core-utils";
import type { ConditionOperator, Maybe } from "@regrello/graphql-api";

export function getUpdateStartingConditionsInputsForEmptyOperators(
  leftFieldInstanceValueId: number,
  operator: ConditionOperator.EMPTY | ConditionOperator.NOT_EMPTY,
  leftFieldInstancePropertyID: Maybe<number> = null,
) {
  return {
    leftFieldInstanceValueID: leftFieldInstanceValueId,
    leftFieldInstancePropertyID: leftFieldInstancePropertyID,
    operatorV2: operator,
    rightFloatMultiValue: EMPTY_ARRAY,
    rightIntMultiValue: EMPTY_ARRAY,
    rightPartyIDMultiValue: EMPTY_ARRAY,
    rightStringMultiValue: EMPTY_ARRAY,
    rightTimeMultiValue: EMPTY_ARRAY,
  };
}
