import type { Auth0Error } from "auth0-js";
import { useCallback } from "react";

import { captureExceptionWithDefaults } from "../sentryErrorUtils";

export namespace useErrorLogger {
  export interface Return {
    /**
     * Logs a plain error to our error-logging service. Do not log ApolloErrors. This happens
     * automatically by Apollo in the `errorLink` in `AuthenticatedApolloProvider`.
     */
    logError: (error: Error & { graphQLErrors?: never }) => void;

    /** Logs an `Auth0Error` to our error-logging service. */
    logAuth0Error: (error: Auth0Error, email?: string) => void;

    /**
     * Logs a plain-string message to our error-logging service.
     *
     * _Note:_ Prefer `logError` instead, since it sends richer information to our error logging
     * service.
     */
    logErrorMessage: (message: string, ...optionalParams: unknown[]) => void;
  }
}

/**
 * Sends error messages to Sentry, our third-party error-logging service. In general, all frontend
 * errors should be sent to Sentry, since it allows us to be notified when errors occur on
 * Production.
 *
 * Sentry must be initialized exactly one time at the application root using Sentry.init(), or
 * undefined behavior may occur when using this hook.
 */
export function useErrorLogger(): useErrorLogger.Return {
  // Force TS to refuse ApolloErrors as arguments to this callback. Apollo errors are automatically
  // handled by the `errorLink` in `AuthenticatedApolloProvider`.
  const logError = useCallback((error: Error & { graphQLErrors?: never }) => {
    console.error(error);
    captureExceptionWithDefaults(error);
  }, []);

  const logAuth0Error = useCallback((error: Auth0Error, email?: string) => {
    console.error(error);

    const plainError = new Error(error.description);
    plainError.name = error.error;

    captureExceptionWithDefaults(plainError, {
      tags: {
        errorType: "validation", // (clewis): Assume all Auth0 errors are validation errors for now.
        isAuth0Error: true,
      },
      extra: {
        ...error,
        email,
      },
    });
  }, []);

  const logErrorMessage = useCallback((message: string, ...optionalParams: unknown[]) => {
    console.error(message, ...optionalParams);
    // (clewis): Use captureException with a new Error, so we get a stack trace.
    captureExceptionWithDefaults(new Error(message), { extra: { params: optionalParams } });
  }, []);

  return { logError, logAuth0Error, logErrorMessage };
}
