import { t } from "@lingui/macro";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { type TagTypeFields, useCreateTagTypeMutation } from "@regrello/graphql-api";
import { RegrelloDialogV2, RegrelloTypography } from "@regrello/ui-core";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ValidationRules } from "../../../../../constants/globalConstants";
import { updateNewTagTypesInCache } from "../../../../../state/apolloCacheAccessors";
import type { SubmitHandler } from "../../../../../types/form";
import { getGraphQlErrorCode, RegrelloGraphQlErrorCode } from "../../../../../utils/errorUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";

interface CreateTagTypeFormFields {
  name: string;
}

export interface ConfigureTagDialogProps {
  /** An initial value to show in the "name" field. Intended to save the user some time. */
  defaultValue: string;

  /** Whether the dialog should render as open. */
  isOpen: boolean;

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;

  /** Callback invoked after a new tag type has been successfully added on the backend. */
  onTagTypeAdded: (newTagType: TagTypeFields) => void;
}

/**
 * A dialog in which the user can add a new tag type. Encapsulates all necessary communication with
 * the backend and emits the new tag type via a prop.
 */
export const CreateTagTypeDialog = React.memo<ConfigureTagDialogProps>(function CreateTagDialogFn({
  defaultValue,
  isOpen,
  onClose,
  onTagTypeAdded,
}) {
  const { handleSubmit, setValue, ...form } = useForm<CreateTagTypeFormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (isOpen) {
      setValue("name", defaultValue);
    }
  }, [defaultValue, setValue, isOpen]);

  const { handleError } = useErrorHandler();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleClose = useCallback(() => {
    setErrorMessage(undefined);
    onClose();
  }, [onClose]);

  const [createTagTypeAsync] = useCreateTagTypeMutation({
    onError: (error) => {
      const errorCode = getGraphQlErrorCode(error);
      switch (errorCode) {
        case RegrelloGraphQlErrorCode.OBJECT_NAME_MUST_BE_UNIQUE:
          setErrorMessage(t`A tag category with that name already exists.`);
          break;
        default:
          handleError(error, {
            toastMessage: t`Failed to create tag category. Please try again, or contact a Regrello admin if you continue to see this error.`,
          });
          break;
      }
    },
    update: (cache, mutationResult) => {
      updateNewTagTypesInCache(cache, mutationResult.data ?? undefined);
    },
  });

  const handleSubmitInternal: SubmitHandler<CreateTagTypeFormFields> = useCallback(
    async (data) => {
      const result = await createTagTypeAsync({
        variables: {
          input: {
            name: data.name,
          },
        },
      });

      if (result.errors != null) {
        return false;
      }

      if (result.data?.createTagType?.tagType == null) {
        console.warn("Created a tag type successfully, but no tag-type data was returned in the response.");
        return false;
      }

      onTagTypeAdded(result.data.createTagType.tagType);
      handleClose();
      return true;
    },
    [createTagTypeAsync, handleClose, onTagTypeAdded],
  );

  const handleNativeFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      // (clewis): Prevent the native-form submission from refreshing the page.
      event.preventDefault();
      return handleSubmit(handleSubmitInternal)();
    },
    [handleSubmit, handleSubmitInternal],
  );

  return (
    // TODO (clewis): Convert this to a RegrelloFormDialog.
    // See: https://app.regrello.com/assigned-tasks?task-id=VuibtVRsSnc
    <RegrelloDialogV2
      actions={[
        {
          text: t`Cancel`,
          buttonProps: {
            onClick: handleClose,
            variant: "outline",
          },
        },
        {
          text: t`Add`,
          buttonProps: {
            dataTestId: DataTestIds.ADD_TAG_TYPE_DIALOG_SUBMIT_BUTTON,
            intent: "primary",
            disabled: !form.formState.isValid,
            onClick: handleSubmit(handleSubmitInternal),
          },
        },
      ]}
      dataTestId={DataTestIds.ADD_TAG_TYPE_DIALOG}
      onClose={handleClose}
      open={isOpen}
      showCloseButton={true}
      title={t`Add tag category`}
    >
      <form onSubmit={handleNativeFormSubmit}>
        {errorMessage != null && (
          <RegrelloTypography className="mb-4" intent="danger" muted={true} variant="body">
            {errorMessage}
          </RegrelloTypography>
        )}
        <RegrelloTypography className="mb-2" variant="body">
          {t`This helps you organize all your tags into groups.`}
        </RegrelloTypography>
        <RegrelloControlledFormFieldText
          autoFocus={true}
          controllerProps={{ control: form.control, name: "name", rules: ValidationRules.REQUIRED }}
          isRequiredAsteriskShown={true}
          label={t`Name`}
        />
      </form>
    </RegrelloDialogV2>
  );
});
