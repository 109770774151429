// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueStringWithCrossWorkflowFields } from './FieldInstanceValueStringWithCrossWorkflowFields.generated';
import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringWithCrossWorkflowFields } from './FieldInstanceMultiValueStringWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntWithCrossWorkflowFields } from './FieldInstanceValueIntWithCrossWorkflowFields.generated';
import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatWithCrossWorkflowFields } from './FieldInstanceValueFloatWithCrossWorkflowFields.generated';
import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanWithCrossWorkflowFields } from './FieldInstanceValueBooleanWithCrossWorkflowFields.generated';
import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeWithCrossWorkflowFields } from './FieldInstanceValueTimeWithCrossWorkflowFields.generated';
import { FieldInstanceValueTimeFields } from './FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from './AcyclicFieldInstanceFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValuePartyWithCrossWorkflowFields } from './FieldInstanceValuePartyWithCrossWorkflowFields.generated';
import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyWithCrossWorkflowFields } from './FieldInstanceMultiValuePartyWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValuePartyFields } from './FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentWithCrossWorkflowFields } from './FieldInstanceMultiValueDocumentWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueDocumentFields } from './FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields } from './FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntWithCrossWorkflowFields } from './FieldInstanceMultiValueIntWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatWithCrossWorkflowFields } from './FieldInstanceMultiValueFloatWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeWithCrossWorkflowFields } from './FieldInstanceMultiValueTimeWithCrossWorkflowFields.generated';
import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { gql } from '@apollo/client';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument = (
  { id: number }
  & FieldInstanceMultiValueDocumentWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat = (
  { id: number }
  & FieldInstanceMultiValueFloatWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt = (
  { id: number }
  & FieldInstanceMultiValueIntWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty = (
  { id: number }
  & FieldInstanceMultiValuePartyWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance = (
  { id: number }
  & FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString = (
  { id: number }
  & FieldInstanceMultiValueStringWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime = (
  { id: number }
  & FieldInstanceMultiValueTimeWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean = (
  { id: number }
  & FieldInstanceValueBooleanWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat = (
  { id: number }
  & FieldInstanceValueFloatWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt = (
  { id: number }
  & FieldInstanceValueIntWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty = (
  { id: number }
  & FieldInstanceValuePartyWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString = (
  { id: number }
  & FieldInstanceValueStringWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime = (
  { id: number }
  & FieldInstanceValueTimeWithCrossWorkflowFields
);

export type FieldInstanceValueWithCrossWorkflowFields = FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime;

export const FieldInstanceValueWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueWithCrossWorkflowFields on FieldInstanceValue {
  id
  ... on FieldInstanceValueString {
    ...FieldInstanceValueStringWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueString {
    ...FieldInstanceMultiValueStringWithCrossWorkflowFields
  }
  ... on FieldInstanceValueInt {
    ...FieldInstanceValueIntWithCrossWorkflowFields
  }
  ... on FieldInstanceValueFloat {
    ...FieldInstanceValueFloatWithCrossWorkflowFields
  }
  ... on FieldInstanceValueBoolean {
    ...FieldInstanceValueBooleanWithCrossWorkflowFields
  }
  ... on FieldInstanceValueTime {
    ...FieldInstanceValueTimeWithCrossWorkflowFields
  }
  ... on FieldInstanceValueParty {
    ...FieldInstanceValuePartyWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueParty {
    ...FieldInstanceMultiValuePartyWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...FieldInstanceMultiValueDocumentWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueInt {
    ...FieldInstanceMultiValueIntWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...FieldInstanceMultiValueFloatWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueTime {
    ...FieldInstanceMultiValueTimeWithCrossWorkflowFields
  }
}
    `;