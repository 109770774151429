import type { AnyObject } from "@regrello/core-utils";
import type { FieldFields, FieldInstanceFields } from "@regrello/graphql-api";
import type { UseFormReturn } from "react-hook-form";

import { isUserFieldInstance } from "./customFieldTypeUtils";
import { CustomFieldPluginRegistrar } from "../../ui/molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { UserFieldPlugin } from "../../ui/molecules/customFields/plugins/UserFieldPlugin";
import { getPartyEmail } from "../parties/partyUtils";

/**
 * Initialize the form value for custom fields according to their types. This is necessary because
 * the default value of useForm is undefined, and this causes the app to crash when the default
 * value of a custom field is supposed to be an array.
 */
export function formSetFieldInstanceFormFieldValue(
  fieldInstance: FieldInstanceFields,
  form: UseFormReturn<AnyObject>,
  formFieldName: string,
  isUserLoggedOut?: boolean,
) {
  const plugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);

  if (isUserFieldInstance(fieldInstance) && isUserLoggedOut) {
    // (clewis, wbuchanan): This is an edge case that violates the plugin framework's assumption
    // that each plugin has exactly one type representation on the frontend. Thus, we handle this
    // special case outside of the UserFieldPlugin.
    form.setValue(formFieldName, UserFieldPlugin.getValueForFrontend(fieldInstance).map(getPartyEmail).join(", "));
  } else {
    form.setValue(formFieldName, plugin.getValueForFrontend(fieldInstance));
  }
}

/**
 * Returns true if the provided field's form field should require a value before the form can be
 * submitted. Leverages plugins' `isFormFieldValueRequired` function.
 *
 * @default true
 */
// (clewis): This is basically a big dance around the fact that checkboxes must intentionally _not_
// require a value. See CheckboxFieldPlugin.
export function isFieldFormFieldRequired(field: FieldFields): boolean {
  const plugin = CustomFieldPluginRegistrar.getPluginForField(field);
  return plugin.isFormFieldValueRequired?.() ?? true;
}
