import { type AccessRoleFields, AccessRoleName } from "@regrello/graphql-api";
import { useCallback } from "react";

import { useUser } from "../authentication/userContextUtils";

export namespace useRelativePermission {
  export interface Return {
    getIsAccessRoleAssignableByCurrentUser: (accessRole: AccessRoleFields) => boolean;
  }
}

export function useRelativePermission(): useRelativePermission.Return {
  const { currentUser } = useUser();

  const getIsAccessRoleAssignableByCurrentUser = useCallback(
    (accessRole: AccessRoleFields) => {
      if (currentUser.accessRole == null) {
        return false;
      }

      // (krashanoff) Owners cannot create others with the "owner" role.
      return currentUser.accessRole.name === AccessRoleName.OWNER
        ? currentUser.accessRole.level > accessRole.level
        : currentUser.accessRole.level >= accessRole.level;
    },

    [currentUser.accessRole],
  );

  return { getIsAccessRoleAssignableByCurrentUser };
}
