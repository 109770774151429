import {
  type ApolloError,
  type FetchResult,
  isApolloError,
  NetworkStatus,
  type OperationVariables,
  type QueryResult,
} from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import type { DocumentNode } from "graphql";

// (clewis): These function are new as of Aug 18 and haven't been rigorously battle-tested to verify
// correctness with edge cases like refetching, polling, etc. Use them cautiously.

export async function awaitCorrectlyTypedAwaitedMutationResults(
  promises: Array<string | Promise<FetchResult<unknown>>>,
): Promise<FetchResult[]> {
  // (dosipiuk): Type cast is needed due to invalid inference from generated apollo types
  //
  // See: https://github.com/apollographql/apollo-client/issues/9292
  const results = (await Promise.all(promises)) as FetchResult[];
  return results;
}

/**
 * Returns `true` if the provided Apollo Client query result has not been called or is still
 * initially loading.
 */
export function isQueryResultLoading<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
) {
  return !queryResult.called || queryResult.loading;
}

/**
 * Returns `true` if the provided Apollo Client query result has been called and finished loading -
 * either successfully (with data) or unsuccessfully (with an error).
 */
export function isQueryResultReady<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
) {
  return (
    !isQueryResultLoading(queryResult) &&
    (queryResult.networkStatus === NetworkStatus.ready || queryResult.networkStatus === NetworkStatus.error)
  );
}

/**
 * Returns `true` if the provided Apollo Client query result has returned an error.
 */
export function isQueryResultError<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
) {
  // (clewis): This isn't the most beautiful - or even verifiably correct - check, but it's what we
  // tend to check nowadays. Probably deserves more investigation (e.g., into networkStatus).
  return !isQueryResultLoading(queryResult) && (queryResult.error != null || queryResult.data == null);
}

/** Returns `true` if at least one result in the provided array of fetch results has failed. */
// (dosipiuk): Type cast is needed due to invalid inference from generated apollo types
//
// See: https://github.com/apollographql/apollo-client/issues/9292
export function isSomeMutationResultFailed(results: FetchResult[]): boolean {
  return results.some((result) => result.errors != null);
}

/**
 * Returns `true` if the provided GraphQL response error was considered a user-validation error by
 * the backend. This distinction affects how the error is reported to our monitoring tools.
 */
export function isValidationApolloError({ graphQLErrors }: Pick<ApolloError, "graphQLErrors">): boolean {
  const errorCode = getRegrelloErrorCodeFromApolloError({ graphQLErrors });
  return errorCode?.startsWith("ValidationErrorCode") ?? false;
}

/**
 * Returns the freshest data possible from the provided query result. The `getPreviousId` and
 * `currentId` parameters help determine whether the previous data and current data correspond to
 * the same entity or separate entities. If they're different entities, then the function won't fall
 * back to the `previousData`.
 */
export function getDataOrFallbackToPreviousData<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
  getPreviousId: (previousData: TData | undefined) => string | number | null | undefined,
  currentId: string | number | null | undefined,
): TData | undefined {
  if (queryResult.data != null) {
    return queryResult.data;
  }

  const previousId = getPreviousId(queryResult.previousData);
  const shouldIgnorePreviousData = previousId != null && currentId != null && previousId !== currentId;
  if (queryResult.error == null && !shouldIgnorePreviousData) {
    return queryResult.previousData;
  }
  return undefined;
}

/**
 * Returns the Regrello error code from the provided `ApolloError` if an error code is present, or
 * `undefined` otherwise. If for some reason there are two error codes, this function will return
 * only the first one.
 */
export function getRegrelloErrorCodeFromApolloError({
  graphQLErrors,
}: Pick<ApolloError, "graphQLErrors">): string | undefined {
  if (graphQLErrors.length === 0) {
    return undefined;
  }
  const errorCode = graphQLErrors[0].extensions.code;
  if (typeof errorCode !== "string") {
    return undefined;
  }
  return errorCode;
}

/**
 * Returns the Regrello "FriendlyMessage" string (if it exists) from the provided `ApolloError`. If
 * for some reason there are more than one error, this function will use only the first one.
 */
export function getRegrelloErrorFriendlyMessageFromApolloError(error: Error | string): string | undefined {
  if (typeof error !== "object" || !isApolloError(error)) {
    return undefined;
  }

  const { graphQLErrors } = error;

  if (graphQLErrors.length === 0) {
    return undefined;
  }

  const friendlyMessage = graphQLErrors[0].extensions.friendlyMessage;

  if (typeof friendlyMessage !== "string") {
    return undefined;
  }

  return friendlyMessage;
}

/**
 * This function uses `apollo client` trick to refetch queries with latest variables from any place,
 * without passing those variables around. This requires to pass an array of strings containing
 * operation names, instead of full `DocumentNode`.
 * @param documents List of graphql DocumentNode to refetch
 */
export function refetchWithLatestVariablesByDocument(documents: DocumentNode[]) {
  const refetchQueries = [];

  for (const document of documents) {
    const operationName = getOperationName(document);
    if (operationName != null) {
      refetchQueries.push(operationName);
    }
  }

  return refetchQueries.length > 0 ? refetchQueries : undefined;
}
