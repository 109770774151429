import { clsx } from "@regrello/core-utils";
import React from "react";

import type { RegrelloButtonVariant } from "./RegrelloButtonVariant";

export interface RegrelloButtonGroupV2Props {
  /** The buttons to show in the button group. */
  children: React.ReactNode;

  /**
   * The variant that the child buttons are using. All buttons must use the same variant in order
   * to ensure valid group styling.
   */
  variant: RegrelloButtonVariant;
}

/** A standard button group in Regrello. */
export const RegrelloButtonGroup = React.memo(
  React.forwardRef(function RegrelloButtonGroupFn(
    { children, variant }: RegrelloButtonGroupV2Props,
    ref: React.Ref<HTMLDivElement>,
  ) {
    return (
      <div
        ref={ref}
        className={clsx([
          `
          flex
          items-center
          `,
          // (clewis): Handle the simple case in which buttons are direct children.
          `
          [&>button:not(:last-child)]:rounded-r-none
          [&>button:not(:first-child)]:rounded-l-none
          `,
          // (clewis): Handle the more complex case in which buttons are wrapped in a popover.
          `
          [&>*:not(:first-child)>button]:rounded-r-none
          [&>*:not(:last-child)>button]:rounded-r-none
          `,
          {
            [`
              [&>button:not(:first-child)]:border-l
            [&>button:not(:first-child)]:border-background/50
              [&>button:not(:first-child)]:border-solid
            `]: variant === "solid",
            [`
              [&>*:not(:first-child)]:-ml-px
            `]: variant === "outline",
          },
        ])}
      >
        {children}
      </div>
    );
  }),
);
