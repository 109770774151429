import { EMPTY_STRING } from "@regrello/core-utils";
import type { DataObjectCell, RegrelloObjectFields, RegrelloObjectInstanceFields } from "@regrello/graphql-api";

import type { RegrelloObjectInstanceForDataGrid } from "../../../formFields/regrelloObjectSearchDialog/RegrelloObjectSearchDialog";

export const convertRegrelloObjectInstanceForDataGrid = (
  regrelloObject: RegrelloObjectFields,
  regrelloObjectInstance: RegrelloObjectInstanceFields | { id: undefined; dataObjectCells: DataObjectCell[] },
): RegrelloObjectInstanceForDataGrid => {
  const regrelloObjectInstanceForDataGrid: RegrelloObjectInstanceForDataGrid = {
    rowId: `${regrelloObjectInstance.id}`,
  };
  for (const { dataObjectKey } of regrelloObject.properties) {
    regrelloObjectInstanceForDataGrid[dataObjectKey] =
      regrelloObjectInstance.dataObjectCells.find(({ key }) => key === dataObjectKey)?.value.stringValue ??
      EMPTY_STRING;
  }
  return regrelloObjectInstanceForDataGrid;
};
