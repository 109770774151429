// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type UserFieldsWithTeams = (
  { teams: Array<TeamWithoutMembersFields> }
  & UserFields
);

export const UserFieldsWithTeams = gql`
    fragment UserFieldsWithTeams on User {
  ...UserFields
  teams {
    ...TeamWithoutMembersFields
  }
}
    `;