import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import {
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
} from "@regrello/graphql-api";
import React, { useCallback, useState } from "react";
import { type SetValueConfig, useFieldArray, type UseFormReturn } from "react-hook-form";
import { useMount } from "react-use";

import { isFieldFormFieldRequired } from "../../../utils/customFields/customFieldFormUtils";
import { getCustomFieldInstanceInputType } from "../../../utils/customFields/getCustomFieldInstanceInputType";
import { CustomFieldPluginRegistrar } from "../customFields/plugins/registry/customFieldPluginRegistrar";
import type { RegrelloFormFieldLayoutProps } from "../formFields/_internal/RegrelloFormFieldLayout";
import type { NameTemplateFieldDisplayValue } from "../nameTemplate/RegrelloNameTemplatePreviewWrapper";
import {
  getNameTemplateFieldDisplayValuesFromFieldInstances,
  getNewNameTemplateFieldDisplayValuesFromFieldInstanceUpdate,
} from "../nameTemplate/utils/nameTemplateUtils";
import { SpectrumFieldPluginRegistrar } from "../spectrumFields/registry/spectrumFieldPluginRegistrar";
import { getAllValidationRulesFromFieldConstraints } from "../spectrumFields/utils/spectrumFieldConstraintUtils";

const LABEL_WIDTH = 108;

export interface RegrelloCompleteCustomFieldsFormFields {
  customFields: Array<{
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues;
    value: unknown;
  }>;
}

export interface RegrelloCompleteCustomFieldsFormProps
  extends Pick<RegrelloFormFieldLayoutProps<unknown>, "labelPlacement"> {
  allowCreateUsers: boolean;
  fieldInstances: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>;
  form: UseFormReturn<RegrelloCompleteCustomFieldsFormFields>;

  /**
   * Callback invoked whenever any of the field instances change value (and thus their respective
   * name template display value also changes).
   */
  onNameTemplateFieldDisplayValuesChange?: (fieldDisplayValues: NameTemplateFieldDisplayValue[]) => void;
  onValueChangeFinish: (fieldId: number) => void;
  onValueChangeStart: (fieldId: number) => void;
  defaultValues?: { [key: string]: string };
}

/**
 * Renders a form section that asks the user to fill in values for each of the provided field
 * instances. A form field is rendered for each field instance.
 */
export const RegrelloCompleteCustomFieldsForm = React.memo<RegrelloCompleteCustomFieldsFormProps>(
  function RegrelloCompleteCustomFieldsFormFn({
    allowCreateUsers,
    fieldInstances,
    form,
    labelPlacement,
    onNameTemplateFieldDisplayValuesChange,
    onValueChangeFinish,
    onValueChangeStart,
    defaultValues,
  }) {
    // (clewis): Avoid errors of the form 'Type instantiation is excessively deep and possibly
    // infinite' by explicitly stating the keys instead of reling on the default Path type.
    const formSetValue = form.setValue as <K extends keyof RegrelloCompleteCustomFieldsFormFields>(
      name: K,
      value: RegrelloCompleteCustomFieldsFormFields[K],
      options?: SetValueConfig,
    ) => void;
    const [hasSetValues, setHasSetValues] = useState(false);

    const [nameTemplateFieldDisplayValues, setnameTemplateFieldDisplayValues] =
      useState<NameTemplateFieldDisplayValue[]>(EMPTY_ARRAY);

    const { fields: rows } = useFieldArray({ control: form.control, name: "customFields" });

    useMount(() => {
      // Populate the initial value for each field instance:
      formSetValue(
        "customFields",
        fieldInstances.map((fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) => ({
          fieldInstance,
          value: CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getEmptyValueForFrontend(
            undefined,
            defaultValues?.[fieldInstance.field.name],
          ),
        })),
      );
      setHasSetValues(true);

      // (zstanik): Initialize display values for the name template fields if any field instances
      // already have values associated with them.
      const initialNameTemplateFieldDisplayValues = getNameTemplateFieldDisplayValuesFromFieldInstances(fieldInstances);

      onNameTemplateFieldDisplayValuesChange?.(initialNameTemplateFieldDisplayValues);
      setnameTemplateFieldDisplayValues(initialNameTemplateFieldDisplayValues);
    });

    // (zstanik): Finds the field display value that corresponds to the field instance that changed
    // and updates to the new display value.
    const handleValueChange = useCallback(
      (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues, value: unknown) => {
        const newNameTemplateFieldDisplayValues = getNewNameTemplateFieldDisplayValuesFromFieldInstanceUpdate(
          nameTemplateFieldDisplayValues,
          fieldInstance,
          value,
        );
        onNameTemplateFieldDisplayValuesChange?.(newNameTemplateFieldDisplayValues);
        setnameTemplateFieldDisplayValues(newNameTemplateFieldDisplayValues);
      },
      [nameTemplateFieldDisplayValues, onNameTemplateFieldDisplayValuesChange],
    );

    if (!hasSetValues) {
      // (clewis): If we try to render before setting values, our field `value` types may be
      // initially undefined and out of sync with the declared types in lower components, which can
      // lead to errors that appear only at runtime.
      return null;
    }

    return (
      <>
        {rows.map(({ id, fieldInstance }, index) => {
          const isOptionalFieldInstance =
            getCustomFieldInstanceInputType(fieldInstance) === FieldInstanceValueInputType.OPTIONAL;

          // Render spectrum input.
          if (fieldInstance.spectrumFieldVersion != null) {
            const plugin = SpectrumFieldPluginRegistrar.getPluginForSpectrumField(fieldInstance.spectrumFieldVersion);
            if (plugin?.renderSpectrumFormField != null) {
              return plugin.renderSpectrumFormField(fieldInstance.field, {
                allowCreate: allowCreateUsers,
                controllerProps: {
                  control: form.control,
                  name: `customFields.${index}.value`,
                  rules: getAllValidationRulesFromFieldConstraints(
                    isFieldFormFieldRequired(fieldInstance.field) && !isOptionalFieldInstance,
                    fieldInstance.spectrumFieldVersion.fieldConstraints,
                  ),
                },
                description: fieldInstance.spectrumFieldVersion.description,
                infoTooltipIconName: "help-outline",
                infoTooltipVariant: "popover",
                isRequiredAsteriskShown: !isOptionalFieldInstance,
                labelPlacement,
                label: fieldInstance.spectrumFieldVersion?.name ?? EMPTY_STRING,
                labelWidth: LABEL_WIDTH,
                onChangeFinish: () => onValueChangeFinish(fieldInstance.field.id),
                onChangeStart: () => onValueChangeStart(fieldInstance.field.id),
                onValueChange: (_, newValue) => handleValueChange(fieldInstance, newValue),
                fieldInstance,
              });
            }
          }

          // Render legacy input.
          return (
            <React.Fragment key={id}>
              {CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(
                fieldInstance.field,
                {
                  allowCreate: allowCreateUsers,
                  controllerProps: {
                    control: form.control,
                    name: `customFields.${index}.value`,
                    rules: getAllValidationRulesFromFieldConstraints(
                      isFieldFormFieldRequired(fieldInstance.field) && !isOptionalFieldInstance,
                      fieldInstance.spectrumFieldVersion?.fieldConstraints ?? [],
                    ),
                  },
                  description: fieldInstance.spectrumFieldVersion?.description,
                  infoTooltipIconName: "help-outline",
                  infoTooltipVariant: "popover",
                  isRequiredAsteriskShown: !isOptionalFieldInstance,
                  labelPlacement,
                  label: fieldInstance.spectrumFieldVersion?.name ?? EMPTY_STRING,
                  labelWidth: LABEL_WIDTH,
                  onChangeFinish: () => onValueChangeFinish(fieldInstance.field.id),
                  onChangeStart: () => onValueChangeStart(fieldInstance.field.id),
                  onValueChange: (_, newValue) => handleValueChange(fieldInstance, newValue),
                  fieldInstance,
                },
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  },
);
