/** Returns the nearest scrollable ancestor. */
export function getScrollParentElement(element: HTMLElement | null): HTMLElement | null {
  if (element == null) {
    return null;
  }

  if (element.scrollHeight > element.clientHeight) {
    return element;
  }
  return getScrollParentElement(element.parentElement);
}
