// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { WorkflowTemplateFields } from '../../fragments/generated/WorkflowTemplateFields.generated';
import { PartyBaseFields } from '../../fragments/generated/PartyBaseFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { NameTemplateFields } from '../../fragments/generated/NameTemplateFields.generated';
import { WorkflowStageTemplateFields } from '../../fragments/generated/WorkflowStageTemplateFields.generated';
import { StartAfterWorkflowStageTemplateFields } from '../../fragments/generated/StartAfterWorkflowStageTemplateFields.generated';
import { WorkflowStageTemplateActionItemTemplateFields } from '../../fragments/generated/WorkflowStageTemplateActionItemTemplateFields.generated';
import { ActionItemTemplateFields } from '../../fragments/generated/ActionItemTemplateFields.generated';
import { ActionItemTemplateActionItemFields } from '../../fragments/generated/ActionItemTemplateActionItemFields.generated';
import { TagFields } from '../../fragments/generated/TagFields.generated';
import { TagTypeFields } from '../../fragments/generated/TagTypeFields.generated';
import { DocumentFields } from '../../fragments/generated/DocumentFields.generated';
import { DocumentVersionFields } from '../../fragments/generated/DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from '../../fragments/generated/WorkflowPermissionsFields.generated';
import { AutomationRequestFields } from '../../fragments/generated/AutomationRequestFields.generated';
import { AutomationOAuth2ConfigFields } from '../../fragments/generated/AutomationOAuth2ConfigFields.generated';
import { AutomationRequestParamFields } from '../../fragments/generated/AutomationRequestParamFields.generated';
import { FieldInstanceFields } from '../../fragments/generated/FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from '../../fragments/generated/FieldInstanceBaseFields.generated';
import { FieldFields } from '../../fragments/generated/FieldFields.generated';
import { PropertyTypeFields } from '../../fragments/generated/PropertyTypeFields.generated';
import { FieldUnitFields } from '../../fragments/generated/FieldUnitFields.generated';
import { RegrelloObjectFields } from '../../fragments/generated/RegrelloObjectFields.generated';
import { RegrelloObjectPropertyFields } from '../../fragments/generated/RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from '../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from '../../fragments/generated/FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from '../../fragments/generated/SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from '../../fragments/generated/SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from '../../fragments/generated/SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from '../../fragments/generated/SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from '../../fragments/generated/SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from '../../fragments/generated/SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from '../../fragments/generated/FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from '../../fragments/generated/FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from '../../fragments/generated/FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from '../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from '../../fragments/generated/FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from '../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from '../../fragments/generated/FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from '../../fragments/generated/FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from '../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from '../../fragments/generated/FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from '../../fragments/generated/FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from '../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from '../../fragments/generated/FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from '../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from '../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from '../../fragments/generated/FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from '../../fragments/generated/FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from '../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from '../../fragments/generated/AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from '../../fragments/generated/AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from '../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from '../../fragments/generated/DocumentBaseFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from '../../fragments/generated/RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from '../../fragments/generated/FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from '../../fragments/generated/FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from '../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from '../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from '../../fragments/generated/FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from '../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from '../../fragments/generated/FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from '../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from '../../fragments/generated/FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from '../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated';
import { ActionItemApprovalFields } from '../../fragments/generated/ActionItemApprovalFields.generated';
import { ActionItemAuditHistoryFields } from '../../fragments/generated/ActionItemAuditHistoryFields.generated';
import { ActionItemAuditHistoryDeltaFields } from '../../fragments/generated/ActionItemAuditHistoryDeltaFields.generated';
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from '../../fragments/generated/ActionItemAuditHistoryDocumentRelatedRecordFields.generated';
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from '../../fragments/generated/ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated';
import { RootCommentFields } from '../../fragments/generated/RootCommentFields.generated';
import { CommentFields } from '../../fragments/generated/CommentFields.generated';
import { IntegrationAutomationInstanceFields } from '../../fragments/generated/IntegrationAutomationInstanceFields.generated';
import { IntegrationAutomationSummaryFields } from '../../fragments/generated/IntegrationAutomationSummaryFields.generated';
import { IntegrationAuthenticationFields } from '../../fragments/generated/IntegrationAuthenticationFields.generated';
import { IntegrationAutomationInputFields } from '../../fragments/generated/IntegrationAutomationInputFields.generated';
import { IntegrationAutomationOutputFields } from '../../fragments/generated/IntegrationAutomationOutputFields.generated';
import { ActionItemPermissionsFields } from '../../fragments/generated/ActionItemPermissionsFields.generated';
import { LineFields } from '../../fragments/generated/LineFields.generated';
import { ActionItemTemplatePermissionsFields } from '../../fragments/generated/ActionItemTemplatePermissionsFields.generated';
import { ActionItemTemplateStartAfterActionItemTemplateChain } from '../../fragments/generated/ActionItemTemplateStartAfterActionItemTemplateChain.generated';
import { ActionItemTemplateStartAfterActionItemTemplateFields } from '../../fragments/generated/ActionItemTemplateStartAfterActionItemTemplateFields.generated';
import { RejectActionFields } from '../../fragments/generated/RejectActionFields.generated';
import { ActionItemTemplateActionItemWithLinkedWorkflowFields } from '../../fragments/generated/ActionItemTemplateActionItemWithLinkedWorkflowFields.generated';
import { ApprovalActionItemTemplateWithActionItemFields } from '../../fragments/generated/ApprovalActionItemTemplateWithActionItemFields.generated';
import { ConditionalExpressionGroupFields } from '../../fragments/generated/ConditionalExpressionGroupFields.generated';
import { FieldInstanceConditionalExpressionFields } from '../../fragments/generated/FieldInstanceConditionalExpressionFields.generated';
import { FieldInstanceFieldsWithBaseValues } from '../../fragments/generated/FieldInstanceFieldsWithBaseValues.generated';
import { FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument, FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat, FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt, FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty, FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueBaseFieldsFieldInstanceMultiValueString, FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime, FieldInstanceValueBaseFieldsFieldInstanceValueBoolean, FieldInstanceValueBaseFieldsFieldInstanceValueFloat, FieldInstanceValueBaseFieldsFieldInstanceValueInt, FieldInstanceValueBaseFieldsFieldInstanceValueParty, FieldInstanceValueBaseFieldsFieldInstanceValueString, FieldInstanceValueBaseFieldsFieldInstanceValueTime } from '../../fragments/generated/FieldInstanceValueBaseFields.generated';
import { FieldInstanceValuePartyBaseFields } from '../../fragments/generated/FieldInstanceValuePartyBaseFields.generated';
import { FieldInstanceMultiValuePartyBaseFields } from '../../fragments/generated/FieldInstanceMultiValuePartyBaseFields.generated';
import { FieldInstanceMultiValueDocumentBaseFields } from '../../fragments/generated/FieldInstanceMultiValueDocumentBaseFields.generated';
import { WorkflowTemplatePermissionsFields } from '../../fragments/generated/WorkflowTemplatePermissionsFields.generated';
import { WorkflowTemplateCollaborationFields } from '../../fragments/generated/WorkflowTemplateCollaborationFields.generated';
import { PartyFields } from '../../fragments/generated/PartyFields.generated';
import { UserFields } from '../../fragments/generated/UserFields.generated';
import { UserFieldsWithoutRoles } from '../../fragments/generated/UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from '../../fragments/generated/AccessRoleFields.generated';
import { StarWithoutEntityFields } from '../../fragments/generated/StarWithoutEntityFields.generated';
import { WorkflowGraphFields } from '../../fragments/generated/WorkflowGraphFields.generated';
import { WorkflowGraphStageFields } from '../../fragments/generated/WorkflowGraphStageFields.generated';
import { GenericWorkflowStageFields } from '../../fragments/generated/GenericWorkflowStageFields.generated';
import { GenericWorkflowActionItemFields } from '../../fragments/generated/GenericWorkflowActionItemFields.generated';
import { WorkflowGraphActionItemFields } from '../../fragments/generated/WorkflowGraphActionItemFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from '../../fragments/generated/FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from '../../fragments/generated/AcyclicFieldInstanceValueFields.generated';
import { FieldInstanceValueBaseFields } from '../../fragments/generated/FieldInstanceValueBaseFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkflowTemplatesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  includeDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  tagIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  types?: Types.InputMaybe<Array<Types.WorkflowTemplateType>>;
  usingFieldIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  usingAutomationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type WorkflowTemplatesQuery = { workflowTemplates: Array<WorkflowTemplateFields> };


export const WorkflowTemplatesQueryDocument = gql`
    query WorkflowTemplatesQuery($id: ID, $includeDeleted: Boolean, $tagIds: [ID!], $types: [WorkflowTemplateType!], $usingFieldIds: [ID!], $usingAutomationIds: [ID!]) {
  workflowTemplates(
    id: $id
    includeDeleted: $includeDeleted
    tagIds: $tagIds
    types: $types
    usingFieldIds: $usingFieldIds
    usingAutomationIds: $usingAutomationIds
  ) {
    ...WorkflowTemplateFields
  }
}
    ${WorkflowTemplateFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${NameTemplateFields}
${WorkflowStageTemplateFields}
${StartAfterWorkflowStageTemplateFields}
${WorkflowStageTemplateActionItemTemplateFields}
${ActionItemTemplateFields}
${ActionItemTemplateActionItemFields}
${TagFields}
${TagTypeFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${AutomationRequestFields}
${AutomationOAuth2ConfigFields}
${AutomationRequestParamFields}
${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${ActionItemApprovalFields}
${ActionItemAuditHistoryFields}
${ActionItemAuditHistoryDeltaFields}
${ActionItemAuditHistoryDocumentRelatedRecordFields}
${ActionItemAuditHistoryFieldInstanceRelatedRecordFields}
${RootCommentFields}
${CommentFields}
${IntegrationAutomationInstanceFields}
${IntegrationAutomationSummaryFields}
${IntegrationAuthenticationFields}
${IntegrationAutomationInputFields}
${IntegrationAutomationOutputFields}
${ActionItemPermissionsFields}
${LineFields}
${ActionItemTemplatePermissionsFields}
${ActionItemTemplateStartAfterActionItemTemplateChain}
${ActionItemTemplateStartAfterActionItemTemplateFields}
${RejectActionFields}
${ActionItemTemplateActionItemWithLinkedWorkflowFields}
${ApprovalActionItemTemplateWithActionItemFields}
${ConditionalExpressionGroupFields}
${FieldInstanceConditionalExpressionFields}
${FieldInstanceFieldsWithBaseValues}
${FieldInstanceValueBaseFields}
${FieldInstanceValuePartyBaseFields}
${FieldInstanceMultiValuePartyBaseFields}
${FieldInstanceMultiValueDocumentBaseFields}
${WorkflowTemplatePermissionsFields}
${WorkflowTemplateCollaborationFields}
${PartyFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${StarWithoutEntityFields}
${WorkflowGraphFields}
${WorkflowGraphStageFields}
${GenericWorkflowStageFields}
${GenericWorkflowActionItemFields}
${WorkflowGraphActionItemFields}`;

/**
 * __useWorkflowTemplatesQuery__
 *
 * To run a query within a React component, call `useWorkflowTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowTemplatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeDeleted: // value for 'includeDeleted'
 *      tagIds: // value for 'tagIds'
 *      types: // value for 'types'
 *      usingFieldIds: // value for 'usingFieldIds'
 *      usingAutomationIds: // value for 'usingAutomationIds'
 *   },
 * });
 */
export function useWorkflowTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>(WorkflowTemplatesQueryDocument, options);
      }
export function useWorkflowTemplatesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>(WorkflowTemplatesQueryDocument, options);
        }
export function useWorkflowTemplatesQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>(WorkflowTemplatesQueryDocument, options);
        }
export type WorkflowTemplatesQueryHookResult = ReturnType<typeof useWorkflowTemplatesQuery>;
export type WorkflowTemplatesQueryLazyQueryHookResult = ReturnType<typeof useWorkflowTemplatesQueryLazyQuery>;
export type WorkflowTemplatesQuerySuspenseQueryHookResult = ReturnType<typeof useWorkflowTemplatesQuerySuspenseQuery>;
export type WorkflowTemplatesQueryResult = Apollo.QueryResult<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>;