import { cva } from "class-variance-authority";

import { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";

export const chipVariants = cva<{
  intent: Record<RegrelloIntent, string>;
  size: Record<Extract<RegrelloSize, "medium" | "small" | "x-small">, string>;
  isClickable: {
    true: string;
    false: string;
  };
  isDisabled: {
    true: string;
    false: string;
  };
  isDeletable: {
    true: string;
    false: string;
  };
  isSelected: {
    true: string;
    false: string;
  };
}>(
  `
  inline-flex
  items-center
  gap-1

  w-auto
  min-w-0

  rounded
  box-inset

  cursor-default
  `,
  {
    variants: {
      intent: {
        neutral: "shadow-neutral-borderInteractive bg-backgroundSoft text-neutral-text",
        primary: "shadow-primary-borderInteractive bg-primary-soft text-primary-text",
        secondary: "shadow-secondary-borderInteractive bg-secondary-soft text-secondary-text",
        success: "shadow-success-borderInteractive bg-success-soft text-success-text",
        warning: "shadow-warning-borderInteractive bg-warning-soft text-warning-text",
        danger: "shadow-danger-borderInteractive bg-danger-soft text-danger-text",
      },
      size: {
        [RegrelloSize.MEDIUM]: "px-2 py-1 text-sm",
        [RegrelloSize.SMALL]: "px-1.5 py-0.5 text-xs",
        [RegrelloSize.X_SMALL]: "px-1 py-0 text-xs",
      },
      isClickable: {
        true: `
          cursor-pointer

          focus-visible:outline-3
          focus-visible:-outline-offset-1
          focus-visible:outline-primary-solid
        `,
        false: "",
      },
      isDeletable: {
        true: "pr-6",
        false: "",
      },
      isDisabled: {
        true: "opacity-50 cursor-not-allowed",
        false: "",
      },
      isSelected: {
        true: "", // (clewis): Will only be applicable if isDisabled is false.
        false: "",
      },
    },
    compoundVariants: [
      {
        isDisabled: false,
        isSelected: true,
        class:
          // (clewis): Make it appear focused without actually being focused.
          "outline-solid outline-2 outline-offset--2 outline-primary-solid focus-visible:outline-width-2 focus-visible:outline-offset--2",
      },
      {
        isClickable: true,
        isDisabled: false,
        intent: "neutral",
        class:
          "hover:shadow-neutral-borderInteractiveHovered hover:bg-neutral-softHovered active:bg-neutral-softPressed",
      },
      {
        isClickable: true,
        isDisabled: false,
        intent: "primary",
        class:
          "hover:shadow-primary-borderInteractiveHovered hover:bg-primary-softHovered active:bg-primary-softPressed",
      },
      {
        isClickable: true,
        isDisabled: false,
        intent: "secondary",
        class:
          "hover:shadow-secondary-borderInteractiveHovered hover:bg-secondary-softHovered active:bg-secondary-softPressed",
      },
      {
        isClickable: true,
        isDisabled: false,
        intent: "success",
        class:
          "hover:shadow-success-borderInteractiveHovered hover:bg-success-softHovered active:bg-success-softPressed",
      },
      {
        isClickable: true,
        isDisabled: false,
        intent: "warning",
        class:
          "hover:shadow-warning-borderInteractiveHovered hover:bg-warning-softHovered active:bg-warning-softPressed",
      },
      {
        isClickable: true,
        isDisabled: false,
        intent: "danger",
        class: "hover:shadow-danger-borderInteractiveHovered hover:bg-danger-softHovered active:bg-danger-softPressed",
      },
      {
        isDeletable: true,
        isDisabled: false,
        size: "x-small",
        class: "pr-5.5",
      },
      {
        isDeletable: true,
        isDisabled: false,
        size: "small",
        class: "pr-5.5",
      },
    ],
    defaultVariants: {
      intent: "neutral",
      isClickable: false,
      isSelected: false,
      size: RegrelloSize.MEDIUM,
    },
  },
);

export const chipDeleteButtonVariants = cva<{
  intent: Record<RegrelloIntent, string>;
  size: Record<Extract<RegrelloSize, "medium" | "small" | "x-small">, string>;
}>(
  `
  rounded-circular

  absolute
  top-1/2
  translate-y--1/2
  `,
  {
    variants: {
      intent: {
        neutral: "text-textDefault hover:bg-neutral-softHovered active:bg-neutral-softPressed",
        primary: "text-primary-textMuted hover:bg-primary-softHovered active:bg-primary-softPressed",
        secondary: "text-secondary-textMuted hover:bg-primary-softHovered active:bg-primary-softPressed",
        success: "text-success-textMuted hover:bg-success-softHovered active:bg-success-softPressed",
        warning: "text-warning-textMuted hover:bg-warning-softHovered active:bg-warning-softPressed",
        danger: "text-danger-textMuted hover:bg-danger-softHovered active:bg-danger-softPressed",
      },
      size: {
        "x-small": "right-1",
        small: "right-1",
        medium: "right-1.5",
      },
    },
    defaultVariants: {
      intent: "neutral",
    },
  },
);

export function getChipIconColorCssClass(intent: RegrelloIntent): string {
  /* eslint-disable lingui/no-unlocalized-strings */
  switch (intent) {
    case RegrelloIntent.PRIMARY:
      return "text-primary-icon";
    case RegrelloIntent.SECONDARY:
      return "text-secondary-icon";
    case RegrelloIntent.SUCCESS:
      return "text-onTrack-icon";
    case RegrelloIntent.WARNING:
      return "text-warning-icon";
    case RegrelloIntent.DANGER:
      return "text-danger-icon";
    default:
      return "text-neutral-icon";
  }
  /* eslint-enable lingui/no-unlocalized-strings */
}
