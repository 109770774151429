import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import type { PopoverContentProps } from "@radix-ui/react-popover";
import { clsx, EMPTY_STRING, noop, type WithDataTestId } from "@regrello/core-utils";
import React, { useEffect } from "react";

import { RegrelloMenuV2ItemContent, type RegrelloMenuV2ItemContentProps } from "./_internal/RegrelloMenuV2ItemContent";
import { RegrelloMenuV2Shortcut } from "./_internal/RegrelloMenuV2Shortcut";
import { menuItemSeparatorClasses, menuItemVariants } from "./_internal/regrelloMenuV2Utils";
import { RegrelloIcon } from "../icons/RegrelloIcon";
import { RegrelloLinkV2 } from "../link/RegrelloLinkV2";

// (clewis): I tried for a while to conditionally hide a <RegrelloIcon iconName="blank" /> in radio
// groups if the parent had [data-state="checked"], following the Tailwind documentation, but for
// some reason nothing I tried was working:
//
// - hidden didn't generate "display: none" as expected
// - group-data-[state=checked]:hidden or any variant of that syntax didn't work
// - peer-data-[state=checked]:hidden or any variant of that syntax didn't work
const SMALL_ICON_WIDTH_CLASS = "w-5";

const RegrelloMenuV2RadioGroup = DropdownMenuPrimitive.RadioGroup;

export interface RegrelloMenuV2Props extends React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root> {
  /** Where along the specified {@link side} of the target to align the menu. */
  align?: PopoverContentProps["align"];

  /** The element that should open the menu when clicked. */
  children: React.ReactNode;

  /** The content to display in the menu. */
  content: React.ReactNode;

  /** Props to pass to the `content` element. */
  contentProps?: Omit<React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>, "align" | "side">;

  /** The side of the target on which to align the menu content. */
  side?: PopoverContentProps["side"];

  /** Props to pass to the trigger element. */
  triggerProps?: React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>;
}

const RegrelloMenuV2 = React.memo<RegrelloMenuV2Props>(
  ({ align, children, content, contentProps, side, triggerProps, ...props }) => {
    return (
      <DropdownMenuPrimitive.Root {...props}>
        <DropdownMenuPrimitive.Trigger asChild={true} {...triggerProps}>
          {children}
        </DropdownMenuPrimitive.Trigger>
        <RegrelloMenuV2Content align={align} side={side} {...contentProps}>
          {content}
        </RegrelloMenuV2Content>
      </DropdownMenuPrimitive.Root>
    );
  },
);
RegrelloMenuV2.displayName = "RegrelloMenuV2";

const RegrelloMenuV2ItemWithSubMenu = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> &
    Omit<RegrelloMenuV2ItemContentProps, "endElement"> & {
      /** The content to show in the submenu. */
      submenuContent?: React.ReactNode;
    }
>(({ className, icon, inset, intent, secondaryText, selected: isSelected, submenuContent, text, ...props }, ref) => (
  <DropdownMenuPrimitive.Sub>
    <DropdownMenuPrimitive.SubTrigger
      ref={ref}
      className={clsx(menuItemVariants({ intent, isDisabled: props.disabled, isSelected }), className)}
      {...props}
    >
      <RegrelloMenuV2ItemContent
        endElement={<RegrelloIcon iconName="arrow-forward-small" intent={intent} />}
        icon={icon}
        inset={inset}
        intent={intent}
        secondaryText={secondaryText}
        text={text}
      />
    </DropdownMenuPrimitive.SubTrigger>
    <DropdownMenuPrimitive.Portal>
      <RegrelloMenuV2SubContent>{submenuContent}</RegrelloMenuV2SubContent>
    </DropdownMenuPrimitive.Portal>
  </DropdownMenuPrimitive.Sub>
));
RegrelloMenuV2ItemWithSubMenu.displayName = "RegrelloMenuV2ItemWithSubMenu";

const RegrelloMenuV2SubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={clsx(
      "z-tooltip min-w-32 overflow-auto rounded border bg-background p-1 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className,
    )}
    style={{
      maxHeight: "var(--radix-dropdown-menu-content-available-height)",
      maxWidth: "var(--radix-dropdown-menu-content-available-width)",
    }}
    {...props}
  />
));
RegrelloMenuV2SubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

/** The content to display in the menu popover. */
export const RegrelloMenuV2Content = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      className={clsx(
        `
        z-tooltip
        shadow-md

        min-w-50
        p-1
        rounded
        border
        bg-background

        data-[state=open]:animate-in
        data-[state=open]:fade-in-0
        data-[state=open]:zoom-in-95

        data-[state=closed]:animate-out
        data-[state=closed]:fade-out-0
        data-[state=closed]:zoom-out-95

        data-[side=bottom]:slide-in-from-top-2
        data-[side=left]:slide-in-from-right-2
        data-[side=right]:slide-in-from-left-2
        data-[side=top]:slide-in-from-bottom-2
        `,

        // (clewis): Allow users to scroll through menu content if the menu height exceeds the
        // viewport height.
        `
        overflow-auto
        `,
        className,
      )}
      sideOffset={sideOffset}
      style={{
        maxHeight: "var(--radix-dropdown-menu-content-available-height)",
        maxWidth: "var(--radix-dropdown-menu-content-available-width)",
      }}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
RegrelloMenuV2Content.displayName = DropdownMenuPrimitive.Content.displayName;

const RegrelloMenuV2Item = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> &
    WithDataTestId &
    Omit<RegrelloMenuV2ItemContentProps, "endElement"> & {
      /** An optional keyboard shortcut to display to the right of the main `text`. */
      shortcut?: React.ReactNode;
    }
>(
  (
    {
      className,
      dataTestId,
      disabled: isDisabled,
      icon,
      inset = false,
      intent,
      secondaryText,
      selected: isSelected,
      shortcut,
      text,
      tooltip,
      onClick,
      ...props
    },
    ref,
  ) => {
    useEffect(() => {
      if (icon != null && inset) {
        console.warn("[RegrelloMenuItemV2] Do not pass `inset={true}` when an `icon` is also provided.");
      }
    }, [icon, inset]);

    return (
      <DropdownMenuPrimitive.Item
        ref={ref}
        className={clsx(menuItemVariants({ intent, isDisabled, isSelected }), className)}
        data-testid={dataTestId ?? EMPTY_STRING}
        disabled={isDisabled}
        // (dosipiuk): This is a fix specific for Firefox as it fails to disable the handler
        onClick={isDisabled ? noop : onClick}
        {...props}
      >
        <RegrelloMenuV2ItemContent
          endElement={shortcut != null && <RegrelloMenuV2Shortcut intent={intent}>{shortcut}</RegrelloMenuV2Shortcut>}
          icon={icon}
          inset={inset}
          intent={intent}
          secondaryText={secondaryText}
          text={text}
          tooltip={tooltip}
        />
      </DropdownMenuPrimitive.Item>
    );
  },
);
RegrelloMenuV2Item.displayName = DropdownMenuPrimitive.Item.displayName;

const RegrelloMenuV2LinkItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> &
    Omit<RegrelloMenuV2ItemContentProps, "endElement"> &
    WithDataTestId & {
      /** An optional keyboard shortcut to display to the right of the main `text`. */
      shortcut?: string;
      to: string;
      state?: Record<string, unknown>;
    }
>(
  (
    {
      className,
      dataTestId,
      icon,
      inset = false,
      intent,
      secondaryText,
      selected: isSelected,
      shortcut,
      text,
      to,
      state,
      ...props
    },
    ref,
  ) => {
    useEffect(() => {
      if (icon != null && inset) {
        console.warn("[RegrelloMenuItemV2] Do not pass `inset={true}` when an `icon` is also provided.");
      }
    }, [icon, inset]);

    return (
      <DropdownMenuPrimitive.Item
        ref={ref}
        className={clsx(menuItemVariants({ intent, isDisabled: props.disabled, isSelected }), className)}
        {...props}
      >
        <RegrelloLinkV2 className="text-inherit hover:no-underline" dataTestId={dataTestId} state={state} to={to}>
          <RegrelloMenuV2ItemContent
            endElement={shortcut != null && <RegrelloMenuV2Shortcut intent={intent}>{shortcut}</RegrelloMenuV2Shortcut>}
            icon={icon}
            inset={inset}
            intent={intent}
            secondaryText={secondaryText}
            text={text}
          />
        </RegrelloLinkV2>
      </DropdownMenuPrimitive.Item>
    );
  },
);
RegrelloMenuV2LinkItem.displayName = DropdownMenuPrimitive.Item.displayName;

const RegrelloMenuV2CheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem> &
    Omit<RegrelloMenuV2ItemContentProps, "endElement" | "icon" | "inset"> & {
      /** An optional keyboard shortcut to display to the right of the main `text`. */
      shortcut?: string;
    }
>(({ checked, className, intent, secondaryText, selected: isSelected, text, shortcut, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    checked={checked}
    className={clsx(menuItemVariants({ intent, isDisabled: props.disabled, isSelected }), className)}
    {...props}
  >
    <RegrelloMenuV2ItemContent
      endElement={shortcut != null && <RegrelloMenuV2Shortcut intent={intent}>{shortcut}</RegrelloMenuV2Shortcut>}
      icon={
        checked ? (
          <DropdownMenuPrimitive.ItemIndicator>
            <RegrelloIcon iconName="selected" />
          </DropdownMenuPrimitive.ItemIndicator>
        ) : (
          <RegrelloIcon iconName="blank" />
        )
      }
      intent={intent}
      secondaryText={secondaryText}
      text={text}
    />
  </DropdownMenuPrimitive.CheckboxItem>
));
RegrelloMenuV2CheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

const RegrelloMenuV2RadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem> &
    Omit<RegrelloMenuV2ItemContentProps, "endElement" | "icon" | "inset"> & {
      /** An optional keyboard shortcut to display to the right of the main `text`. */
      shortcut?: string;
    }
>(({ className, intent, secondaryText, selected: isSelected, shortcut, text, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={clsx(menuItemVariants({ intent, isDisabled: props.disabled, isSelected }), className)}
    {...props}
  >
    <RegrelloMenuV2ItemContent
      endElement={shortcut != null && <RegrelloMenuV2Shortcut intent={intent}>{shortcut}</RegrelloMenuV2Shortcut>}
      icon={
        <div className={SMALL_ICON_WIDTH_CLASS}>
          <DropdownMenuPrimitive.ItemIndicator className="peer">
            <RegrelloIcon iconName="dot" intent={intent} />
          </DropdownMenuPrimitive.ItemIndicator>
        </div>
      }
      intent={intent}
      secondaryText={secondaryText}
      text={text}
    />
  </DropdownMenuPrimitive.RadioItem>
));
RegrelloMenuV2RadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

const RegrelloMenuV2Label = React.memo(
  React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label>
  >(function RegrelloMenuV2LabelFn({ className, ...props }, ref) {
    return (
      <DropdownMenuPrimitive.Label ref={ref} className={clsx("p-2 text-sm font-semibold", className)} {...props} />
    );
  }),
);

const RegrelloMenuV2Separator = React.memo(
  React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
  >(function RegrelloMenuV2SeparatorFn({ className, ...props }, ref) {
    return (
      <DropdownMenuPrimitive.Separator ref={ref} className={clsx(menuItemSeparatorClasses, className)} {...props} />
    );
  }),
);

const RegrelloMenuV2Group = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Group> & {
    /** The content to show in the group. */
    children: React.ReactNode;

    /**
     * Whether to show a separator above this group.
     * @default false
     */
    showSeparator?: boolean;

    /** An optional title to display for this group. */
    title?: string;

    /** Class name to be attached to group title */
    titleProps?: {
      className?: string;
      "data-testid"?: string;
    };
  }
>(({ children, showSeparator = false, title, titleProps, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.Group ref={ref} {...props}>
      {showSeparator ? <RegrelloMenuV2Separator /> : null}
      {title != null ? <RegrelloMenuV2Label {...titleProps}>{title}</RegrelloMenuV2Label> : null}
      {children}
    </DropdownMenuPrimitive.Group>
  );
});
RegrelloMenuV2Group.displayName = "RegrelloMenuV2Group";

const RegrelloMenuV2Root = DropdownMenuPrimitive.Root;
const RegrelloMenuV2Trigger = DropdownMenuPrimitive.Trigger;

export {
  RegrelloMenuV2,
  RegrelloMenuV2CheckboxItem,
  RegrelloMenuV2Group,
  RegrelloMenuV2Item,
  RegrelloMenuV2ItemContent,
  RegrelloMenuV2ItemWithSubMenu,
  RegrelloMenuV2Label,
  RegrelloMenuV2LinkItem,
  RegrelloMenuV2RadioGroup,
  RegrelloMenuV2RadioItem,
  RegrelloMenuV2Root,
  RegrelloMenuV2Separator,
  RegrelloMenuV2Trigger,
};
