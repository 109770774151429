import type {
  AccessRoleFields,
  CreateDocumentMutation,
  CurrentUserQuery,
  FieldFields,
  FieldInstanceValueBaseFields,
  Maybe,
  UserAccessLevel,
  UserPermissionsToWorkspace,
} from "@regrello/graphql-api";
import type { LanguageCode } from "@regrello/i18n-llm-translator";

// (clewis): The following are more readable shorthand types derived from generated GraphQL types.
// The primary tools for constructing these types include:
//
// - NonNullable: For stripping away 'Maybe<...>' and '| undefined'
// - Foo["someKey"]: For extracting the value type for a particular object key
// - Foo[number]: For extracting the element type from an array
//
// These three tools can be composed as needed so that the frontend can "think" in clearer types.
// For full commentary on our GraphQL Code Generator, see the comments in
// workspace/libs/graphql-api/src/config/codegen.ts.

export type CreatedDocument = NonNullable<CreateDocumentMutation["createDocument"]>;

export type CurrentUser = NonNullable<CurrentUserQuery["currentUser"]>;

export interface UrlParamTypes {
  [key: string]: string;
  id: string;
  tagType: string;
  tagId: string;
  name: string;
}

/**
 * Describes the "place" in which a workflow-related element is displayed. Useful for rendering or
 * behaving slightly differently based solely on where an element is rendered (e.g., a form dialog
 * that can adjust field order or behavior based on display context).
 */
export enum WorkflowContext {
  WORKFLOW_TEMPLATE = "workflowTemplate",
  WORKFLOW_NOT_STARTED = "workflowNotStarted",
  WORKFLOW_STARTED = "workflowStarted",
}

export enum DocumentTableContext {
  ACTION_ITEM_TABLE = "actionItemTable",
  WORKFLOW = "workflow",
  WORKFLOW_TEMPLATE = "workflowTemplate",
}

export type CurrentUserContext = {
  id: number;
  name: string;

  /**
   * The name of the user who invited the current user to this workspace, or their email if they
   * have no name set.
   */
  createdByUserName: string;

  email: string;
  partyId: number;
  isAdmin: boolean;
  isAuthAdmin: boolean;
  isAuthWorkspaceCreator: boolean;
  isPendingSignUp: boolean;
  isRegrelloEmployee: boolean;
  isVerified: boolean;
  accessLevel: UserAccessLevel;
  accessRole: AccessRoleFields | undefined;
  isAllowedToCreateTeams: boolean;
  tenantId: number;
  tenantName: string;
  tenantDisplayName: string;
  isLoggedIn: boolean;
  permissions: UserPermissionsToWorkspace;
  language?: LanguageCode;
};

export type FieldInstanceBaseFields = {
  field: FieldFields;
  values: FieldInstanceValueBaseFields[];
  isMultiValued?: Maybe<boolean>;
  projection?: Maybe<{ selectedRegrelloObjectPropertyIds?: Maybe<number[]> }>;
};
