import { t } from "@lingui/macro";
import { ConditionOperator, FieldInstanceValueInputType } from "@regrello/graphql-api";

import type { ConditionOperatorConfig } from "../types/ConditionOperator";

export const DEFAULT_INPUT_TYPE_IF_NO_VALUE: FieldInstanceValueInputType = FieldInstanceValueInputType.REQUESTED;

const conditionOperatorConfigConstructor = (displayValue: string, operator: ConditionOperator, numFields: number) => {
  return {
    displayValue,
    operator,
    numFields,
  };
};

const getConditionOperatorConfigEmpty = () => {
  return conditionOperatorConfigConstructor(t`Is empty`, ConditionOperator.EMPTY, 0);
};
const getConditionOperatorConfigNotEmpty = () => {
  return conditionOperatorConfigConstructor(t`Is not empty`, ConditionOperator.NOT_EMPTY, 0);
};
const getConditionOperatorConfigGreaterThanOrEquals = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.GREATER_THAN_OR_EQUALS, 1);
};
const getConditionOperatorConfigLessThanOrEquals = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.LESS_THAN_OR_EQUALS, 1);
};
const getConditionOperatorConfigEquals = (displayValue: string, excludeFormField?: boolean) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.EQUALS, excludeFormField ? 0 : 1);
};
const getConditionOperatorConfigNotEquals = (displayValue: string, excludeFormField?: boolean) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.NOT_EQUALS, excludeFormField ? 0 : 1);
};
const getConditionOperatorBetween = () => {
  return conditionOperatorConfigConstructor(t`Is between`, ConditionOperator.BETWEEN, 2);
};
const getConditionOperatorConfigContainsAnyOf = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.CONTAINS_ANY_OF, 1);
};
const getConditionOperatorConfigContainsNoneOf = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.CONTAINS_NONE_OF, 1);
};
const getConditionOperatorConfigContainsAllOf = () => {
  return conditionOperatorConfigConstructor(t`Contains all of`, ConditionOperator.CONTAINS_ALL_OF, 1);
};

const CONDITION_OPERATOR_CONFIGS_CHECKBOX = () => [
  getConditionOperatorConfigEquals(t`Is checked`, true),
  getConditionOperatorConfigNotEquals(t`Is not checked`, true),
];

const CONDITION_OPERATOR_CONFIGS_CURRENCY = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigGreaterThanOrEquals(t`Is greater than or equal to`),
  getConditionOperatorConfigLessThanOrEquals(t`Is less than or equal to`),
  getConditionOperatorConfigEquals(t`Is equal to`),
  getConditionOperatorConfigNotEquals(t`Is not equal to`),
  getConditionOperatorBetween(),
];

const CONDITION_OPERATOR_CONFIGS_DATE = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigGreaterThanOrEquals(t`Is after or on`),
  getConditionOperatorConfigLessThanOrEquals(t`Is before or on`),
  getConditionOperatorConfigEquals(t`Is on same calendar day`),
  getConditionOperatorBetween(),
];

const CONDITION_OPERATOR_CONFIGS_MULTISELECT = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigContainsAnyOf(t`Contains`),
  getConditionOperatorConfigContainsNoneOf(t`Does not contain`),
  getConditionOperatorConfigContainsAllOf(),
];

const CONDITION_OPERATOR_CONFIGS_NUMBER = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigGreaterThanOrEquals(t`Is greater than or equal to`),
  getConditionOperatorConfigLessThanOrEquals(t`Is less than or equal to`),
  getConditionOperatorConfigEquals(t`Is equal to`),
  getConditionOperatorConfigNotEquals(t`Is not equal to`),
  getConditionOperatorBetween(),
];

const CONDITION_OPERATOR_CONFIGS_REGRELLO_OBJECT = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigEquals(t`Is equal to`),
  getConditionOperatorConfigNotEquals(t`Is not equal to`),
  getConditionOperatorConfigContainsAnyOf(t`Contains`),
  getConditionOperatorConfigContainsNoneOf(t`Does not contain`),
];

const CONDITION_OPERATOR_CONFIGS_SELECT = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigEquals(t`Is equal to`),
  getConditionOperatorConfigNotEquals(t`Is not equal to`),
  getConditionOperatorConfigContainsAnyOf(t`Is in`),
  getConditionOperatorConfigContainsNoneOf(t`Is not in`),
];

const CONDITION_OPERATOR_CONFIGS_TEXT = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigEquals(t`Is equal to`),
  getConditionOperatorConfigNotEquals(t`Is not equal to`),
  getConditionOperatorConfigContainsAnyOf(t`Contains`),
  getConditionOperatorConfigContainsNoneOf(t`Does not contain`),
];

const CONDITION_OPERATOR_CONFIGS_USER = () => [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigContainsAnyOf(t`Contains`),
  getConditionOperatorConfigContainsNoneOf(t`Does not contain`),
  getConditionOperatorConfigContainsAllOf(),
];

const CONDITION_OPERATOR_MAP: Record<string, () => ConditionOperatorConfig[]> = {
  CHECKBOX: CONDITION_OPERATOR_CONFIGS_CHECKBOX,
  CURRENCY: CONDITION_OPERATOR_CONFIGS_CURRENCY,
  DATE: CONDITION_OPERATOR_CONFIGS_DATE,
  MULTISELECT: CONDITION_OPERATOR_CONFIGS_MULTISELECT,
  NUMBER: CONDITION_OPERATOR_CONFIGS_NUMBER,
  REGRELLO_OBJECT: CONDITION_OPERATOR_CONFIGS_REGRELLO_OBJECT,
  SELECT: CONDITION_OPERATOR_CONFIGS_SELECT,
  TEXT: CONDITION_OPERATOR_CONFIGS_TEXT,
  USER: CONDITION_OPERATOR_CONFIGS_USER,
};

export function getConditionOperatorsByType(key: keyof typeof CONDITION_OPERATOR_MAP) {
  return CONDITION_OPERATOR_MAP[key]();
}
