import { clsx, type WithDataTestId } from "@regrello/core-utils";
import type { VariantProps } from "class-variance-authority";
import React, { useMemo } from "react";

import { chipDeleteButtonVariants, chipVariants, getChipIconColorCssClass } from "./regrelloChipUtils";
import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import type { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloAvatar, type RegrelloAvatarProps } from "../avatar/RegrelloAvatar";
import { RegrelloIcon, type RegrelloIconName } from "../icons/RegrelloIcon";
import { RegrelloLinkV2 } from "../link/RegrelloLinkV2";
import { RegrelloTooltippedText } from "../tooltippedText/RegrelloTooltippedText";

export type RegrelloChipProps = React.PropsWithChildren<
  Omit<VariantProps<typeof chipVariants>, "intent" | "isDeletable" | "isDisabled" | "isSelected" | "size"> &
    WithDataTestId & {
      /**
       * Whether the chip is non-interactive. When `true`, the chip will not be clickable or
       * deleteable or selectable.
       * @default false
       */
      disabled?: boolean;

      /**
       * Whether to disable the `min-width: 0` style that is imposed by default on chips. Set to
       * `true` if and only if you want to display chips inline without having chips shrink.
       * @default false
       */
      disableShrinking?: boolean;

      /**
       * Whether to disable the tooltip that would display the full text of the chip if the text
       * overflows the available width.
       * @default false
       */
      disabledOverflowTooltip?: boolean;

      /** An icon to show before the text. */
      icon?:
        | {
            type: "iconName";
            iconName: RegrelloIconName;
          }
        | {
            type: "avatar";
            avatarProps: Pick<RegrelloAvatarProps, "scope" | "shape" | "visual">;
          }
        | {
            type: "custom";
            element: React.ReactElement;
          };

      /**
       * The semantic intent of this chip
       * @default RegrelloIntent.NEUTRAL
       */
      intent?: RegrelloIntent;

      /**
       * Whether the visible chip element should expand to fill the size of its parent.
       * @default false
       */
      isVisibleChipFullSize?: boolean;

      /**
       * Callback to invoke when the user clicks a delete button. The delete button is not shown unless
       * this prop is provided.
       */
      onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;

      /**
       * Whether the current chip is viusually highlighted. When selected, the chip will have a 1px
       * primary-colored border.
       */
      selected?: boolean;

      /**
       * The size of this chip.
       * @default "medium"
       */
      size?: Extract<RegrelloSize, "x-small" | "small" | "medium">;

      /**
       * Whether to include this chip's delete button in the keyboard-accessible Tab ordering for this
       * page. It may be useful to set this to `-1` (off) when this chip is shown within a
       * {@link RegrelloChipInput}, because that component provides alternate keyboard navigation for
       * selecting and deleting selected chips.
       *
       * @default undefined // Included in the tab order
       **/
      tabIndexForDeleteButton?: -1 | undefined;

      /**
       * The class name to provide to the visible chip element. (By default, `className` will be
       * applied to a wrapper element.)
       */
      visibleChipClassName?: string;
    } & (
      | Pick<HTMLAnchorElement, "className" | "href" | "rel" | "target">
      | (React.HTMLAttributes<HTMLDivElement | HTMLButtonElement> & {
          /**
           * Callback to invoke when the user clicks the chip. The chip will not be clickable unless
           * this prop is provided.
           */
          onClick?: () => void;
        })
    )
>;

/**
 * A chip is a lightweight visual container for a short string of text. It can be used to represent
 * an entity, label an entity, display a list of selected items, and much more.
 */
export const RegrelloChip = React.memo<RegrelloChipProps>(function RegrelloChipFn({
  children,
  className,
  dataTestId,
  disabled: isDisabled = false,
  disableShrinking = false,
  disabledOverflowTooltip = false,
  icon,
  intent = "neutral",
  isVisibleChipFullSize = false,
  onDelete,
  selected: isSelected = false,
  size = "medium",
  tabIndexForDeleteButton = undefined,
  visibleChipClassName,
  ...props
}) {
  const tooltipProps = useMemo(() => ({ disabled: disabledOverflowTooltip }), [disabledOverflowTooltip]);
  const isClickable = ("onClick" in props && props.onClick != null) || "href" in props;
  const isDeletable = onDelete != null && !isDisabled;

  const Comp = isClickable ? "button" : "div";

  const resolvedIcon = useMemo(() => {
    if (icon == null) {
      return null;
    }

    let iconElement: React.ReactElement;
    switch (icon.type) {
      case "iconName":
        iconElement = (
          <RegrelloIcon
            className={getChipIconColorCssClass(intent)}
            iconName={icon.iconName}
            size={size === "medium" ? "small" : "x-small"}
          />
        );
        break;
      case "avatar":
        iconElement = <RegrelloAvatar {...icon.avatarProps} size={size === "medium" ? "small" : "x-small"} />;
        break;
      default:
        iconElement = icon.element;
    }

    return <div className="mr-0.5 flex-none">{iconElement}</div>;
  }, [icon, intent, size]);

  const child = useMemo(() => {
    const childBaseProps = {
      className: clsx(
        chipVariants({ isClickable, isDeletable, isDisabled, isSelected, intent, size }),
        { "w-full h-full": isVisibleChipFullSize },
        visibleChipClassName,
      ),
      // (clewis): I believe this prop will automatically prevent onClick or href from firing:
      disabled: isDisabled,
    };

    const childContent = (
      <>
        {resolvedIcon}
        {
          // (wsheehan): When RegrelloChip is the child of a tooltip, this tooltipped text
          // interferes with the hover event.
          disabledOverflowTooltip ? (
            children
          ) : (
            <RegrelloTooltippedText tooltipProps={tooltipProps}>{children}</RegrelloTooltippedText>
          )
        }
      </>
    );

    if ("href" in props && props.href != null) {
      return (
        <RegrelloLinkV2 {...childBaseProps} rel={props.rel} target={props.target} to={props.href}>
          {childContent}
        </RegrelloLinkV2>
      );
    }

    return (
      <Comp
        {...childBaseProps}
        onClick={"onClick" in props ? props.onClick : undefined}
        type={"onClick" in props && props.onClick != null ? "button" : undefined}
      >
        {childContent}
      </Comp>
    );
  }, [
    Comp,
    children,
    disabledOverflowTooltip,
    intent,
    isClickable,
    isDeletable,
    isDisabled,
    isSelected,
    isVisibleChipFullSize,
    // biome-ignore lint/correctness/useExhaustiveDependencies: Need to refactor this component to not rely on props spread
    props,
    resolvedIcon,
    size,
    tooltipProps,
    visibleChipClassName,
  ]);

  return (
    // (clewis): Use max-w-min and h-min to prevent the chip from expanding to the height of a flex
    // parent.
    <div className={clsx("flex h-min max-w-full", { "min-w-0": !disableShrinking }, className)}>
      <div
        aria-disabled={isDisabled ? true : undefined}
        aria-selected={isSelected ? true : undefined}
        className={clsx("relative flex min-w-0", { "w-full h-full": isVisibleChipFullSize })}
        // This is on this element so that tests can easily tell if the delete button is contained
        // within this data-testid.
        data-testid={dataTestId}
      >
        {child}

        {isDeletable && (
          // (clewis): It's invalid to have a <button> inside a <button>, so if the chip is clickable
          // and deletable, we need to render the delete button as a sibling and absolutely position
          // it over the chip itself.
          <button
            aria-label="delete"
            className={chipDeleteButtonVariants({ intent, size })}
            onClick={onDelete}
            tabIndex={tabIndexForDeleteButton}
            type="button"
          >
            <RegrelloIcon className="pointer-events-none" iconName="close" size="x-small" />
          </button>
        )}
      </div>
    </div>
  );
});
