import { assertNever, type ValuesUnion, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import React from "react";

import { RegrelloIconStyler, type RegrelloIconStylerProps } from "./RegrelloIconStyler";
import ActivityApprovedIcon from "./svgs/activity-approved-icon.svg?react";
import ActivityApprovedOutlineIcon from "./svgs/activity-approved-outline-icon.svg?react";
import ActivityAssignedIcon from "./svgs/activity-assigned-icon.svg?react";
import ActivityAutomatedRequestErrorIcon from "./svgs/activity-automated-request-error-icon.svg?react";
import ActivityAutomatedRequestIcon from "./svgs/activity-automated-request-icon.svg?react";
import ActivityCompleteIcon from "./svgs/activity-complete-icon.svg?react";
import ActivityDateChangeIcon from "./svgs/activity-date-change-icon.svg?react";
import ActivityDocumentAddedIcon from "./svgs/activity-document-added-icon.svg?react";
import ActivityEditIcon from "./svgs/activity-edit-icon.svg?react";
import ActivityExceptionIcon from "./svgs/activity-exception-icon.svg?react";
import ActivityMessageIcon from "./svgs/activity-message-icon.svg?react";
import ActivityOverdueIcon from "./svgs/activity-overdue-icon.svg?react";
import ActivityRejectedIcon from "./svgs/activity-rejected-icon.svg?react";
import ActivityRejectedOutlineIcon from "./svgs/activity-rejected-outline-icon.svg?react";
import ActivityResetIcon from "./svgs/activity-reset-icon.svg?react";
import ActivityResolveExceptionIcon from "./svgs/activity-resolve-exception-icon.svg?react";
import ActivityRestartedIcon from "./svgs/activity-restarted-icon.svg?react";
import ActivitySaveIcon from "./svgs/activity-save-icon.svg?react";
import ActivityTaskStartedIcon from "./svgs/activity-task-started-icon.svg?react";
import ActivityWorkflowCompleteIcon from "./svgs/activity-workflow-complete-icon.svg?react";
import ActivityWorkflowEndedIcon from "./svgs/activity-workflow-ended-icon.svg?react";
import ActivityWorkflowPausedIcon from "./svgs/activity-workflow-paused-icon.svg?react";
import ActivityWorkflowStartedIcon from "./svgs/activity-workflow-started-icon.svg?react";
import AddIcon from "./svgs/add-icon.svg?react";
import AdminIcon from "./svgs/admin-icon.svg?react";
import AlertIcon from "./svgs/alert-icon.svg?react";
import AlertOutlineIcon from "./svgs/alert-outline-icon.svg?react";
import AnalyticsIcon from "./svgs/analytics-icon.svg?react";
import ApiIcon from "./svgs/api-icon.svg?react";
import ApprovalNotStartedIcon from "./svgs/approval-not-started-icon.svg?react";
import ApprovedIcon from "./svgs/approved-icon.svg?react";
import ArrowBackIcon from "./svgs/arrow-back-icon.svg?react";
import ArrowBackSmallIcon from "./svgs/arrow-back-small-icon.svg?react";
import ArrowForwardIcon from "./svgs/arrow-forward-icon.svg?react";
import ArrowForwardSmallIcon from "./svgs/arrow-forward-small-icon.svg?react";
import ArrowLeftIcon from "./svgs/arrow-left-icon.svg?react";
import ArrowRightIcon from "./svgs/arrow-right-icon.svg?react";
import AssignIcon from "./svgs/assign-icon.svg?react";
import AssignedTasksIcon from "./svgs/assigned-tasks-icon.svg?react";
import AttachFileIcon from "./svgs/attach-file-icon.svg?react";
import BellIcon from "./svgs/bell-icon.svg?react";
import BellMutedIcon from "./svgs/bell-muted-icon.svg?react";
import BellOutlineIcon from "./svgs/bell-outline-icon.svg?react";
import BlankIcon from "./svgs/blank-icon.svg?react";
import BlueprintFileIcon from "./svgs/blueprint-file-icon.svg?react";
import BlueprintIcon from "./svgs/blueprint-icon.svg?react";
import CalendarIcon from "./svgs/calendar-icon.svg?react";
import CcIcon from "./svgs/cc-icon.svg?react";
import CcdTasksIcon from "./svgs/ccd-tasks-icon.svg?react";
import CheckboxCheckedIcon from "./svgs/checkbox-checked-icon.svg?react";
import CheckboxFieldIcon from "./svgs/checkbox-field-icon.svg?react";
import CheckboxSomeIcon from "./svgs/checkbox-some-icon.svg?react";
import CheckboxUncheckedIcon from "./svgs/checkbox-unchecked-icon.svg?react";
import CircleIcon from "./svgs/circle-icon.svg?react";
import CircleOutlineIcon from "./svgs/circle-outline-icon.svg?react";
import CloseFullscreenIcon from "./svgs/close-fullscreen-icon.svg?react";
import CloseIcon from "./svgs/close-icon.svg?react";
import CloseSmallIcon from "./svgs/close-small-icon.svg?react";
import ColumnsIcon from "./svgs/columns-icon.svg?react";
import ColumnsTwoIcon from "./svgs/columns-two-icon.svg?react";
import CompleteIcon from "./svgs/complete-icon.svg?react";
import CompleteLateIcon from "./svgs/complete-late-icon.svg?react";
import ContactCardIcon from "./svgs/contact-card-icon.svg?react";
import CopyIcon from "./svgs/copy-icon.svg?react";
import CopyLinkIcon from "./svgs/copy-link-icon.svg?react";
import CreateIcon from "./svgs/create-icon.svg?react";
import CrosshairsIcon from "./svgs/crosshairs-icon.svg?react";
import CsvFileIcon from "./svgs/csv-file-icon.svg?react";
import CurrencyFieldIcon from "./svgs/currency-field-icon.svg?react";
import CustomRoleAdminFieldIcon from "./svgs/custom-role-admin-field-icon.svg?react";
import DataAndIntegrationsIcon from "./svgs/data-and-integrations-icon.svg?react";
import DataSourceIcon from "./svgs/data-source-icon.svg?react";
import DataSyncErrorIcon from "./svgs/data-sync-error-icon.svg?react";
import DataSyncStoppedIcon from "./svgs/data-sync-stopped-icon.svg?react";
import DateFieldIcon from "./svgs/date-field-icon.svg?react";
import DelayedIcon from "./svgs/delayed-icon.svg?react";
import DeleteIcon from "./svgs/delete-icon.svg?react";
import DeprecatedInProgressOverdueIcon from "./svgs/DEPRECATED-in-progress-overdue-icon.svg?react";
import DeprecatedNeutralFaceIcon from "./svgs/DEPRECATED-neutral-face-icon.svg?react";
import DeprecatedUpcomingIcon from "./svgs/DEPRECATED-upcoming-icon.svg?react";
import DevUiIcon from "./svgs/dev-ui-icon.svg?react";
import DocumentsIcon from "./svgs/documents-icon.svg?react";
import DotIcon from "./svgs/dot-icon.svg?react";
import DragHandleVerticalIcon from "./svgs/drag-handle-vertical-icon.svg?react";
import DueDateIcon from "./svgs/due-date-icon.svg?react";
import DuplicateIcon from "./svgs/duplicate-icon.svg?react";
import EditOutlineIcon from "./svgs/edit-outline-icon.svg?react";
import EmailIcon from "./svgs/email-icon.svg?react";
import EmptyStateCompanyBlueprintsIcon from "./svgs/empty-state-company-blueprints-icon.svg?react";
import EmptyStateDocumentsIcon from "./svgs/empty-state-documents-icon.svg?react";
import EmptyStateHomeIcon from "./svgs/empty-state-home-icon.svg?react";
import EmptyStateMyBlueprintsIcon from "./svgs/empty-state-my-blueprints-icon.svg?react";
import EmptyStateTagsIcon from "./svgs/empty-state-tags-icon.svg?react";
import EmptyStateTeamsIcon from "./svgs/empty-state-teams-icon.svg?react";
import EmptyStateWorkflowsIcon from "./svgs/empty-state-workflows-icon.svg?react";
import EndWorkflowIcon from "./svgs/end-workflow-icon.svg?react";
import EndWorkflowMinimalIcon from "./svgs/end-workflow-minimal-icon.svg?react";
import ExceptionIcon from "./svgs/exception-icon.svg?react";
import ExpandLessHorizontalIcon from "./svgs/expand-less-horizontal-icon.svg?react";
import ExpandLessIcon from "./svgs/expand-less-icon.svg?react";
import ExpandMoreHorizontalIcon from "./svgs/expand-more-horizontal-icon.svg?react";
import ExpandMoreIcon from "./svgs/expand-more-icon.svg?react";
import ExportIcon from "./svgs/export-icon.svg?react";
import FieldAlertIcon from "./svgs/field-alert-icon.svg?react";
import FieldSearchIcon from "./svgs/field-search-icon.svg?react";
import FileDownloadIcon from "./svgs/file-download-icon.svg?react";
import FileFieldIcon from "./svgs/file-field-icon.svg?react";
import FileUploadIcon from "./svgs/file-upload-icon.svg?react";
import FilterIcon from "./svgs/filter-icon.svg?react";
import FilterOffOutlineIcon from "./svgs/filter-off-outline-icon.svg?react";
import FilterOutlineIcon from "./svgs/filter-outline-icon.svg?react";
import FolderIcon from "./svgs/folder-icon.svg?react";
import FormIcon from "./svgs/form-icon.svg?react";
import GenericFieldIcon from "./svgs/generic-field-icon.svg?react";
import GenericFieldsIcon from "./svgs/generic-fields-icon.svg?react";
import GenericFileIcon from "./svgs/generic-file-icon.svg?react";
import HamburgerMenuIcon from "./svgs/hamburger-menu-icon.svg?react";
import HelpIcon from "./svgs/help-icon.svg?react";
import HelpOutlineIcon from "./svgs/help-outline-icon.svg?react";
import HideIcon from "./svgs/hide-icon.svg?react";
import HighlightIcon from "./svgs/highlight-icon.svg?react";
import HomeIcon from "./svgs/home-icon.svg?react";
import ImageFileIcon from "./svgs/image-file-icon.svg?react";
import ImportIcon from "./svgs/import-icon.svg?react";
import InProgressIcon from "./svgs/in-progress-icon.svg?react";
import InProgressMinimalIcon from "./svgs/in-progress-minimal-icon.svg?react";
import InboxIcon from "./svgs/inbox-icon.svg?react";
import InfoIcon from "./svgs/info-icon.svg?react";
import InfoOutlineIcon from "./svgs/info-outline-icon.svg?react";
import IntegrationSimpleIcon from "./svgs/integration-simple-icon.svg?react";
import IntegrationSimpleSolidIcon from "./svgs/integration-simple-solid-icon.svg?react";
import InternationalAmericasIcon from "./svgs/international-americas-icon.svg?react";
import InternationalApacAustraliaIcon from "./svgs/international-apac-australia-icon.svg?react";
import KeyIcon from "./svgs/key-icon.svg?react";
import LaunchIcon from "./svgs/launch-icon.svg?react";
import LinkToFileIcon from "./svgs/link-to-file-icon.svg?react";
import LinkedWorkflowIcon from "./svgs/linked-workflow-icon.svg?react";
import ListIcon from "./svgs/list-icon.svg?react";
import LockedIcon from "./svgs/locked-icon.svg?react";
import MagicIcon from "./svgs/magic-icon.svg?react";
import MailIcon from "./svgs/mail-icon.svg?react";
import MenuOpenLeftIcon from "./svgs/menu-open-left-icon.svg?react";
import MenuOpenRightIcon from "./svgs/menu-open-right-icon.svg?react";
import MessageOutlineIcon from "./svgs/message-outline-icon.svg?react";
import MoreVerticalIcon from "./svgs/more-vertical-icon.svg?react";
import MoveIcon from "./svgs/move-icon.svg?react";
import MultiSelectFieldIcon from "./svgs/multi-select-field-icon.svg?react";
import NewViewIcon from "./svgs/new-view-icon.svg?react";
import NotStartedIcon from "./svgs/not-started-icon.svg?react";
import NumberFieldIcon from "./svgs/number-field-icon.svg?react";
import ObjectOutlineIcon from "./svgs/object-outline-icon.svg?react";
import OpenFullscreenIcon from "./svgs/open-fullscreen-icon.svg?react";
import OutOfOfficeIcon from "./svgs/out-of-office-icon.svg?react";
import OverdueIcon from "./svgs/overdue-icon.svg?react";
import OverdueMinimalIcon from "./svgs/overdue-minimal-icon.svg?react";
import OwnedByMeIcon from "./svgs/owned-by-me-icon.svg?react";
import PasteIcon from "./svgs/paste-icon.svg?react";
import PauseWorkflowIcon from "./svgs/pause-workflow-icon.svg?react";
import PausedForExceptionIcon from "./svgs/paused-for-exception-icon.svg?react";
import PeopleIcon from "./svgs/people-icon.svg?react";
import PersonFieldIcon from "./svgs/person-field-icon.svg?react";
import PersonIcon from "./svgs/person-icon.svg?react";
import PhoneIcon from "./svgs/phone-icon.svg?react";
import PinnedIcon from "./svgs/pinned-icon.svg?react";
import PlaceholderIcon from "./svgs/placeholder-icon.svg?react";
import PreviewIcon from "./svgs/preview-icon.svg?react";
import PublishBlueprintIcon from "./svgs/publish-blueprint-icon.svg?react";
import PublishIcon from "./svgs/publish-icon.svg?react";
import RefreshIcon from "./svgs/refresh-icon.svg?react";
import RegrelloLogoIcon from "./svgs/regrello-logo-icon.svg?react";
import RejectedIcon from "./svgs/rejected-icon.svg?react";
import RemoveCancelIcon from "./svgs/remove-cancel-icon.svg?react";
import RenameIcon from "./svgs/rename-icon.svg?react";
import ReopenedIcon from "./svgs/reopened-icon.svg?react";
import ReopenedWarningIcon from "./svgs/reopened-warning-icon.svg?react";
import ReplyAllIcon from "./svgs/reply-all-icon.svg?react";
import ReplyIcon from "./svgs/reply-icon.svg?react";
import RequiredIcon from "./svgs/required-icon.svg?react";
import ResetIcon from "./svgs/reset-icon.svg?react";
import ResizeHandleVerticalIcon from "./svgs/resize-handle-vertical-icon.svg?react";
import RestartAlertIcon from "./svgs/restart-alert-icon.svg?react";
import RestartIcon from "./svgs/restart-icon.svg?react";
import RoleIcon from "./svgs/role-icon.svg?react";
import RowsIcon from "./svgs/rows-icon.svg?react";
import SaveAsIcon from "./svgs/save-as-icon.svg?react";
import SaveIcon from "./svgs/save-icon.svg?react";
import SearchIcon from "./svgs/search-icon.svg?react";
import SelectFieldIcon from "./svgs/select-field-icon.svg?react";
import SelectedIcon from "./svgs/selected-icon.svg?react";
import SendAndCompleteIcon from "./svgs/send-and-complete-icon.svg?react";
import SendAndReportExceptionIcon from "./svgs/send-and-report-exception-icon.svg?react";
import SendIcon from "./svgs/send-icon.svg?react";
import SettingsIcon from "./svgs/settings-icon.svg?react";
import ShareIcon from "./svgs/share-icon.svg?react";
import ShowIcon from "./svgs/show-icon.svg?react";
import SignatureFieldIcon from "./svgs/signature-field-icon.svg?react";
import SkippedIcon from "./svgs/skipped-icon.svg?react";
import SortAscendingIcon from "./svgs/sort-ascending-icon.svg?react";
import SortDescendingIcon from "./svgs/sort-descending-icon.svg?react";
import SplitTaskIcon from "./svgs/split-task-icon.svg?react";
import SsoIcon from "./svgs/sso-icon.svg?react";
import StageCompletedIcon from "./svgs/stage-completed-icon.svg?react";
import StageInProgressIcon from "./svgs/stage-in-progress-icon.svg?react";
import StageNotStartedIcon from "./svgs/stage-not-started-icon.svg?react";
import StageSkippedIcon from "./svgs/stage-skipped-icon.svg?react";
import StarredIcon from "./svgs/starred-icon.svg?react";
import StartWorkflowIcon from "./svgs/start-workflow-icon.svg?react";
import SubmittedIcon from "./svgs/submitted-icon.svg?react";
import SubtractIcon from "./svgs/subtract-icon.svg?react";
import SystemFieldPersonIcon from "./svgs/system-field-person-icon.svg?react";
import TableIcon from "./svgs/table-icon.svg?react";
import TagIcon from "./svgs/tag-icon.svg?react";
import TaskIcon from "./svgs/task-icon.svg?react";
import TeamIcon from "./svgs/team-icon.svg?react";
import TextFieldIcon from "./svgs/text-field-icon.svg?react";
import UndoIcon from "./svgs/undo-icon.svg?react";
import UnfoldLessIcon from "./svgs/unfold-less-icon.svg?react";
import UnfoldMoreIcon from "./svgs/unfold-more-icon.svg?react";
import UnlockedIcon from "./svgs/unlocked-icon.svg?react";
import UnpinnedIcon from "./svgs/unpinned-icon.svg?react";
import UnstarredIcon from "./svgs/unstarred-icon.svg?react";
import UpdateManualIcon from "./svgs/update-manual-icon.svg?react";
import UpdateSyncIcon from "./svgs/update-sync-icon.svg?react";
import VerifiedIcon from "./svgs/verified-icon.svg?react";
import VersionsIcon from "./svgs/versions-icon.svg?react";
import ViewIcon from "./svgs/view-icon.svg?react";
import ViewLogIcon from "./svgs/view-log-icon.svg?react";
import WarningIcon from "./svgs/warning-icon.svg?react";
import WorkflowIcon from "./svgs/workflow-icon.svg?react";
import WorkspaceIcon from "./svgs/workspace-icon.svg?react";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";

// (clewis): Put all the code that will grow with the number of icons, down here in one place.

export const RegrelloIconName = {
  ACTIVITY_AUTOMATED_REQUEST_ERROR: "activity-automated-request-error",
  ACTIVITY_AUTOMATED_REQUEST: "activity-automated-request",
  ACTIVITY_DATE_CHANGE: "activity-date-change",
  ACTIVITY_DOCUMENT_ADDED: "activity-document-added",
  ACTIVITY_EDIT: "activity-edit",
  ACTIVITY_MESSAGE: "activity-message",
  ACTIVITY_RESET: "activity-reset",
  ACTIVITY_RESOLVE_EXCEPTION: "activity-resolve-exception",
  ACTIVITY_RESTARTED: "activity-restarted",
  ACTIVITY_TASK_STARTED: "activity-task-started",
  ACTIVITY_WORKFLOW_STARTED: "activity-workflow-started",
  ADD: "add",
  ADMIN: "admin",
  ANALYTICS: "analytics",
  API: "api",
  APPROVAL_NOT_STARTED: "approval-not-started",
  APPROVED: "approved",
  ACTIVITY_APPROVED: "activity-approved",
  ACTIVITY_APPROVED_OUTLINE: "activity-approved-outline",
  ALERT: "alert",
  ALERT_OUTLINE: "alert-outline",
  ARROW_BACK: "arrow-back",
  ARROW_BACK_SMALL: "arrow-back-small",
  ARROW_FORWARD: "arrow-forward",
  ARROW_FORWARD_SMALL: "arrow-forward-small",
  ARROW_LEFT: "arrow-left",
  ARROW_RIGHT: "arrow-right",
  ASSIGNED_TASKS: "assigned-tasks",
  ASSIGN: "assign",
  ACTIVITY_ASSIGNED: "activity-assigned",
  ATTACH_FILE: "attach-file",
  BELL: "bell",
  BELL_MUTED: "bell-muted",
  BELL_OUTLINE: "bell-outline",
  BLANK: "blank",
  BLUEPRINT_FILE: "blueprint-file",
  CALENDAR: "calendar",
  CC: "cc",
  CCD_TASKS: "ccd-tasks",
  CHECKBOX_FIELD: "checkbox-field",
  CHECKBOX_SOME: "checkbox-some",
  CHECKBOX_UNCHECKED: "checkbox-unchecked",
  CHECKBOX_CHECKED: "checkbox-checked",
  CIRCLE: "circle",
  CIRCLE_OUTLINE: "circle-outline",
  CLOSE: "close",
  CLOSE_SMALL: "close-small",
  CLOSE_FULLSCREEN: "close-fullscreen",
  COLUMNS: "columns",
  COLUMNS_TWO: "columns-two",
  COPY_LINK: "copy-link",
  ACTIVITY_WORKFLOW_COMPLETE: "activity-workflow-complete",
  ACTIVITY_COMPLETE: "activity-complete",
  COMPLETE: "complete",
  COMPLETE_LATE: "complete-late",
  CONTACT_CARD: "contact-card",
  COPY: "copy",
  CREATE: "create",
  CROSSHAIRS: "crosshairs",
  CSV_FILE: "csv-file",
  CURRENCY_FIELD: "currency-field",
  CUSTOM_ROLE_ADMIN_FIELD: "custom-role-admin-field",
  DATA_AND_INTEGRATIONS: "data-and-integrations",
  DATA_SOURCE: "data-source",
  DATA_SYNC_ERROR: "data-sync-error",
  DATA_SYNC_STOPPED: "data-sync-stopped",
  DATE_FIELD: "date-field",
  DELAYED: "delayed",
  DELETE: "delete",
  DEV_UI_ICON: "dev-ui-icon",
  DOCUMENTS: "documents",
  DOT: "dot",
  DRAG_HANDLE_VERTICAL: "drag-handle-vertical",
  DUE_DATE: "due-date",
  DUPLICATE: "duplicate",
  EMAIL: "email",
  EDIT_OUTLINE: "edit-outline",
  EXPAND_LESS: "expand-less",
  EXPAND_LESS_HORIZONTAL: "expand-less-horizontal",
  EXPAND_MORE: "expand-more",
  EXPAND_MORE_HORIZONTAL: "expand-more-horizontal",
  EMPTY_STATE_COMPANY_BLUEPRINTS: "empty-state-company-blueprints",
  EMPTY_STATE_DOCUMENT: "empty-state-document",
  EMPTY_STATE_HOME: "empty-state-home",
  EMPTY_STATE_MY_BLUEPRINTS: "empty-state-my-blueprints",
  EMPTY_STATE_TAGS: "empty-state-tags",
  EMPTY_STATE_TEAMS: "empty-state-teams",
  EMPTY_STATE_USER: "empty-state-user",
  EMPTY_STATE_WORKFLOWS: "empty-state-workflows",
  ACTIVITY_WORKFLOW_ENDED: "activity-workflow-ended",
  ACTIVITY_EXCEPTION: "activity-exception",
  ACTIVITY_OVERDUE: "activity-overdue",
  EXPORT: "export",
  FIELD_ALERT: "field-alert",
  FIELD_SEARCH: "field-search",
  GENERIC_FIELD: "generic-field",
  FILE_DOWNLOAD: "file-download",
  FILE_FIELD: "file-field",
  FILTER: "filter",
  FILTER_OFF_OUTLINE: "filter-off-outline",
  FILTER_OUTLINE: "filter-outline",
  FOLDER: "folder",
  FORM: "form",
  HAMBURGER_MENU: "hamburger-menu",
  HELP: "help",
  HELP_OUTLINE: "help-outline",
  HIDE: "hide",
  HIGHLIGHT: "highlight",
  HOME: "home",
  IMAGE_FILE: "image-file",
  IMPORT: "import",
  INBOX: "inbox",
  DEPRECATED_IN_PROGRESS_OVERDUE: "DEPRECATED-in-progress-overdue",
  IN_PROGRESS: "in-progress",
  IN_PROGRESS_MINIMAL: "in-progress-minimal",
  INFO: "info",
  INFO_OUTLINE: "info-outline",
  INTEGRATION_SIMPLE: "integration-simple",
  INTEGRATION_SIMPLE_SOLID: "integration-simple-solid",
  INTERNATIONAL_AMERICAS: "international-americas",
  INTERNATIONAL_APAC_AUSTRALIA: "international-apac-australia",
  GENERIC_FIELDS: "generic-fields",
  GENERIC_FILE: "generic-file",
  KEY: "key",
  LAUNCH: "launch",
  LINK_TO_FILE: "link-to-file",
  LINKED_WORKFLOW: "linked-workflow",
  LIST: "list",
  LOCKED: "locked",
  MAGIC: "magic",
  MAIL: "mail",
  MENU_OPEN_LEFT: "menu-open-left",
  MENU_OPEN_RIGHT: "menu-open-right",
  MESSAGE_OUTLINE: "message-outline",
  MORE_VERTICAL: "more-vertical",
  MOVE: "move",
  MULTI_SELECT_FIELD: "multi-select-field",
  DEPRECATED_NEUTRAL_FACE: "DEPRECATED-neutral-face",
  NEW_VIEW: "new-view",
  NOT_STARTED: "not-started",
  NUMBER_FIELD: "number-field",
  OBJECT_OUTLINE: "object-outline",
  OPEN_FULLSCREEN: "open-fullscreen",
  OUT_OF_OFFICE: "out-of-office",
  OVERDUE: "overdue",
  OVERDUE_MINIMAL: "overdue-minimal",
  OWNED_BY_ME: "owned-by-me",
  PASTE: "paste",
  ACTIVITY_WORKFLOW_PAUSED: "activity-workflow-paused",
  PAUSE_WORKFLOW: "pause-workflow",
  PAUSED_FOR_EXCEPTION: "paused-for-exception",
  PEOPLE: "people",
  PERSON_FIELD: "person-field",
  PERSON: "person",
  PHONE: "phone",
  PINNED: "pinned",
  PLACEHOLDER: "placeholder",
  PREVIEW: "preview",
  PUBLISH: "publish",
  PUBLISH_BLUEPRINT: "publish-blueprint",
  REFRESH: "refresh",
  REGRELLO_LOGO: "regrello-logo",
  ACTIVITY_REJECTED: "activity-rejected",
  ACTIVITY_REJECTED_OUTLINE: "activity-rejected-outline",
  REJECTED: "rejected",
  REMOVE_CANCEL: "remove-cancel",
  RENAME: "rename",
  REOPENED: "reopened",
  REOPENED_WARNING: "reopened-warning",
  REPLY_ALL: "reply-all",
  REPLY: "reply",
  EXCEPTION: "exception",
  REQUIRED: "required",
  RESET: "reset",
  RESIZE_HANDLE_VERTICAL: "resize-handle-vertical",
  RESTART_ALERT: "restart-alert",
  RESTART: "restart",
  ROLE: "role",
  ACTIVITY_SAVE: "activity-save",
  ROWS: "rows",
  SAVE_AS: "save-as",
  SAVE: "save",
  SEARCH: "search",
  SELECT_FIELD: "select-field",
  SELECTED: "selected",
  SEND: "send",
  SEND_AND_COMPLETE: "send-and-complete",
  SEND_AND_REPORT_EXCEPTION: "send-and-report-exception",
  SETTINGS: "settings",
  SHARE: "share",
  SHOW: "show",
  SIGNATURE_FIELD: "signature-field",
  SKIPPED: "skipped",
  SORT_ASCENDING: "sort-ascending",
  SORT_DESCENDING: "sort-descending",
  SPLIT_TASK: "split-task",
  SSO: "sso",
  STAGE_COMPLETED: "stage-completed",
  STAGE_IN_PROGRESS: "stage-in-progress",
  STAGE_NOT_STARTED: "stage-not-started",
  STAGE_SKIPPED: "stage-skipped",
  STARRED: "starred",
  START_WORKFLOW: "start-workflow",
  SUBMITTED: "submitted",
  SUBTRACT: "subtract",
  END_WORKFLOW: "end-workflow",
  END_WORKFLOW_MINIMAL: "end-workflow-minimal",
  SYSTEM_FIELD_PERSON: "system-field-person",
  TABLE: "table",
  TAG: "tag",
  TASK: "task",
  TEAM: "team",
  BLUEPRINT: "blueprint",
  TEXT_FIELD: "text-field",
  UNDO: "undo",
  UNFOLD_LESS: "unfold-less",
  UNFOLD_MORE: "unfold-more",
  UNLOCKED: "unlocked",
  UNPINNED: "unpinned",
  UNSTARRED: "unstarred",
  DEPRECATED_UPCOMING: "upcoming-DEPRECATED-DO-NOT-USE",
  UPDATE_MANUAL: "update-manual",
  UPDATE_SYNC: "update-sync",
  FILE_UPLOAD: "file-upload",
  VERIFIED: "verified",
  VERSIONS: "versions",
  VIEW: "view",
  VIEW_LOG: "view-log",
  WARNING: "warning",
  WORKFLOW: "workflow",
  WORKSPACE: "workspace",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RegrelloIconName = ValuesUnion<typeof RegrelloIconName>;

function getIconSvgComponentByName(iconName: RegrelloIconName): React.FunctionComponent<React.SVGProps<SVGSVGElement>> {
  switch (iconName) {
    case RegrelloIconName.ACTIVITY_AUTOMATED_REQUEST_ERROR:
      return ActivityAutomatedRequestErrorIcon;
    case RegrelloIconName.ACTIVITY_AUTOMATED_REQUEST:
      return ActivityAutomatedRequestIcon;
    case RegrelloIconName.ACTIVITY_DATE_CHANGE:
      return ActivityDateChangeIcon;
    case RegrelloIconName.ACTIVITY_DOCUMENT_ADDED:
      return ActivityDocumentAddedIcon;
    case RegrelloIconName.ACTIVITY_EDIT:
      return ActivityEditIcon;
    case RegrelloIconName.ACTIVITY_MESSAGE:
      return ActivityMessageIcon;
    case RegrelloIconName.ACTIVITY_RESET:
      return ActivityResetIcon;
    case RegrelloIconName.ACTIVITY_RESOLVE_EXCEPTION:
      return ActivityResolveExceptionIcon;
    case RegrelloIconName.ACTIVITY_RESTARTED:
      return ActivityRestartedIcon;
    case RegrelloIconName.ACTIVITY_TASK_STARTED:
      return ActivityTaskStartedIcon;
    case RegrelloIconName.ACTIVITY_WORKFLOW_STARTED:
      return ActivityWorkflowStartedIcon;
    case RegrelloIconName.ADD:
      return AddIcon;
    case RegrelloIconName.ADMIN:
      return AdminIcon;
    case RegrelloIconName.ALERT:
      return AlertIcon;
    case RegrelloIconName.ANALYTICS:
      return AnalyticsIcon;
    case RegrelloIconName.API:
      return ApiIcon;
    case RegrelloIconName.APPROVAL_NOT_STARTED:
      return ApprovalNotStartedIcon;
    case RegrelloIconName.APPROVED:
      return ApprovedIcon;
    case RegrelloIconName.ACTIVITY_APPROVED:
      return ActivityApprovedIcon;
    case RegrelloIconName.ACTIVITY_APPROVED_OUTLINE:
      return ActivityApprovedOutlineIcon;
    case RegrelloIconName.ARROW_FORWARD_SMALL:
      return ArrowForwardSmallIcon;
    case RegrelloIconName.ARROW_LEFT:
      return ArrowLeftIcon;
    case RegrelloIconName.ARROW_RIGHT:
      return ArrowRightIcon;
    case RegrelloIconName.ASSIGN:
      return AssignIcon;
    case RegrelloIconName.ASSIGNED_TASKS:
      return AssignedTasksIcon;
    case RegrelloIconName.ACTIVITY_ASSIGNED:
      return ActivityAssignedIcon;
    case RegrelloIconName.ATTACH_FILE:
      return AttachFileIcon;
    case RegrelloIconName.BELL:
      return BellIcon;
    case RegrelloIconName.BELL_MUTED:
      return BellMutedIcon;
    case RegrelloIconName.BELL_OUTLINE:
      return BellOutlineIcon;
    case RegrelloIconName.BLANK:
      return BlankIcon;
    case RegrelloIconName.BLUEPRINT_FILE:
      return BlueprintFileIcon;
    case RegrelloIconName.CALENDAR:
      return CalendarIcon;
    case RegrelloIconName.CC:
      return CcIcon;
    case RegrelloIconName.CCD_TASKS:
      return CcdTasksIcon;
    case RegrelloIconName.CHECKBOX_CHECKED:
      return CheckboxCheckedIcon;
    case RegrelloIconName.CHECKBOX_FIELD:
      return CheckboxFieldIcon;
    case RegrelloIconName.CHECKBOX_SOME:
      return CheckboxSomeIcon;
    case RegrelloIconName.CHECKBOX_UNCHECKED:
      return CheckboxUncheckedIcon;
    case RegrelloIconName.ARROW_BACK:
      return ArrowBackIcon;
    case RegrelloIconName.ARROW_BACK_SMALL:
      return ArrowBackSmallIcon;
    case RegrelloIconName.ARROW_FORWARD:
      return ArrowForwardIcon;
    case RegrelloIconName.CIRCLE:
      return CircleIcon;
    case RegrelloIconName.CIRCLE_OUTLINE:
      return CircleOutlineIcon;
    case RegrelloIconName.CLOSE_FULLSCREEN:
      return CloseFullscreenIcon;
    case RegrelloIconName.CLOSE:
      return CloseIcon;
    case RegrelloIconName.CLOSE_SMALL:
      return CloseSmallIcon;
    case RegrelloIconName.COLUMNS:
      return ColumnsIcon;
    case RegrelloIconName.COLUMNS_TWO:
      return ColumnsTwoIcon;
    case RegrelloIconName.ACTIVITY_WORKFLOW_COMPLETE:
      return ActivityWorkflowCompleteIcon;
    case RegrelloIconName.ACTIVITY_COMPLETE:
      return ActivityCompleteIcon;
    case RegrelloIconName.COMPLETE:
      return CompleteIcon;
    case RegrelloIconName.COMPLETE_LATE:
      return CompleteLateIcon;
    case RegrelloIconName.CONTACT_CARD:
      return ContactCardIcon;
    case RegrelloIconName.COPY:
      return CopyIcon;
    case RegrelloIconName.COPY_LINK:
      return CopyLinkIcon;
    case RegrelloIconName.CREATE:
      return CreateIcon;
    case RegrelloIconName.CROSSHAIRS:
      return CrosshairsIcon;
    case RegrelloIconName.CSV_FILE:
      return CsvFileIcon;
    case RegrelloIconName.CURRENCY_FIELD:
      return CurrencyFieldIcon;
    case RegrelloIconName.CUSTOM_ROLE_ADMIN_FIELD:
      return CustomRoleAdminFieldIcon;
    case RegrelloIconName.DATA_AND_INTEGRATIONS:
      return DataAndIntegrationsIcon;
    case RegrelloIconName.DATA_SOURCE:
      return DataSourceIcon;
    case RegrelloIconName.DATA_SYNC_ERROR:
      return DataSyncErrorIcon;
    case RegrelloIconName.DATA_SYNC_STOPPED:
      return DataSyncStoppedIcon;
    case RegrelloIconName.DATE_FIELD:
      return DateFieldIcon;
    case RegrelloIconName.DELAYED:
      return DelayedIcon;
    case RegrelloIconName.DELETE:
      return DeleteIcon;
    case RegrelloIconName.DEV_UI_ICON:
      return DevUiIcon;
    case RegrelloIconName.DOCUMENTS:
      return DocumentsIcon;
    case RegrelloIconName.DOT:
      return DotIcon;
    case RegrelloIconName.DRAG_HANDLE_VERTICAL:
      return DragHandleVerticalIcon;
    case RegrelloIconName.DUE_DATE:
      return DueDateIcon;
    case RegrelloIconName.DUPLICATE:
      return DuplicateIcon;
    case RegrelloIconName.EMAIL:
      return EmailIcon;
    case RegrelloIconName.EDIT_OUTLINE:
      return EditOutlineIcon;
    case RegrelloIconName.EXPAND_LESS:
      return ExpandLessIcon;
    case RegrelloIconName.EXPAND_LESS_HORIZONTAL:
      return ExpandLessHorizontalIcon;
    case RegrelloIconName.EXPAND_MORE:
      return ExpandMoreIcon;
    case RegrelloIconName.EXPAND_MORE_HORIZONTAL:
      return ExpandMoreHorizontalIcon;
    case RegrelloIconName.EMPTY_STATE_DOCUMENT:
      return EmptyStateDocumentsIcon;
    case RegrelloIconName.EMPTY_STATE_COMPANY_BLUEPRINTS:
      return EmptyStateCompanyBlueprintsIcon;
    case RegrelloIconName.EMPTY_STATE_HOME:
      return EmptyStateHomeIcon;
    case RegrelloIconName.EMPTY_STATE_MY_BLUEPRINTS:
      return EmptyStateMyBlueprintsIcon;
    case RegrelloIconName.EMPTY_STATE_TAGS:
      return EmptyStateTagsIcon;
    case RegrelloIconName.EMPTY_STATE_TEAMS:
      return EmptyStateTeamsIcon;
    case RegrelloIconName.EMPTY_STATE_USER:
      return OwnedByMeIcon;
    case RegrelloIconName.EMPTY_STATE_WORKFLOWS:
      return EmptyStateWorkflowsIcon;
    case RegrelloIconName.ACTIVITY_WORKFLOW_ENDED:
      return ActivityWorkflowEndedIcon;
    case RegrelloIconName.END_WORKFLOW:
      return EndWorkflowIcon;
    case RegrelloIconName.END_WORKFLOW_MINIMAL:
      return EndWorkflowMinimalIcon;
    case RegrelloIconName.ALERT_OUTLINE:
      return AlertOutlineIcon;
    case RegrelloIconName.ACTIVITY_EXCEPTION:
      return ActivityExceptionIcon;
    case RegrelloIconName.ACTIVITY_OVERDUE:
      return ActivityOverdueIcon;
    case RegrelloIconName.EXPORT:
      return ExportIcon;
    case RegrelloIconName.FIELD_ALERT:
      return FieldAlertIcon;
    case RegrelloIconName.FIELD_SEARCH:
      return FieldSearchIcon;
    case RegrelloIconName.GENERIC_FIELD:
      return GenericFieldIcon;
    case RegrelloIconName.FILE_DOWNLOAD:
      return FileDownloadIcon;
    case RegrelloIconName.FILE_FIELD:
      return FileFieldIcon;
    case RegrelloIconName.FILTER:
      return FilterIcon;
    case RegrelloIconName.FILTER_OFF_OUTLINE:
      return FilterOffOutlineIcon;
    case RegrelloIconName.FILTER_OUTLINE:
      return FilterOutlineIcon;
    case RegrelloIconName.FOLDER:
      return FolderIcon;
    case RegrelloIconName.FORM:
      return FormIcon;
    case RegrelloIconName.GENERIC_FIELDS:
      return GenericFieldsIcon;
    case RegrelloIconName.GENERIC_FILE:
      return GenericFileIcon;
    case RegrelloIconName.HAMBURGER_MENU:
      return HamburgerMenuIcon;
    case RegrelloIconName.HELP:
      return HelpIcon;
    case RegrelloIconName.HELP_OUTLINE:
      return HelpOutlineIcon;
    case RegrelloIconName.HIDE:
      return HideIcon;
    case RegrelloIconName.HIGHLIGHT:
      return HighlightIcon;
    case RegrelloIconName.HOME:
      return HomeIcon;
    case RegrelloIconName.IMAGE_FILE:
      return ImageFileIcon;
    case RegrelloIconName.IMPORT:
      return ImportIcon;
    case RegrelloIconName.INBOX:
      return InboxIcon;
    case RegrelloIconName.IN_PROGRESS:
      return InProgressIcon;
    case RegrelloIconName.IN_PROGRESS_MINIMAL:
      return InProgressMinimalIcon;
    case RegrelloIconName.DEPRECATED_IN_PROGRESS_OVERDUE:
      return DeprecatedInProgressOverdueIcon;
    case RegrelloIconName.INFO:
      return InfoIcon;
    case RegrelloIconName.INFO_OUTLINE:
      return InfoOutlineIcon;
    case RegrelloIconName.INTEGRATION_SIMPLE:
      return IntegrationSimpleIcon;
    case RegrelloIconName.INTEGRATION_SIMPLE_SOLID:
      return IntegrationSimpleSolidIcon;
    case RegrelloIconName.INTERNATIONAL_AMERICAS:
      return InternationalAmericasIcon;
    case RegrelloIconName.INTERNATIONAL_APAC_AUSTRALIA:
      return InternationalApacAustraliaIcon;
    case RegrelloIconName.KEY:
      return KeyIcon;
    case RegrelloIconName.LAUNCH:
      return LaunchIcon;
    case RegrelloIconName.LINK_TO_FILE:
      return LinkToFileIcon;
    case RegrelloIconName.SELECT_FIELD:
      return SelectFieldIcon;
    case RegrelloIconName.LINKED_WORKFLOW:
      return LinkedWorkflowIcon;
    case RegrelloIconName.LIST:
      return ListIcon;
    case RegrelloIconName.LOCKED:
      return LockedIcon;
    case RegrelloIconName.MAGIC:
      return MagicIcon;
    case RegrelloIconName.MAIL:
      return MailIcon;
    case RegrelloIconName.MENU_OPEN_LEFT:
      return MenuOpenLeftIcon;
    case RegrelloIconName.MENU_OPEN_RIGHT:
      return MenuOpenRightIcon;
    case RegrelloIconName.MESSAGE_OUTLINE:
      return MessageOutlineIcon;
    case RegrelloIconName.MORE_VERTICAL:
      return MoreVerticalIcon;
    case RegrelloIconName.MOVE:
      return MoveIcon;
    case RegrelloIconName.MULTI_SELECT_FIELD:
      return MultiSelectFieldIcon;
    case RegrelloIconName.DEPRECATED_NEUTRAL_FACE:
      return DeprecatedNeutralFaceIcon;
    case RegrelloIconName.NEW_VIEW:
      return NewViewIcon;
    case RegrelloIconName.NOT_STARTED:
      return NotStartedIcon;
    case RegrelloIconName.NUMBER_FIELD:
      return NumberFieldIcon;
    case RegrelloIconName.OBJECT_OUTLINE:
      return ObjectOutlineIcon;
    case RegrelloIconName.OPEN_FULLSCREEN:
      return OpenFullscreenIcon;
    case RegrelloIconName.OUT_OF_OFFICE:
      return OutOfOfficeIcon;
    case RegrelloIconName.OVERDUE:
      return OverdueIcon;
    case RegrelloIconName.OVERDUE_MINIMAL:
      return OverdueMinimalIcon;
    case RegrelloIconName.OWNED_BY_ME:
      return OwnedByMeIcon;
    case RegrelloIconName.PASTE:
      return PasteIcon;
    case RegrelloIconName.ACTIVITY_WORKFLOW_PAUSED:
      return ActivityWorkflowPausedIcon;
    case RegrelloIconName.PAUSE_WORKFLOW:
      return PauseWorkflowIcon;
    case RegrelloIconName.PAUSED_FOR_EXCEPTION:
      return PausedForExceptionIcon;
    case RegrelloIconName.PEOPLE:
      return PeopleIcon;
    case RegrelloIconName.PERSON_FIELD:
      return PersonFieldIcon;
    case RegrelloIconName.PERSON:
      return PersonIcon;
    case RegrelloIconName.PHONE:
      return PhoneIcon;
    case RegrelloIconName.PINNED:
      return PinnedIcon;
    case RegrelloIconName.PLACEHOLDER:
      return PlaceholderIcon;
    case RegrelloIconName.PREVIEW:
      return PreviewIcon;
    case RegrelloIconName.PUBLISH:
      return PublishIcon;
    case RegrelloIconName.PUBLISH_BLUEPRINT:
      return PublishBlueprintIcon;
    case RegrelloIconName.REFRESH:
      return RefreshIcon;
    case RegrelloIconName.REGRELLO_LOGO:
      return RegrelloLogoIcon;
    case RegrelloIconName.ACTIVITY_REJECTED:
      return ActivityRejectedIcon;
    case RegrelloIconName.ACTIVITY_REJECTED_OUTLINE:
      return ActivityRejectedOutlineIcon;
    case RegrelloIconName.REJECTED:
      return RejectedIcon;
    case RegrelloIconName.REMOVE_CANCEL:
      return RemoveCancelIcon;
    case RegrelloIconName.RENAME:
      return RenameIcon;
    case RegrelloIconName.REOPENED:
      return ReopenedIcon;
    case RegrelloIconName.REOPENED_WARNING:
      return ReopenedWarningIcon;
    case RegrelloIconName.REPLY_ALL:
      return ReplyAllIcon;
    case RegrelloIconName.REPLY:
      return ReplyIcon;
    case RegrelloIconName.EXCEPTION:
      return ExceptionIcon;
    case RegrelloIconName.REQUIRED:
      return RequiredIcon;
    case RegrelloIconName.RESET:
      return ResetIcon;
    case RegrelloIconName.RESIZE_HANDLE_VERTICAL:
      return ResizeHandleVerticalIcon;
    case RegrelloIconName.RESTART_ALERT:
      return RestartAlertIcon;
    case RegrelloIconName.RESTART:
      return RestartIcon;
    case RegrelloIconName.ROLE:
      return RoleIcon;
    case RegrelloIconName.ACTIVITY_SAVE:
      return ActivitySaveIcon;
    case RegrelloIconName.ROWS:
      return RowsIcon;
    case RegrelloIconName.SAVE_AS:
      return SaveAsIcon;
    case RegrelloIconName.SAVE:
      return SaveIcon;
    case RegrelloIconName.SEARCH:
      return SearchIcon;
    case RegrelloIconName.SELECTED:
      return SelectedIcon;
    case RegrelloIconName.SEND:
      return SendIcon;
    case RegrelloIconName.SETTINGS:
      return SettingsIcon;
    case RegrelloIconName.SEND_AND_COMPLETE:
      return SendAndCompleteIcon;
    case RegrelloIconName.SEND_AND_REPORT_EXCEPTION:
      return SendAndReportExceptionIcon;
    case RegrelloIconName.SHARE:
      return ShareIcon;
    case RegrelloIconName.SHOW:
      return ShowIcon;
    case RegrelloIconName.SIGNATURE_FIELD:
      return SignatureFieldIcon;
    case RegrelloIconName.SKIPPED:
      return SkippedIcon;
    case RegrelloIconName.SORT_ASCENDING:
      return SortAscendingIcon;
    case RegrelloIconName.SORT_DESCENDING:
      return SortDescendingIcon;
    case RegrelloIconName.SPLIT_TASK:
      return SplitTaskIcon;
    case RegrelloIconName.SSO:
      return SsoIcon;
    case RegrelloIconName.STAGE_COMPLETED:
      return StageCompletedIcon;
    case RegrelloIconName.STAGE_IN_PROGRESS:
      return StageInProgressIcon;
    case RegrelloIconName.STAGE_NOT_STARTED:
      return StageNotStartedIcon;
    case RegrelloIconName.STAGE_SKIPPED:
      return StageSkippedIcon;
    case RegrelloIconName.STARRED:
      return StarredIcon;
    case RegrelloIconName.START_WORKFLOW:
      return StartWorkflowIcon;
    case RegrelloIconName.SUBMITTED:
      return SubmittedIcon;
    case RegrelloIconName.SUBTRACT:
      return SubtractIcon;
    case RegrelloIconName.SYSTEM_FIELD_PERSON:
      return SystemFieldPersonIcon;
    case RegrelloIconName.TABLE:
      return TableIcon;
    case RegrelloIconName.TAG:
      return TagIcon;
    case RegrelloIconName.TASK:
      return TaskIcon;
    case RegrelloIconName.TEAM:
      return TeamIcon;
    case RegrelloIconName.BLUEPRINT:
      return BlueprintIcon;
    case RegrelloIconName.TEXT_FIELD:
      return TextFieldIcon;
    case RegrelloIconName.UNDO:
      return UndoIcon;
    case RegrelloIconName.UNFOLD_LESS:
      return UnfoldLessIcon;
    case RegrelloIconName.UNFOLD_MORE:
      return UnfoldMoreIcon;
    case RegrelloIconName.UNLOCKED:
      return UnlockedIcon;
    case RegrelloIconName.UNPINNED:
      return UnpinnedIcon;
    case RegrelloIconName.UNSTARRED:
      return UnstarredIcon;
    case RegrelloIconName.DEPRECATED_UPCOMING:
      return DeprecatedUpcomingIcon;
    case RegrelloIconName.UPDATE_MANUAL:
      return UpdateManualIcon;
    case RegrelloIconName.UPDATE_SYNC:
      return UpdateSyncIcon;
    case RegrelloIconName.FILE_UPLOAD:
      return FileUploadIcon;
    case RegrelloIconName.VERIFIED:
      return VerifiedIcon;
    case RegrelloIconName.VERSIONS:
      return VersionsIcon;
    case RegrelloIconName.VIEW:
      return ViewIcon;
    case RegrelloIconName.VIEW_LOG:
      return ViewLogIcon;
    case RegrelloIconName.WARNING:
      return WarningIcon;
    case RegrelloIconName.WORKFLOW:
      return WorkflowIcon;
    case RegrelloIconName.WORKSPACE:
      return WorkspaceIcon;
    default:
      assertNever(iconName);
  }
}

export interface RegrelloIconProps
  extends WithDataTestId,
    WithClassName,
    Pick<RegrelloIconStylerProps, "displayInline" | "intent" | "variant"> {
  /** The unique name of the desired icon to display. */
  iconName: RegrelloIconName;

  /**
   * The size of the icon. If `inherit`, no sizing attempts are made.
   *
   * @default RegrelloSize.SMALL
   */
  size?: RegrelloSize | "inherit";
}

/** A standalone icon in Regrello. Renders an SVG that can be rendered anywhere in the UI. */
export const RegrelloIcon = React.memo<RegrelloIconProps>(function RegrelloIconFn({
  dataTestId,
  iconName,
  size = RegrelloSize.SMALL,
  ...stylerProps
}) {
  const IconSvgComponent = getIconSvgComponentByName(iconName);

  return (
    <RegrelloIconStyler size={size === "inherit" ? null : size} {...stylerProps}>
      <IconSvgComponent data-testid={dataTestId} />
    </RegrelloIconStyler>
  );
});

const allIconNames = Object.values(RegrelloIconName).sort();

/**
 * Utility function to get all icon names following kebab-case as an array
 */
// (dosipiuk): fast-refresh would not work for icons anyway, since all icon imports are de-facto treated as components
// eslint-disable-next-line react-refresh/only-export-components
export function getAllIconNames() {
  return [...allIconNames];
}

/** Utility type guard to determine if `value` is actually a `RegrelloIconName`. */
// eslint-disable-next-line react-refresh/only-export-components
export function isRegrelloIconName(value: string): value is RegrelloIconName {
  return allIconNames.includes(value as RegrelloIconName);
}
