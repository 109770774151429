import { assertNever, isDefined } from "@regrello/core-utils";
import type { FieldFields } from "@regrello/graphql-api";

import { getBasePartyTypeUnion, getPartyFullNameAndOrganization } from "../../../../../utils/parties/partyUtils";
import type { CustomFieldFrontendSelectableOption } from "../types/CustomFieldFrontendSelectableOption";

export function extractAllowedValuesForFrontend(field: FieldFields): CustomFieldFrontendSelectableOption[] {
  return field.allowedValues
    .map((allowedValue) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (allowedValue.__typename) {
        case "FieldAllowedValueString":
          return {
            id: allowedValue.id,
            value: allowedValue.stringValue,
          };
        case "FieldAllowedValueParty":
          return {
            id: allowedValue.id,
            value: getPartyFullNameAndOrganization(getBasePartyTypeUnion(allowedValue.partyValue)),
          };
        default:
          assertNever(allowedValue);
      }
    })
    .filter(isDefined);
}
