import { clsx, type WithClassName, type WithDataTestId } from "@regrello/core-utils";

export const RegrelloFormFieldSelectOptionContainer = ({
  children,
  className,
  dataTestId,
}: { children: React.ReactNode } & WithClassName & WithDataTestId) => {
  return (
    <div className={clsx("flex items-center justify-between w-full", className)} data-testid={dataTestId}>
      {children}
    </div>
  );
};
