import { clsx, mergeRefs } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldInstanceValueInputType } from "@regrello/graphql-api";
import { RegrelloDragHandle, RegrelloSwitch, useDndReorder } from "@regrello/ui-core";
import React, { useRef } from "react";
import type { FieldArrayWithId } from "react-hook-form";

import { CSS_CLASS_COLUMN_GAP, CSS_CLASS_WIDTH_IS_REQUIRED } from "./fieldInstanceRowConstants";
import type { RegrelloConfigureCustomFieldsForm } from "../RegrelloConfigureCustomFieldsForm";

export interface FieldInstanceRowItemProps {
  index: number;
  row: FieldArrayWithId<RegrelloConfigureCustomFieldsForm.Fields, "customFields", "id">;
  customFieldInstanceSelectComponent: React.ReactNode;
  fieldInstanceFormField: React.ReactNode;
  editRegrelloObjectButton: React.ReactNode;
  deleteButton: React.ReactNode;
  disallowSelectInputType: boolean;
  disallowEditFieldValue?: boolean;
  disallowDeleteFields: boolean;

  /**
   * Whether the input type switch is disabled.
   * @default false
   */
  isInputTypeSwitchDisabled?: boolean;
  handleInputTypeChange: (
    row: RegrelloConfigureCustomFieldsForm.Fields["customFields"][number],
    index: number,
    newValue: FieldInstanceValueInputType | null,
  ) => void;
  isDragEnabled: boolean;
  moveRow: (indexA: number, indexB: number) => void;
}

/**
 * A nice visual representation of  a field instance that can be shown in a vertical list. Includes
 * the field name, the field type, and the field icon.
 */
export const FieldInstanceRowItem = React.memo<FieldInstanceRowItemProps>(function FieldInstanceListItemFn({
  index,
  row,
  customFieldInstanceSelectComponent,
  fieldInstanceFormField,
  disallowSelectInputType,
  disallowEditFieldValue,
  disallowDeleteFields,
  handleInputTypeChange,
  editRegrelloObjectButton,
  isInputTypeSwitchDisabled = false,
  deleteButton,
  isDragEnabled,
  moveRow,
}) {
  const dropElementRef = useRef<HTMLElement | null>(null);
  const { dragRef, dropRef, dropState, onDragHandleMouseDown } = useDndReorder({
    dropElementRef: dropElementRef,
    isDragEnabled: isDragEnabled,
    item: { id: row.id, index },
    itemType: "fieldInstance",
    onDrop: (draggedItem, droppedOverItem, side) => {
      if (draggedItem.index < droppedOverItem.index && side === "above") {
        moveRow(draggedItem.index, droppedOverItem.index - 1);
      } else if (draggedItem.index > droppedOverItem.index && side === "above") {
        moveRow(draggedItem.index, droppedOverItem.index);
      } else if (draggedItem.index < droppedOverItem.index && side === "below") {
        moveRow(draggedItem.index, droppedOverItem.index);
      } else if (draggedItem.index > droppedOverItem.index && side === "below") {
        moveRow(draggedItem.index, droppedOverItem.index + 1);
      }
    },
  });

  const { field, inputType } = row;

  return (
    <div
      key={row.id}
      ref={mergeRefs(dropRef, dropElementRef)}
      className={clsx("flex gap-1 py-1 relative", {
        "after:content-[''] after:absolute after:inset-0 after:z-2 after:pointer-events-none after:ring-width--4 after:shadow-y":
          dropState.isDraggingOver && dropState.dropSide === "top",
        "after:content-[''] after:absolute after:inset-0 after:z-2 after:pointer-events-none after:ring-width-4 after:shadow-y":
          dropState.isDraggingOver && dropState.dropSide === "bottom",
      })}
    >
      {/* Drag handle */}
      {isDragEnabled ? (
        <RegrelloDragHandle
          className="items-start mt-1.5"
          dataTestId={DataTestIds.WORKFLOW_FIELD_INSTANCE_DRAG_HANDLE}
          dragRef={dragRef}
          isDragEnabled={isDragEnabled}
          onMouseDown={onDragHandleMouseDown}
        />
      ) : null}

      {/* Row content */}
      <div className={clsx("flex-1 flex min-w-0", CSS_CLASS_COLUMN_GAP)}>
        <div className="flex-1 min-w-0 flex gap-2">
          {/* Select a custom field to instantiate: */}
          {customFieldInstanceSelectComponent}

          {/* Show a value input for this field instance: */}
          {fieldInstanceFormField}

          {field?.regrelloObject != null ? editRegrelloObjectButton : null}
        </div>

        {/* Click to switch this custom field between OPTIONAL and REQUESTED */}
        {!disallowSelectInputType && (
          <div
            className={clsx("flex-none flex items-center", CSS_CLASS_WIDTH_IS_REQUIRED, {
              grow: !disallowEditFieldValue,
            })}
          >
            <RegrelloSwitch
              checked={
                inputType === FieldInstanceValueInputType.REQUESTED ||
                inputType === FieldInstanceValueInputType.PROVIDED
              }
              color="primary"
              dataTestId={DataTestIds.CUSTOM_FIELD_INPUT_TYPE_SWITCH}
              disabled={isInputTypeSwitchDisabled}
              onCheckedChange={() => {
                if (inputType === FieldInstanceValueInputType.OPTIONAL) {
                  handleInputTypeChange(row, index, FieldInstanceValueInputType.REQUESTED);
                } else {
                  handleInputTypeChange(row, index, FieldInstanceValueInputType.OPTIONAL);
                }
              }}
            />
          </div>
        )}

        {/* Click to delete this custom field: */}
        {!disallowDeleteFields && <div className="flex-none">{deleteButton}</div>}
      </div>
    </div>
  );
});
