import { clsx, EMPTY_STRING, intersperse, KeyNames } from "@regrello/core-utils";
import {
  RegrelloField,
  RegrelloInput,
  type RegrelloInputProps,
  RegrelloLinkify,
  type RegrelloSize,
} from "@regrello/ui-core";
import React, { useId } from "react";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldEmptyValueEmpty } from "./_internal/RegrelloFormFieldEmptyValueEmpty";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { getSelfContainedFormInternal } from "./_internal/selfContainedForm/getSelfContainedFormInternal";
import { RegrelloClampedText } from "../../atoms/clampedText/RegrelloClampedText";

export interface RegrelloFormFieldTextMultilineProps
  extends RegrelloFormFieldBaseProps<string>,
    Pick<RegrelloInputProps, "autoFocus" | "onBlur" | "onChange" | "onPaste" | "inputRef"> {
  /** Whether to prevent the user from inserting newlines on "Enter". */
  disableNewlines?: boolean;

  /** Whether the display value of a self-contained multiline form field component is collapsable. */
  isCollapsible?: boolean;

  /**
   * Whether the default maximum height should not be enforced. If `true`, the field will grow as
   * much as needed to display all rows of content without internal scrolling.
   */
  maxHeightDisabled?: boolean;

  /** Whether the input should start as a single line, which can expand. */
  minimumRowCount?: number;

  /**
   * If provided, will show an 'x' button in the input that invokes this callback when clicked.
   * Expected usage thereafter is for the callback to clear the input's value.
   */
  onClearClick?: () => void;

  /**
   * The size of the input field.
   * @default RegrelloSize.MEDIUM
   */
  size?: RegrelloSize;

  value: string;
}

export const RegrelloFormFieldTextMultiline = React.memo(function RegrelloFormFieldTextMultilineFn({
  className,
  dataTestId,
  disableNewlines,
  error,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  isDefaultMarginsOmitted,
  isDeleted,
  isCollapsible = false,
  isRequiredAsteriskShown,
  label,
  labelPlacement,
  labelWidth,
  maxHeightDisabled,
  minimumRowCount = 2,
  selfContainedForm,
  size,
  value,
  variant = "default",
  ...textFieldProps
}: RegrelloFormFieldTextMultilineProps) {
  const id = useId();
  const hasError = error != null;

  if (variant === "spectrum" && selfContainedForm == null) {
    return (
      <RegrelloField
        dataTestId={dataTestId}
        deleted={isDeleted}
        description={infoTooltipText}
        errorMessage={error}
        helperText={typeof helperText === "string" ? helperText : undefined}
        htmlFor={id}
        label={typeof label === "string" ? label : EMPTY_STRING}
        name={textFieldProps.name ?? EMPTY_STRING}
        required={isRequiredAsteriskShown}
      >
        {() => (
          <RegrelloInput
            {...textFieldProps}
            asChild={true}
            autoFocus={textFieldProps.autoFocus || selfContainedForm != null}
            className="h-auto"
            id={id}
            intent={hasError ? "danger" : "neutral"}
            size={size}
            value={value}
          >
            <textarea
              ref={(el) => {
                if (el != null) {
                  el.style.height = el.scrollHeight + "px";
                }
              }}
              className={clsx("my-2 resize-none", {
                "max-h-[16rem]": !maxHeightDisabled,
              })}
              onInput={(event) => {
                event.currentTarget.style.height = "auto";
                event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";
              }}
              onKeyDown={(event) => {
                if (disableNewlines && event.key === KeyNames.ENTER && !event.metaKey) {
                  // (clewis): Don't add a newline when "Enter" is pressed. Note that holding the meta key at
                  // the same time can trigger different reactions (e.g., submitting a form), so we enter this
                  // block only if the meta key isn't pressed.
                  event.preventDefault();
                }
              }}
              rows={minimumRowCount}
            />
          </RegrelloInput>
        )}
      </RegrelloField>
    );
  }

  return (
    <RegrelloFormFieldLayout
      className={className}
      dataTestId={dataTestId}
      error={error}
      helperText={helperText}
      htmlFor={id}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDefaultMarginsOmitted={isDefaultMarginsOmitted}
      isDeleted={isDeleted}
      isRequiredAsteriskShown={isRequiredAsteriskShown}
      label={label}
      labelPlacement={labelPlacement}
      labelWidth={labelWidth}
      selfContainedForm={getSelfContainedFormInternal(selfContainedForm, (savedValue) => {
        if (isCollapsible) {
          return (
            <RegrelloClampedText maxLines={selfContainedForm?.maxRowWhenCollapsed}>{savedValue}</RegrelloClampedText>
          );
        }

        return savedValue === EMPTY_STRING ? <RegrelloFormFieldEmptyValueEmpty /> : getDisplayValue(savedValue);
      })}
      variant={variant}
    >
      <RegrelloInput
        {...textFieldProps}
        asChild={true}
        autoFocus={textFieldProps.autoFocus || selfContainedForm != null}
        className="h-auto"
        id={id}
        intent={hasError ? "danger" : "neutral"}
        size={size}
        value={value}
      >
        <textarea
          ref={(el) => {
            if (el != null) {
              el.style.height = el.scrollHeight + "px";
            }
          }}
          className={clsx("my-2 resize-none", {
            "max-h-[16rem]": !maxHeightDisabled,
          })}
          onInput={(event) => {
            event.currentTarget.style.height = "auto";
            event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";
          }}
          onKeyDown={(event) => {
            if (disableNewlines && event.key === KeyNames.ENTER && !event.metaKey) {
              // (clewis): Don't add a newline when "Enter" is pressed. Note that holding the meta key at
              // the same time can trigger different reactions (e.g., submitting a form), so we enter this
              // block only if the meta key isn't pressed.
              event.preventDefault();
            }
          }}
          rows={minimumRowCount}
        />
      </RegrelloInput>
    </RegrelloFormFieldLayout>
  );
});

function getDisplayValue(textMultilineValue: string): React.ReactNode {
  const valueWithLineBreaks = intersperse<React.ReactNode>(textMultilineValue.split("\n"), {
    type: "callback",
    // eslint-disable-next-line react/display-name
    callback: (_, leftIndex) => <br key={`br=${leftIndex}`} />,
  });

  return (
    <div>
      <RegrelloLinkify>{valueWithLineBreaks}</RegrelloLinkify>
    </div>
  );
}
