export enum RouteQueryStringKeys {
  EMAIL = "email",
  /** Used by Auth0 when redirecting back with an error. */
  AUTH0_ERROR_DESCRIPTION = "error_description",
  COMMENT_ID = "comment-id",
  INITIAL_SECONDARY_TAB = "initial-secondary-tab",
  PAGE = "page",
  REDIRECT = "redirect",
  SORT = "sort",
  STATUS = "status",
  SUPPLIER = "supplier",
  TAB = "tab",
  TAG_TYPE_ID = "tag-type-id",
  TASK_ID = "task-id",
  TOKEN = "token",
  USER_VERIFICATION_UUID = "verification-uuid",
  UUID = "uuid",
  VIEW_ID = "view-id",
  IS_PUBLIC = "is-public",
  SEARCH = "search",
  WORKFLOW_ID = "workflow-id",
  // Used in `request for email` page
  SOURCE = "source",
  JOB_UUID = "job-uuid",
}

export enum RoutePaths {
  HOME = "/",
  HOME_WHEN_INBOX_ENABLED = "/workflows",

  WORKSPACE = "/workspace",

  CREDENTIALS_ERROR = "/credentials-error",

  // My work
  MY_TASKS = "/my/tasks",

  // Tasks
  ASSIGNED_TASKS = "/assigned-tasks",
  CCD_TASKS = "/ccd-tasks",

  // Starred
  STARRED = "/starred",
  DOCUMENTS = "/documents",

  // Blueprints
  BLUEPRINTS = "/blueprints",
  TEMPLATES_MY_WORKFLOW_TEMPLATES = "/templates/my-workflow-templates",
  TEMPLATES_COMPANY_WORKFLOW_TEMPLATES = "/templates/company-workflow-templates",

  // TODO (anthony): Rename these.
  GENERATE = "/templates/generate",
  IMPORT = "/templates/import",

  // Custom fields
  CUSTOM_FIELDS = "/custom-fields",

  // Spectrum forms
  SPECTRUM_FORMS = "/forms",
  SPECTRUM_FORM_BUILDER = "/forms/form-builder",
  SPECTRUM_FORM_GENERATE = "/forms/generate",

  // Tags
  TAGS = "/tags",

  // People and Teams
  PEOPLE = "/people",
  TEAMS = "/teams",

  // ADMIN
  ADMIN = "/admin",
  SYNC_LOGS = "/admin/sync-logs",

  // OAuth redirects
  // ===============
  // These prefixes must stay in sync with OAUTH_CALLBACK_ROUTE_PREFIX.
  OAUTH_REDIRECT_BOX = "/callback/box",
  OAUTH_REDIRECT_DOCUSIGN = "/callback/docusign",
  OAUTH_REDIRECT_DOCUSIGN_DEVELOPER = "/callback/docusign-developer",

  // Unauthenticated
  LOGIN = "/login",
  LOGIN_SSO = "/login-sso",
  SIGN_UP = "/sign-up-regrello-app", // (hchen): Make the sign up URL a little more difficult to guess per Derek's suggestion
  INVITE_SENT = "/invite-sent",
  INVITE_REQUEST = "/invite-request",
  PASSWORD_RESET_SENT = "/password-reset-sent",
  PASSWORD_RESET_REQUEST = "/password-reset-request",
  VERIFICATION_SENT = "/verification-sent",

  // Regrello-Only
  INTERNAL_GRANULAR_ACCESS = "/internal/granular_access",

  // Submit task without logging in (Regrello Lite)
  SUBMISSION = "/submission",

  // Request a new verification code.
  REQUEST_VERIFICATION = "/request-verification",

  // Developer-only page to facilitate testing.
  DEVELOPER = "/developer",
}

export enum RoutePathsWithId {
  BLUEPRINTS = "/blueprints",

  // People and Teams.
  /**
   * `PEOPLE` must match the format of `model/user.go#GetPersonDetailViewDeepLinkTail` for search
   * results to link correctly from Typesense.
   */
  PEOPLE = "/people",
  /**
   * `TEAMS` must match the format of `model/team.go#GetTeamDetailViewDeepLinkTail` for search
   * results to link correctly from Typesense.
   */
  TEAMS = "/teams",

  // Workflow Pages
  WORKFLOW = "/workflow",
  WORKFLOW_TEMPLATE = "/workflow-template",
}
