import { clsx, swallowChildMouseEvent, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import React from "react";
import type { ConnectDragSource } from "react-dnd";

import { RegrelloIcon } from "../icons/RegrelloIcon";

export interface RegrelloDragHandleProps extends WithDataTestId, WithClassName {
  /**
   * A `dragRef` from `react-dnd`'s `useDrag` hook. This is what wires up this component for use in
   * drag-and-drop interactions.
   */
  dragRef: ConnectDragSource;

  /**
   * Whether the drag handle should appear (if `true`) or be visually hidden (if `false`). To avoid
   * jittering parent layouts, the handle will always be mounted even if it's visually hidden.
   */
  isDragEnabled: boolean;

  /**
   * Whether to show the handle as if it were being hovered.
   *
   * @default false
   */
  isHovered?: boolean;

  /** Callback to invoke when the user mouses down in the drag handle. See `useDndReorder`. */
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * A simple drag handle that can be attached to any component in the application. Designed to work
 * specifically with React DND (for greppability: react-dnd).
 */
export const RegrelloDragHandle = React.memo<RegrelloDragHandleProps>(function RegrelloDragHandleFn({
  className,
  dataTestId,
  dragRef,
  isDragEnabled,
  isHovered = false,
  onMouseDown,
}) {
  return (
    <div
      ref={dragRef}
      className={clsx(
        [
          "flex items-center justify-center shrink-0 overflow-hidden w-4 opacity-0 text-neutral-icon",
          "hover:opacity-100 hover:text-neutral-iconHovered hover:cursor-grab",
          "active:cursor-grabbing",
        ],
        {
          invisible: !isDragEnabled,
          "opacity-100": isHovered,
        },
        className,
      )}
      data-testid={dataTestId}
      onClick={isDragEnabled ? swallowChildMouseEvent : undefined}
      onMouseDown={isDragEnabled ? onMouseDown : undefined}
      onMouseUp={isDragEnabled ? swallowChildMouseEvent : undefined}
    >
      <RegrelloIcon iconName="drag-handle-vertical" size="medium" />
    </div>
  );
});
