import { ApolloError, type LazyQueryResult, type OperationVariables, type QueryResult } from "@apollo/client";
import { t } from "@lingui/macro";
import { EMPTY_ARRAY } from "@regrello/core-utils";

export type AsyncLoaded_NotLoaded = {
  type: "notLoaded";
};

export type AsyncLoaded_Loading<T> = {
  type: "loading";
  previousValue?: T;
};

export type AsyncLoaded_Loaded<T> = {
  type: "loaded";
  value: T;
};

export type AsyncLoaded_Error<E extends Error = Error> = {
  type: "error";
  error: E;
};

/**
 * A utility type that serves as a tuple of (loading state, loaded value). This type is a nicer and
 * more expressive alternative to the pattern of sending in two sibling props — `isFooValueLoading:
 * boolean` and `fooValue: T | undefined` - into a component.
 */
export type AsyncLoaded<T, E extends Error = Error> =
  | AsyncLoaded_NotLoaded
  | AsyncLoaded_Loading<T>
  | AsyncLoaded_Loaded<T>
  | AsyncLoaded_Error<E>;

export function isNotLoaded<T, E extends Error = Error>(value: AsyncLoaded<T, E>): value is AsyncLoaded_NotLoaded {
  return value.type === "notLoaded";
}

export function isLoading<T, E extends Error = Error>(value: AsyncLoaded<T, E>): value is AsyncLoaded_Loading<T> {
  return value.type === "loading";
}

export function isLoaded<T, E extends Error = Error>(value: AsyncLoaded<T, E>): value is AsyncLoaded_Loaded<T> {
  return value.type === "loaded";
}

export function isError<T, E extends Error = Error>(value: AsyncLoaded<T, E>): value is AsyncLoaded_Error<E> {
  return value.type === "error";
}

function isReady<T, E extends Error = Error>(
  value: AsyncLoaded<T, E>,
): value is AsyncLoaded_Loaded<T> | AsyncLoaded_Error<E> {
  return isLoaded(value) || isError(value);
}

function isInitialLoad<T, E extends Error = Error>(value: AsyncLoaded<T, E>): boolean {
  return isLoading(value) && value.previousValue === undefined;
}

function loadedValueOrFallback<T>(value: AsyncLoaded<T>, defaultValue: T): T {
  return isLoaded(value)
    ? value.value
    : isLoading(value) && value.previousValue != null
      ? value.previousValue
      : defaultValue;
}

export function notLoaded(): AsyncLoaded_NotLoaded {
  return { type: "notLoaded" };
}

function loading<T>(previousValue?: T): AsyncLoaded_Loading<T> {
  return { type: "loading", previousValue };
}

export function loaded<T>(value: T): AsyncLoaded_Loaded<T> {
  return { type: "loaded", value };
}

export function error<E extends Error>(errorParam: E): AsyncLoaded_Error<E> {
  return { type: "error", error: errorParam };
}

/**
 * Assuming T is an array type, returns a new `AsyncLoaded` that concatenates all the values from
 * the provided `asyncLoaded`s once they've all finished loading. Unlike `composeFresh`, this will
 * return `previousValue`s if any of the `asyncLoaded`s are still loading.
 */
function compose<T extends unknown[], E extends Error>(...asyncLoadeds: Array<AsyncLoaded<T, E>>): AsyncLoaded<T, E> {
  // Return error if any of the asyncLoadeds are errors.
  for (const asyncLoaded of asyncLoadeds) {
    if (isError(asyncLoaded)) {
      return error(asyncLoaded.error);
    }
  }
  // Return loading if any of the asyncLoadeds are loading.
  let isSomeLoading = false;
  const previousDatas: T[] = [];
  for (const asyncLoaded of asyncLoadeds) {
    if (isLoading(asyncLoaded)) {
      if (asyncLoaded.previousValue != null) {
        // @ts-expect-error: We know that value is an array type, but TypeScript can't figure that out.
        previousDatas.push(...asyncLoaded.previousValue);
      }
      isSomeLoading = true;
    } else if (isLoaded(asyncLoaded)) {
      // (clewis): Combine previousData and loaded data since the AsyncLoaded's may not all be
      // reloading at exactly the same time, and we don't want values to disappear from the ones
      // that haven't started reloading yet.
      //
      // @ts-expect-error: We know that value is an array type, but TypeScript can't figure that out.
      previousDatas.push(...asyncLoaded.value);
    }
  }
  if (isSomeLoading) {
    // (clewis): Ignore ts(2322): 'T' could be instantiated with an arbitrary type which could be
    // unrelated to 'T[]'. We already know T is an array, so this is fine.
    return AsyncLoaded.loading(previousDatas as unknown as T);
  }

  // Now they're either all notLoaded...
  for (const asyncLoaded of asyncLoadeds) {
    if (isNotLoaded(asyncLoaded)) {
      return notLoaded();
    }
  }

  // Or they're all loaded, and we should combine their values.
  const result: T[] = [];
  for (const asyncLoaded of asyncLoadeds) {
    if (isLoaded(asyncLoaded)) {
      // @ts-expect-error: We know that value is an array type, but TypeScript can't figure that out.
      result.push(...asyncLoaded.value);
    }
  }
  // (clewis): Ignore ts(2322): 'T' could be instantiated with an arbitrary type which could be
  // unrelated to 'T[]'. We already know T is an array, so this is fine.
  return loaded(result) as unknown as AsyncLoaded<T, E>;
}
/**
 * Assuming T is an array type, returns a new `AsyncLoaded` that concatenates all the values from
 * the provided `asyncLoaded`s once they've all finished loading. Unlike `compose`, this will ignore
 * previous values and return only freshly loaded data.
 */
function composeFresh<T extends unknown[], E extends Error>(
  ...asyncLoadeds: Array<AsyncLoaded<T, E>>
): AsyncLoaded<T, E> {
  for (const asyncLoaded of asyncLoadeds) {
    if (isError(asyncLoaded)) {
      return error(asyncLoaded.error);
    }
  }
  for (const asyncLoaded of asyncLoadeds) {
    if (isLoading(asyncLoaded)) {
      return AsyncLoaded.loading();
    }
  }
  for (const asyncLoaded of asyncLoadeds) {
    if (isNotLoaded(asyncLoaded)) {
      return notLoaded();
    }
  }
  const result: T[] = [];
  for (const asyncLoaded of asyncLoadeds) {
    if (isLoaded(asyncLoaded)) {
      // @ts-expect-error: We know that value is an array type, but TypeScript can't figure that out.
      result.push(...asyncLoaded.value);
    }
  }
  // (clewis): Ignore ts(2322): 'T' could be instantiated with an arbitrary type which could be
  // unrelated to 'T[]'. We already know T is an array, so this is fine.
  return loaded(result) as unknown as AsyncLoaded<T, E>;
}

/**
 * Converts a GraphQL query result into a more ergonomic `AsyncLoaded` type. Provide `transformData`
 * if you wish to transform the loaded data before storing it in the returned `AsyncLoaded` wrapper
 * (e.g., to extract a particular value).
 */
function fromGraphQlQueryResult<
  TData,
  TTransformedData = TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  queryResult: QueryResult | LazyQueryResult<TData, TVariables>,
  transformData?: (data: TData) => TTransformedData,
): AsyncLoaded<TTransformedData, ApolloError> {
  if (!queryResult.called) {
    return AsyncLoaded.notLoaded();
  }
  if (queryResult.loading) {
    const typedPreviousData = queryResult.previousData as TData | null | undefined;
    return AsyncLoaded.loading(
      typedPreviousData == null
        ? undefined
        : transformData != null
          ? transformData(typedPreviousData)
          : // (clewis): There's a better way to type this without casting, but this is okay for now.
            (typedPreviousData as unknown as TTransformedData),
    );
  }
  if (queryResult.error != null) {
    return AsyncLoaded.error(queryResult.error);
  }
  if (queryResult.data != null) {
    return AsyncLoaded.loaded(transformData != null ? transformData(queryResult.data) : queryResult.data);
  }
  return AsyncLoaded.error(
    new ApolloError({
      graphQLErrors: EMPTY_ARRAY,
      networkError: null,
      errorMessage: t`Failed to create AsyncLoaded wrapper because of seemingly invalid query result state`,
      extraInfo: { queryResult },
    }),
  );
}

/** Converts an existing `AsyncLoaded` into a new one with a transformed value type. */
function transform<TData, E extends Error, TTransformedData = TData>(
  asyncLoaded: AsyncLoaded<TData, E>,
  transformData: (data: TData) => TTransformedData,
): AsyncLoaded<TTransformedData, E> {
  if (isNotLoaded(asyncLoaded)) {
    return AsyncLoaded.notLoaded();
  }
  if (isLoading(asyncLoaded)) {
    return AsyncLoaded.loading(
      asyncLoaded.previousValue == null ? undefined : transformData(asyncLoaded.previousValue),
    );
  }
  if (isError(asyncLoaded)) {
    return AsyncLoaded.error(asyncLoaded.error);
  }
  return AsyncLoaded.loaded(transformData(asyncLoaded.value));
}

export interface AsyncLoadedVisitor<TReturn, TValue, TError extends Error = Error> {
  notLoaded: () => TReturn;
  loading: (previousValue: TValue | undefined) => TReturn;
  error: (error: TError) => TReturn;
  loaded: (value: TValue) => TReturn;
  unknown: (value: never) => TReturn;
}

function visit<TReturn, TValue, TError extends Error = Error>(
  obj: AsyncLoaded<TValue, TError>,
  visitor: AsyncLoadedVisitor<TReturn, TValue, TError>,
): TReturn {
  if (AsyncLoaded.isNotLoaded(obj)) {
    return visitor.notLoaded();
  }
  if (AsyncLoaded.isLoading(obj)) {
    return visitor.loading(obj.previousValue);
  }
  if (AsyncLoaded.isError(obj)) {
    return visitor.error(obj.error);
  }
  if (AsyncLoaded.isLoaded(obj)) {
    return visitor.loaded(obj.value);
  }
  return visitor.unknown(obj);
}

function render<TValue, TError extends Error = Error>(
  obj: AsyncLoaded<TValue, TError>,
  visitor: AsyncLoadedVisitor<React.ReactNode, TValue, TError>,
): React.ReactNode {
  return visit(obj, visitor);
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsyncLoaded = {
  isNotLoaded,
  isLoading,
  isLoaded,
  isError,
  isReady,
  isInitialLoad,

  notLoaded,
  loading,
  loaded,
  error,

  loadedValueOrFallback,
  fromGraphQlQueryResult,
  transform,

  compose,
  composeFresh,

  visit,
  render,
};
