import type { FieldInstanceFields, FieldInstanceValueFields } from "@regrello/graphql-api";

import type { FieldInstanceBaseFields } from "../../../../../types";
import { getErrorMessageWithPayload } from "../../../../../utils/getErrorMessageWithPayload";

export function extractAtMostOneValueOrThrow<
  TReturnValue,
  TFieldInstanceValueTypeName extends FieldInstanceValueFields["__typename"],
  TFieldInstanceValue extends Extract<FieldInstanceValueFields, { __typename: TFieldInstanceValueTypeName }>,
>(params: {
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields;
  fieldInstanceValueTypeName: TFieldInstanceValueTypeName | TFieldInstanceValueTypeName[];
  errorMessageIfMultipleValues: string;
  errorMessageIfWrongValueType: string;
  getterIfNoValue: () => TReturnValue;
  getterIfValue: (fieldInstanceValue: TFieldInstanceValue) => TReturnValue;
}): TReturnValue {
  const {
    fieldInstance,
    fieldInstanceValueTypeName,
    errorMessageIfMultipleValues,
    errorMessageIfWrongValueType,
    getterIfNoValue,
    getterIfValue,
  } = params;

  const { values } = fieldInstance;

  if (values.length > 1) {
    throw new Error(getErrorMessageWithPayload(errorMessageIfMultipleValues, { fieldInstance }));
  }

  if (values.length === 0) {
    return getterIfNoValue();
  }

  const representativeSingleValue = values[0];

  const permittedTypeNames =
    typeof fieldInstanceValueTypeName === "string" ? [fieldInstanceValueTypeName] : fieldInstanceValueTypeName;

  if (!permittedTypeNames.includes(representativeSingleValue.__typename as TFieldInstanceValueTypeName)) {
    throw new Error(getErrorMessageWithPayload(errorMessageIfWrongValueType, { fieldInstance }));
  }

  // (clewis): TS isn't smart enough to know that the type has been narrowed properly based on the
  // __typename, but it works as expected in practice.
  //
  return getterIfValue(representativeSingleValue as TFieldInstanceValue);
}
