import LinkifyIt, { type Options } from "linkify-it";
import React, { type ReactNode, useMemo } from "react";
import tlds from "tlds";

const LINKIFY_OPTIONS = {
  fuzzyIP: false,
  fuzzyLink: false,
};

const MAX_URL_DISPLAY_LENGTH = 50;

// (krashanoff): We keep a global instance of `LinkifyIt` configured to our defaults
// to prevent memoizing a new instance every time the component is used. Since the
// RegrelloLinkify component is used so frequently, doing this is a boon for memory
// consumption.
const globalDefaultLinkify = new LinkifyIt().tlds(tlds).set(LINKIFY_OPTIONS);

export interface LinkifyProps {
  children?: ReactNode;

  /** Attributes applied to all anchor tags rendered by the component */
  linkAttributes?: React.AnchorHTMLAttributes<HTMLAnchorElement>;

  /**
   * Options to pass to the `LinkifyIt` instance. **Be careful when doing this;
   * passing non-default options without a `linkifyInstance` will create a new
   * `LinkifyIt` per instantiation of the component!** This leads to rapid
   * memory consumption.
   */
  linkifyOptions?: Options;

  /** Instance to use in place of the global default LinkifyIt, or one constructed on-the-fly. */
  linkifyInstance?: LinkifyIt;
}

export const Linkify = React.memo<LinkifyProps>(function RegrelloLinkifyFn({
  linkAttributes,
  linkifyOptions,
  linkifyInstance,
  children,
}) {
  const linkify = useMemo(
    () =>
      linkifyInstance != null
        ? linkifyInstance.set(linkifyOptions ?? {})
        : linkifyOptions != null
          ? new LinkifyIt().tlds(tlds).set(linkifyOptions)
          : globalDefaultLinkify,
    [linkifyInstance, linkifyOptions],
  );

  const parseString = (text: string): ReactNode => {
    if (text === "") {
      return text;
    }

    const matches = linkify.match(text) ?? [];
    if (!matches) {
      return text;
    }

    const elements = [];
    let lastIndex = 0;
    matches.forEach((match, i) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(text.substring(lastIndex, match.index));
      }

      const isLongDisplayUrl = match.text === match.url && match.url.length > MAX_URL_DISPLAY_LENGTH;

      const decoratedComponent = (
        <a key={i} href={match.url} {...linkAttributes} title={isLongDisplayUrl ? match.url : undefined}>
          {/* (clewis): Truncate long URLs to avoid layout issues. */}
          {isLongDisplayUrl ? `${match.url.substring(0, MAX_URL_DISPLAY_LENGTH)}...` : match.text}
        </a>
      );

      elements.push(decoratedComponent);

      lastIndex = match.lastIndex;
    });

    // Push remaining text if there is any
    if (text.length > lastIndex) {
      elements.push(text.substring(lastIndex));
    }

    return elements;
  };

  const parse = (childNodes: ReactNode, key = 0): ReactNode => {
    if (typeof childNodes === "string") {
      return parseString(childNodes);
    }
    if (React.isValidElement(childNodes) && childNodes.type !== "a" && childNodes.type !== "button") {
      return React.cloneElement(childNodes, { key: key }, parse(childNodes.props.children));
    }
    if (Array.isArray(childNodes)) {
      return childNodes.map((child, i) => parse(child, i));
    }
    return childNodes;
  };
  // (zstanik): Force links to wrap instead of overflowing their parent container.
  return <span className="max-w-full break-words">{children && parse(children)}</span>;
});
