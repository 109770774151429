/** An enumeration of `event.key` names, so you don't have to keep looking them up online. */
// (clewis): Please add additional names as you need them, ordered alphabetically by enum key.
/** @knipignore */
export enum KeyNames {
  ARROW_DOWN = "ArrowDown",
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",
  ARROW_UP = "ArrowUp",
  BACKSPACE = "Backspace",
  COMMA = ",",
  DELETE = "Delete",
  ENTER = "Enter",
  ESCAPE = "Escape",
  SHIFT = "Shift",
  SPACE = " ",
  TAB = "Tab",
}
