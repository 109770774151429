import type { ValuesUnion } from "@regrello/core-utils";

/** Names of metrics to report to Pendo, one of our 3rd-party metrics services. */
export const PendoMetricName = {
  STAR_ACTION_ITEM: "star-action-item",
  STAR_WORKFLOW: "star-workflow",
  STAR_WORKFLOW_TEMPLATE: "star-workflow-template",
  UNSTAR_ACTION_ITEM: "unstar-action-item",
  UNSTAR_WORKFLOW: "unstar-workflow",
  UNSTAR_WORKFLOW_TEMPLATE: "unstar-workflow-template",
  USER_SAVED_VIEW_ADD: "user-saved-view-add",
  USER_SAVED_VIEW_CLICK: "user-saved-view-click",
  USER_SAVED_VIEW_REMOVE: "user-saved-view-remove",

  // Someone tries to sign in with the "automatic sign-in" feature.
  SUPPLIER_AUTO_SIGN_IN: "supplier-auto-sign-in",
  SIGN_IN_WITH_SSO: "sign-in-with-sso",
  SIGN_IN_WITH_GOOGLE: "sign-in-with-google",
  FORGOT_PASSWORD: "forgot-password",

  PAUSE_END_WORKFLOW_PAUSE: "pause-end-workflow-pause",
  PAUSE_END_WORKFLOW_SEND_NOTIFICATION_WHEN_PAUSED: "pause-end-workflow-send-notification-when-paused",
  PAUSE_END_WORKFLOW_DO_NOT_SHIFT_DATE: "pause-end-workflow-do-not-shift-date",
  PAUSE_END_WORKFLOW_SHIFT_DATE: "pause-end-workflow-shift-date",
  PAUSE_END_WORKFLOW_SHIFT_DATE_HELPER_TEXT_HOVER: "pause-end-workflow-shift-date-helper-text-hover",
  PAUSE_END_WORKFLOW_END: "pause-end-workflow-end",
  PAUSE_END_WORKFLOW_END_AND_DELETE: "pause-end-workflow-end-and-delete",
  PAUSE_END_WORKFLOW_SEND_NOTIFICATION_WHEN_ENDED: "pause-end-workflow-send-notification-when-ended",
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PendoMetricName = ValuesUnion<typeof PendoMetricName>;
