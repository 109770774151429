import { useEffect, useState } from "react";

import type { ValuesUnion } from "../types/ValuesUnion";

// (dosipiuk): this should be kept in sync with `UnoCSS`
export const MEDIA_QUERY = {
  SM: "(min-width: 640px)",
  MD: "(min-width: 768px)",
  LG: "(min-width: 1024px)",
  XL: "(min-width: 1280px)",
  XXL: "(min-width: 1536px)",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MEDIA_QUERY = ValuesUnion<typeof MEDIA_QUERY>;

export const useMediaQuery = (query: MEDIA_QUERY) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};
