import { assertNever, clsx, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import React, { useCallback } from "react";

import { RegrelloIntent } from "../../utils/enums/RegrelloIntent";

const DEFAULT_BODY_COMPONENT_TAG: React.ElementType = "p";

export type RegrelloTypographyProps = WithClassName &
  WithDataTestId & {
    /** The text to display. */
    children: React.ReactNode;

    /**
     * Whether to restrict the text to one line and show ellipsis if the text overflows.
     * @default false
     */
    noWrap?: boolean;
  } & (
    | {
        /** The large heading variant to display. */
        variant: "h1" | "h2" | "h3" | "h4" | "h5";
      }
    | {
        /**
         * Whether the heading text should be visibly muted and uppercase. Can be useful for subtle
         * group headings.
         */
        mutedUppercase?: boolean;

        /** The smaller heading variant to display. */
        variant: "h6" | "h7";
      }
    | {
        /**
         * The wrapper component to use for the body text.
         *
         * ___Note:___ Prefer `span` and `p` whenever possible, because they're the most accessible.
         * Only use `div` if you need to apply typography to an element that may have arbitrary
         * elements as children.
         *
         * @default "p"
         */
        component?: React.ElementType;

        /**
         * Whether to permit auto-hyphenation, line-breaking within words when they don't fit at the
         * end of a line.
         *
         * @default true // (clewis): Ideally `false` but there's legacy code that depends on this.
         */
        hyphenate?: boolean;

        /**
         * The semantic intent for this text. Controls the text color.
         * @default RegrelloIntent.NEUTRAL
         */
        intent?: RegrelloIntent;

        /**
         * Whether the formatting white spaces including line breaks and newlines should be preserved.
         */
        isWhitespacePreserved?: boolean;

        /** Whether the body text should be visibly muted. Ignored if `intent` is set. */
        muted?: boolean;

        /**
         * Whether to show this body text as uppercase. Can be useful to use as an overline above a
         * heading.
         */
        uppercase?: boolean;

        /**
         * The font weight to use.
         *
         * __Note (clewis):__ `medium` is intentionally not supported because I'm trying to reduce
         * the number of font weights we use in the codebase.
         */
        weight?: "semi-bold" | "normal";

        /**
         * The body-text variant to display. In general, prefer `body` or `body-xs` for the vast
         * majority of cases. `body-sm` exists between these two sizes and is offered solely as an
         * escape hatch in rare cases when the previous two sizes don't quite work well.
         * @default "body"
         */
        variant?: "body" | "body-sm" | "body-xs";
      }
  );

/** Renders standard typography for headings and body text. */
export const RegrelloTypography = React.memo<RegrelloTypographyProps>(
  React.forwardRef(function RegrelloTypographyFn(
    props: RegrelloTypographyProps,
    ref: React.ForwardedRef<HTMLHeadingElement>,
  ) {
    const renderLargeHeading = useCallback(
      (largeHeadingProps: { variant: "h1" | "h2" | "h3" | "h4" | "h5" }) => {
        const baseClassName = clsx(
          "font-semibold",
          {
            truncate: props.noWrap,
          },
          props.className,
        );

        switch (largeHeadingProps.variant) {
          case "h1":
            return (
              <h1 ref={ref} className={clsx("text-3xl ", baseClassName)} data-testid={props.dataTestId}>
                {props.children}
              </h1>
            );
          case "h2":
            return (
              <h2 ref={ref} className={clsx("text-2xl", baseClassName)} data-testid={props.dataTestId}>
                {props.children}
              </h2>
            );
          case "h3":
            return (
              <h3 ref={ref} className={clsx("text-xl", baseClassName)} data-testid={props.dataTestId}>
                {props.children}
              </h3>
            );
          case "h4":
            return (
              <h4 ref={ref} className={clsx("text-lg", baseClassName)} data-testid={props.dataTestId}>
                {props.children}
              </h4>
            );
          case "h5":
            return (
              <h5 ref={ref} className={clsx("text-base", baseClassName)} data-testid={props.dataTestId}>
                {props.children}
              </h5>
            );
          default:
            return assertNever(largeHeadingProps.variant);
        }
      },
      [props.children, props.className, props.dataTestId, props.noWrap, ref],
    );

    const renderSmallHeading = useCallback(
      (smallHeadingProps: { variant: "h6" | "h7"; children: React.ReactNode; mutedUppercase?: boolean }) => {
        const baseProps = {
          className: clsx(
            "font-semibold",
            {
              "text-textMuted uppercase": smallHeadingProps.mutedUppercase,
              truncate: props.noWrap,
            },
            props.className,
          ),
          "data-testid": props.dataTestId,
        };

        switch (smallHeadingProps.variant) {
          case "h6":
            return (
              <h6 ref={ref} {...baseProps} className={clsx("text-sm", baseProps.className)}>
                {smallHeadingProps.children}
              </h6>
            );
          case "h7":
            return (
              // (clewis): There's no such thing as 'h7', so we have to use 'p'.
              <p ref={ref} {...baseProps} className={clsx("text-xs", baseProps.className)}>
                {smallHeadingProps.children}
              </p>
            );
          default:
            return assertNever(smallHeadingProps.variant);
        }
      },
      [props.className, props.dataTestId, props.noWrap, ref],
    );

    const renderBodyText = useCallback(
      (bodyTextProps: {
        children: React.ReactNode;
        component?: React.ElementType;
        hyphenate?: boolean;
        intent?: RegrelloIntent;
        isWhitespacePreserved?: boolean;
        muted?: boolean;
        uppercase?: boolean;
        weight: "semi-bold" | "normal";
        variant: "body" | "body-sm" | "body-xs";
      }) => {
        const className = clsx(
          {
            "hyphens-auto": bodyTextProps.hyphenate,
            "text-sm": bodyTextProps.variant === "body",
            "text-[13px] leading-[18px]": bodyTextProps.variant === "body-sm", // (clewis): This should be a rarer size.
            "text-xs": bodyTextProps.variant === "body-xs",

            "font-semibold": bodyTextProps.weight === "semi-bold",
            "font-normal": bodyTextProps.weight === "normal",

            "text-textMuted": bodyTextProps.muted && bodyTextProps.intent == null,
            "text-primary-text": bodyTextProps.intent === RegrelloIntent.PRIMARY,
            "text-success-text": bodyTextProps.intent === RegrelloIntent.SUCCESS,
            "text-warning-text": bodyTextProps.intent === RegrelloIntent.WARNING,
            "text-danger-text": bodyTextProps.intent === RegrelloIntent.DANGER,
            "text-primary-textMuted": bodyTextProps.muted && bodyTextProps.intent === RegrelloIntent.PRIMARY,
            "text-success-textMuted": bodyTextProps.muted && bodyTextProps.intent === RegrelloIntent.SUCCESS,
            "text-warning-textMuted": bodyTextProps.muted && bodyTextProps.intent === RegrelloIntent.WARNING,
            "text-danger-textMuted": bodyTextProps.muted && bodyTextProps.intent === RegrelloIntent.DANGER,

            "whitespace-pre-line": bodyTextProps.isWhitespacePreserved,
            uppercase: bodyTextProps.uppercase,
            truncate: props.noWrap,
          },
          props.className,
        );

        const Comp = bodyTextProps.component ?? DEFAULT_BODY_COMPONENT_TAG;

        return (
          <Comp ref={ref} className={className} data-testid={props.dataTestId}>
            {bodyTextProps.children}
          </Comp>
        );
      },
      [props.className, props.dataTestId, props.noWrap, ref],
    );

    switch (props.variant) {
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
        return renderLargeHeading(props);
      case "h6":
      case "h7":
        return renderSmallHeading(props);
      default:
        return renderBodyText({
          ...props,
          // Fall back to defaults here to simplify code in `renderBodyText`:
          hyphenate: props.hyphenate ?? true,
          variant: props.variant ?? "body",
          weight: props.weight ?? "normal",
        });
    }
  }),
);
