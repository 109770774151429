import { clsx, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import React from "react";

import { RegrelloDataGrid, type RegrelloDataGridData, type ResolvedColumnDef } from "./RegrelloDataGrid";
import { RegrelloButton } from "../../atoms/button/RegrelloButton";
import { RegrelloIcon } from "../../atoms/icons/RegrelloIcon";
import {
  RegrelloPopoverAnchor,
  RegrelloPopoverClose,
  RegrelloPopoverContent,
  RegrelloPopoverRoot,
  RegrelloPopoverTrigger,
} from "../../atoms/popover/RegrelloPopover";

export interface RegrelloDataGridWithPopoverProps<TData extends RegrelloDataGridData<TData>>
  extends WithDataTestId,
    WithClassName {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  columns: Array<ResolvedColumnDef<TData, any>>;
  data: TData[];
  onClose?: () => void;
  withPopoverOnly?: boolean;
}

const RegrelloDataGridWithPopoverInternal = function RegrelloDataGridWithPopoverFn<
  TData extends RegrelloDataGridData<TData>,
>({ className, dataTestId, withPopoverOnly, onClose, columns, data }: RegrelloDataGridWithPopoverProps<TData>) {
  const renderToggleButton = (toggleButtonIcon: "open-fullscreen" | "close-fullscreen") => (
    <RegrelloButton
      aria-label={toggleButtonIcon}
      className="hidden absolute top-1 right-1 p-1.5 opacity-50 bg-neutral-solid/80 hover:opacity-90 group-hover:block"
      shape="circle"
      size="medium"
    >
      <RegrelloIcon iconName={toggleButtonIcon} intent="neutral" variant="textContrast" />
    </RegrelloButton>
  );

  const renderTable = (options: { fullWidth: boolean }) => {
    return (
      <RegrelloDataGrid<TData>
        className="relative"
        columns={columns}
        data={data}
        filterable={false}
        fullWidth={options.fullWidth}
        isManuallySorted={true}
        reorderable={false}
        resizable={false}
        sortable={false}
      />
    );
  };

  return (
    <div className={clsx("overflow-hidden relative group", className)} data-testid={dataTestId}>
      <RegrelloPopoverRoot
        // (dosipiuk): Those three options below are necessary to override material-ui focus management
        defaultOpen={withPopoverOnly}
        modal={true}
        onOpenChange={
          withPopoverOnly
            ? (isOpen: boolean) => {
                if (!isOpen) {
                  onClose?.();
                }
              }
            : undefined
        }
      >
        <RegrelloPopoverAnchor asChild={true}>
          {/* This is needed to `overlap` the existing table */}
          <div className="w-full" />
        </RegrelloPopoverAnchor>
        {withPopoverOnly ? null : (
          <>
            {renderTable({ fullWidth: false })}
            {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
            <RegrelloPopoverTrigger asChild={true}>{renderToggleButton("open-fullscreen")}</RegrelloPopoverTrigger>
          </>
        )}
        <RegrelloPopoverContent
          align="end"
          className="w-full overflow-auto group max-w-[calc(100vw-28px)]" // (clewis): 28px ensures that the poopover table aligns properly with the underlying table
          collisionPadding={8}
          omitDefaultPadding={true}
          sideOffset={0}
        >
          <>
            {renderTable({ fullWidth: true })}
            {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
            <RegrelloPopoverClose asChild={true}>{renderToggleButton("close-fullscreen")}</RegrelloPopoverClose>
          </>
        </RegrelloPopoverContent>
      </RegrelloPopoverRoot>
    </div>
  );
};

/**
 * An inline data grid that can be shown in tight spaces and can "pop out" to show the full table
 * in a popover.
 */
export const RegrelloDataGridWithPopover = React.memo(
  RegrelloDataGridWithPopoverInternal,
) as typeof RegrelloDataGridWithPopoverInternal;
