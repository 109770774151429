import { t, Trans } from "@lingui/macro";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloLinkV2, RegrelloTypography } from "@regrello/ui-core";
import React from "react";

import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../../constants/globalConstants";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";

export interface UnauthenticatedEmailSentPageProps {
  /**
   * Whether the email is sent for invitatation or password reset. Different instructions are
   * rendered based on the selection.
   */
  mode: "lostInvite" | "passwordReset" | "verificationSent";
}

export const UnauthenticatedEmailSentPage = React.memo(function UnauthenticatedEmailSentPageFn({
  mode,
}: UnauthenticatedEmailSentPageProps) {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierMode = supplierQueryValue != null;

  const signInButtonHref = `${RoutePaths.LOGIN}/${
    isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: "1" } : undefined
  }`;

  return (
    <div className="flex justify-center w-full h-full" data-testid={DataTestIds.UNAUTHENTICATED_EMAIL_SENT_PAGE_ROOT}>
      <div className="flex flex-col px-12 pb-4">
        <div className="flex flex-col justify-center flex-auto">
          <RegrelloTypography className="mb-10" variant="h1">
            {getHeaderText(mode, isSupplierMode)}
          </RegrelloTypography>

          <RegrelloTypography className="mb-4" variant="body" weight="semi-bold">
            {getDescriptionText(mode, isSupplierMode)}
          </RegrelloTypography>
          <RegrelloTypography>{getInstructions(mode, isSupplierMode)}</RegrelloTypography>

          <div className="mb-10">
            <RegrelloLinkV2 className="font-normal" to={signInButtonHref}>
              {mode === "lostInvite" && isSupplierMode ? t`Back to supplier sign in` : t`Back to sign in`}
            </RegrelloLinkV2>
          </div>

          <RegrelloTypography>
            {t`Having trouble? Contact us at `}{" "}
            <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
              {REGRELLO_SUPPORT_EMAIL}
            </RegrelloLinkV2>
          </RegrelloTypography>
        </div>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});

function getHeaderText(mode: "lostInvite" | "passwordReset" | "verificationSent", isSupplierModeEnabled = false) {
  if (mode === "passwordReset") {
    return t`Password reset`;
  }
  if (mode === "lostInvite" && isSupplierModeEnabled) {
    return t`Check your email`;
  }

  return t`Invite resent`;
}

function getDescriptionText(mode: "lostInvite" | "passwordReset" | "verificationSent", isSupplierModeEnabled = false) {
  if (mode === "passwordReset") {
    return t`We have sent you an email. You’ll need to verifiy your identity before changing your password.`;
  }
  if (mode === "lostInvite" && isSupplierModeEnabled) {
    return t`What to do next:`;
  }

  return t`Check your inbox for an invite email.`;
}

function getInstructions(mode: "lostInvite" | "passwordReset" | "verificationSent", isSupplierModeEnabled = false) {
  if (mode === "passwordReset") {
    return (
      <ul className="pl-4 mb-10 list-disc">
        <li className="pb-1">
          <Trans>
            Check your inbox for the email with the subject <strong>Password reset</strong>
          </Trans>
        </li>
        <li>{t`If you have not signed up with an email and password, you will not receive an email.`}</li>
      </ul>
    );
  }
  if (mode === "lostInvite" && isSupplierModeEnabled) {
    return (
      <ul className="pl-4 mb-10 list-disc">
        <li>
          <Trans>There should be a new invite from us in your inbox.</Trans>
        </li>
        <li>
          <Trans>Don&apos;t see it there? Check your Junk folder.</Trans>
        </li>
        <li>
          <Trans>
            Click the <strong>Accept invite</strong> link.
          </Trans>
        </li>
        <li>
          <Trans>Create your password.</Trans>
        </li>
        <li>
          <Trans>That&apos;s it. You and your customer can now collaborate with Regrello.</Trans>
        </li>
      </ul>
    );
  }

  return null;
}
