import { ComparatorResult } from "@regrello/core-utils";

import type { FieldInstanceFieldsWithSource } from "../../types/FieldInstanceFieldsWithSource";

interface FieldInstanceWithSourcesComparatorOptions {
  /** When `true`, groups the fields by stage ID numerically first, then by field name. */
  groupByStage: boolean;
}

/** Sorts the provided field instances first by workflow / stage then by field name. */
function fieldInstancesWithSourcesComparator(
  fieldInstanceA: FieldInstanceFieldsWithSource,
  fieldInstanceB: FieldInstanceFieldsWithSource,
  options: FieldInstanceWithSourcesComparatorOptions,
) {
  // If there are no sources, it's probably a bug, but I need a guard clause so just pull them to
  // the front of the list.
  if (fieldInstanceA.source == null || fieldInstanceB.source == null) {
    return ComparatorResult.BEFORE;
  }

  // Start by comparing source types (workflow-level come before task-level).
  if (fieldInstanceA.source.type === "parent" && fieldInstanceB.source.type === "previous") {
    return ComparatorResult.BEFORE;
  }
  if (fieldInstanceA.source.type === "previous" && fieldInstanceB.source.type === "parent") {
    return ComparatorResult.AFTER;
  }

  // Then, sort all workflow-level fields by name.
  if (fieldInstanceA.source.type === "parent" && fieldInstanceB.source.type === "parent") {
    return fieldInstanceA.field.name.localeCompare(fieldInstanceB.field.name);
  }

  // This is just a guard clause to make sure all types from here on out are "previous".
  if (fieldInstanceA.source.type !== "previous" || fieldInstanceB.source.type !== "previous") {
    return ComparatorResult.EQUAL;
  }

  if (options.groupByStage) {
    // (anthony): -1 since stageless field instances should always come first anyways.
    const fieldInstanceAStageId = fieldInstanceA.source.stageId ?? -1;
    const fieldInstanceBStageId = fieldInstanceB.source.stageId ?? -1;

    if (fieldInstanceAStageId < fieldInstanceBStageId) {
      return ComparatorResult.BEFORE;
    }
    if (fieldInstanceBStageId < fieldInstanceAStageId) {
      return ComparatorResult.AFTER;
    }
  }

  // Finally, sort by field name.
  return fieldInstanceA.field.name.localeCompare(fieldInstanceB.field.name);
}

/** HoF to give the field instance comparator some options. */
export function getFieldInstancesWithSourcesComparator(options: FieldInstanceWithSourcesComparatorOptions) {
  return (fieldInstanceA: FieldInstanceFieldsWithSource, fieldInstanceB: FieldInstanceFieldsWithSource) => {
    return fieldInstancesWithSourcesComparator(fieldInstanceA, fieldInstanceB, options);
  };
}
