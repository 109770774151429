import { EMPTY_ARRAY } from "@regrello/core-utils";
import type { FieldFields, ViewColumnFields } from "@regrello/graphql-api";

import { UNDEFINED_COLUMN_ID } from "../../../../../constants/globalConstants";

/**
 * Helper function to wrap a `FieldFields` or break out its nested `RegrelloObjectPropertyFields`
 * for usage in tables.
 */
export const createViewColumnsFromField = (field: FieldFields): ViewColumnFields[] => {
  if (field.regrelloObject != null) {
    return field.regrelloObject?.properties.map((property) => ({
      displayOrder: property.displayOrder,
      field: field,
      fieldId: field.id,
      filters: EMPTY_ARRAY,
      id: UNDEFINED_COLUMN_ID,
      regrelloObjectProperty: property,
    }));
  }

  return [
    {
      displayOrder: 0,
      field: field,
      fieldId: field.id,
      filters: EMPTY_ARRAY,
      id: UNDEFINED_COLUMN_ID,
    },
  ];
};
